import React, { FC, useCallback, useState, useEffect } from 'react'
import moment from 'moment'
import { Space } from 'antd'
import Swal from 'sweetalert2'
import TableComponent from '../../Elements/Table'
import StatusBadge from '../../Elements/StatusBadge'
import SlipModal from '../../Slip/SlipModal'
import PrefixService from '../../../services/prefix'
import { useAPIRequest } from '../../../hooks/useAPIRequest'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { getBankName, getBankIcon } from '../../../utils/bank'
import { Member } from '../../../@types/member'
import FormattedMessage from '../../Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
// import { EyeOutlined } from '@ant-design/icons';


interface WithdrawTabProps {
    member: Member
    dateRange: any
}

const WithdrawTab: FC<WithdrawTabProps> = ({ member, dateRange }) => {
    const [dataSource, setDataSource] = useState<any>([])

    const [viewSlipModal, setViewSlipModal] = useState(false)
    const [transaction, setTransaction] = useState({})
    const toggleViewSlip = () => setViewSlipModal(!viewSlipModal)

    const { data, loading, update } = useAPIRequest(
        useCallback(
            async () =>
                PrefixService.getMemberTransactionList({
                    id: member.id,
                    dateRange: dateRange,
                    type: 'withdraw',
                }),
            [member.id, dateRange]
        )
    )


    const textHandleCheckSlip = {
        title: FuncFormattedMessage("slip", "สลิปโอนเงิน"),
        confirm: FuncFormattedMessage("close", "ปิด"),
    }

    const handleCheckSlip = (values: any) => {
        if (values.transactionLog) {
            setTransaction(values)
            toggleViewSlip()
        } else {
            if (values.withdraw.qrString) {
                Swal.fire({
                    title: `${textHandleCheckSlip.title}`,
                    html: `
                    <img src="https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${values.withdraw.qrString}">
                `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: textHandleCheckSlip.confirm,
                }).then(async (result) => {
                })
            } else {
                Swal.fire({
                    title: `${textHandleCheckSlip.title}`,
                    html: `
                    <img src="https://msn.cdnbet.co/withdraw/${PrefixService.getPrefix()}/${values.withdraw.transactionID}.png">
                `,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: textHandleCheckSlip.confirm,
                }).then(async (result) => {
                })
            }
        }
    }

    const columns = [
        {
            title: <FormattedMessage id="date_time" defaultMessage="วันที่/เวลา" />,
            dataIndex: 'createdAt',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                    <div><FormattedMessage id="balance_update" defaultMessage="อัพเดทยอด" /> : {moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="to" defaultMessage="ไปยัง" />,
            dataIndex: 'transferTo',
            render: (text: any, record: any) => (
                <div className="row-bank">
                    {getBankIcon(record.transferTo) && (<img src={getBankIcon(record.transferTo)} alt={record.transferTo} />)}
                    <div className="row-bank-info">
                        <div>{getBankName(record.transferTo)} ({record.transferTo})</div>
                    </div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    {record.remark && <div className="row-remark mt-2" style={{ maxWidth: '360px' }}>{record.remark}</div>}
                    {record.operator && <div className="bo-badge badge-light mt-2 text-danger"><FormattedMessage id="listed_by" defaultMessage="ทำรายการโดย" /> : <b>{record.operator}</b></div>}
                </>
            )
        },
        {
            title: <FormattedMessage id="status" defaultMessage="สถานะ" />,
            dataIndex: 'status',
            width: '180px',
            render: (text: any, record: any) => (
                <StatusBadge status={record.status} />
            )
        },
        {
            title: <FormattedMessage id="action" defaultMessage="จัดการ" />,
            dataIndex: 'action',
            width: '120px',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        {record.status === 'success' && record.withdraw.transactionID && (
                            // <WithdrawSlipActions tx={record} />
                            <button className="btn btn-info btn-sm text-white me-2"
                                onClick={() => {
                                    handleCheckSlip(record)
                                }}
                            ><FormattedMessage id="slip" defaultMessage="สลิปโอนเงิน" /></button>
                        )}
                    </Space>
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource(data)
    }, [data, loading, setDataSource])

    useEffect(() => {
        update()
    }, [dateRange])// eslint-disable-line

    return (
        <>
            <h5 className="mt-2 mb-3"><FormattedMessage id="transaction-withdraw" defaultMessage="รายการถอน" /> <small className="sub-title">{`${NumberWithCommas(dataSource ? dataSource.length : 0)}`} <FormattedMessage id="list" defaultMessage="รายการ" /></small></h5>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    customOffsetHeader={-24}
                />
            </div>

            <SlipModal
                data={transaction as any}
                isOpen={viewSlipModal}
                close={() => toggleViewSlip()}
            />
        </>
    )
}
export default WithdrawTab