import React, { FC } from 'react'
import { useSelector } from 'react-redux'
// import PrefixService from '../../services/prefix'
import { ApplicationState } from '../../store'

interface loadingProps {
    className?: string
}

const NamePrefix: FC<loadingProps> = ({ className }) => {
    // const namePrefix = PrefixService.getPrefix()

    const { name } = useSelector((state: ApplicationState) => state.prefix)
    const namePrefix = name.toUpperCase()

    return (
        <a href="/" className={'name-prefix ' + className}>
            <div className="firework">
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
            </div>
            <div className="name-prefix-shape"></div>
            <div className="name-prefix-shape"></div>
            <div className="name-prefix-text">
                {namePrefix.toLocaleUpperCase()}
            </div>
        </a>
    );
}

export default NamePrefix