export const Colors = {
	mirage: '#192132',
	ebonyClay: '#273043',
	zircon: '#F2F5FF',
	zircon2: '#FAFBFF',
	white: '#fff',
	casper: '#B0BBD2',
	casper2: '#808BA2',
	dodgerBlue: '#4F80FF',
	regentGray: '#818CA3',
	linkWater: '#E2E9F9',
	linkWater2: '#D3DEF5',
	linkWater3: '#EFF4FF',
	slateGray: '#68748D',
	black: '#282F40',
	black2: '#212632',
	positive: '#7FD592',
	positive2: '#E7F4EA',
	positive3: '#398349',
	positive4: '#E7F4EA',
	negative: '#FF746B',
	negative2: '#FFDEDC',
	negative3: '#C64C44',
	negative4: '#FFDEDC',
	warning: '#FFD66B',
	anakiwa: '#A7C3FF',
	malibu: '#77A2FF',
	zumthor: '#E9F0FF',
	studio: '#7543B4',
	selago: '#F9FAFE',
	scb: '#B77EFF',
	ktb: '#4F80FF',
	kbank: '#7FD592',
	true: '#FFC887',
	true2: '#FFF7E1',
	true3: '#F09B38',
	bbl: '#4F53DF',
}
