import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        if (pathname !== '/' && pathname !== '/secure-code') {
            document.getElementsByClassName("app")[0].scrollTo({ top: 0, behavior: 'smooth' })
            window.scrollTo(0, 0);
            document.getElementsByClassName("app")[0].classList.remove("toggle")
            document.querySelector('.bo-nav-toggle .far.fa-bars')?.classList.add('open');
            document.querySelector('.bo-nav-toggle .far.fa-times')?.classList.remove('open');
        }
    }, [pathname]);

    return null;
}

