import React, { FC } from 'react'
import BadgeComponent from '../../components/Elements/Badge'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'

interface StatusBadgeProps {
    status: string
}

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
    switch (status) {
        case 'pending': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รอดำเนินการ")
            return <BadgeComponent className="badge-warning" text={status_text} />
        }
        case 'queueing': {
            const status_text = FuncFormattedMessage(`status-${status}`, "กำลังดำเนินการ")
            return <BadgeComponent className="badge-warning" text={status_text} />
        }
        case 'checking': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รอตรวจสอบ")
            return <BadgeComponent className="badge-warning" text={status_text} />
        }
        case 'success': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รายการสำเร็จ")
            return <BadgeComponent className="badge-success" text={status_text} />
        }
        case 'reject': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รายการไม่สำเร็จ")
            return <BadgeComponent className="badge-danger" text={status_text} />
        }
        case 'member_notfound': {
            const status_text = FuncFormattedMessage(`status-${status}`, "ไม่พบสมาชิก")
            return <BadgeComponent className="badge-danger" text={status_text} />
        }
        case 'duplicate': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รายการซ้ำ")
            return <BadgeComponent className="badge-danger" text={status_text} />
        }
        case 'bank_duplicate': {
            const status_text = FuncFormattedMessage(`status-${status}`, "รายการฝากผิดพลาด")
            return <BadgeComponent className="badge-danger" text={status_text} />
        }
        default: {
            return <BadgeComponent className="badge-warning" text={status && status.toUpperCase()} />
        }
    }
}

export default StatusBadge