import React, { FC } from 'react'
import moment from 'moment'
import useFetchTransactionList from '../../hooks/useFetchTransactionList'
import NumberWithCommas from '../../utils/NumberWithCommas'
import { getBankName } from '../../utils/bank'
import Loading from '../Elements/Loading'
import Empty from '../Elements/Empty'
import FormattedMessage from '../Elements/FormattedMessage'
interface TxStatusProps {
    status: string
}
const TxStatus: FC<TxStatusProps> = ({ status }) => {
    switch (status) {
        case 'pending': {
            return <div className="text-status-warning"><small><FormattedMessage id={'main.money-list.pending'} defaultMessage={'รอดำเนินการ'} /></small></div>
        }
        case 'reject': {
            return <div className="text-status-danger"><small><FormattedMessage id={'main.money-list.reject'} defaultMessage={'รายการไม่สำเร็จ'} /></small></div>
        }
        case 'member_notfound': {
            return <div className="text-status-danger"><small><FormattedMessage id={'main.money-list.member_notfound'} defaultMessage={'ไม่พบสมาชิก'} /></small></div>
        }
        default: {
            return <div className="text-status-warning"><small>{status && status.toUpperCase()}</small></div>
        }
    }
}


const BankTransactionLastest = () => {
    const dateFormat = "DD/MM/YYYY HH:mm:ss"
    const { data, isLoading } = useFetchTransactionList(`/bank-transactions`, {
        type: 'bank-transactions',
        dateRange: {
            start: moment().format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
    }, 30000)

    const listTransaction = data !== undefined && Object.values(data.transactions as any).filter((item: any, index: number) => (
        item.status !== 'success' && item.status !== 'duplicate'
    ))
    const listTransactionLimit = listTransaction !== undefined && Object.values(listTransaction as any).filter((item: any, index: number) => index < 20)

    return (
        <div className="mb-4">
            <h5 className="mb-3"><FormattedMessage id={'main.money-list.title'} defaultMessage={'รายการเงินเข้า'} /></h5>
            {!isLoading ?
                Object.values(listTransactionLimit as any).length !== 0 ?
                    Object.values(listTransactionLimit as any).map((item: any, index: number) => (
                        <div className="box-white box-list" key={index}>
                            <div className="box-list-head">
                                <div>
                                    <div>{getBankName(item.bankType)} ({item.bankType})</div>
                                    <div><small>{item.bankAccount}</small></div>
                                </div>
                                <div className="ms-auto text-end">
                                    <div><span>฿</span> {NumberWithCommas(item.amount, 2)}</div>
                                    <TxStatus status={item.status} />
                                </div>
                            </div>
                            <div className="box-list-footer">
                                <small><FormattedMessage id="note" defaultMessage="หมายเหตุ" /> : {item.remark}</small>
                                <small><FormattedMessage id="transfer" defaultMessage="ทำรายการโอน" /> : {moment(item?.createdAt).format(dateFormat)}</small>
                            </div>
                        </div>
                    ))
                    :
                    <Empty />
                :
                <Loading />
            }
        </div>
    );
}

export default BankTransactionLastest