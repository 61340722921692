import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { Form, InputNumber, Row, Col, Switch, Radio, Select, Checkbox, Divider, Space, Button, Alert } from 'antd';
import { toast } from 'react-toastify'
import { ApplicationState } from '../../../store'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import TitleBar from '../../../components/Elements/TitleBar'
import PatternNumber from '../../../utils/PatternNumber'
import { getRewardItems } from '../../../utils/Wheel'
import { WheelConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage';
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage';

const PromotionWheelLuck = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)

    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [rewardItemInput, setRewardItemInput] = useState<any>()
    const [rewardPrecent, setRewardPrecent] = useState<number>(0)
    const [form] = Form.useForm();
    const { Option } = Select;

    const updateData = async (values: WheelConfig) => {
        try {
            const res = await PrefixService.saveWheelConfig(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then(async (values: WheelConfig) => {
                const rewards = await getRewardItems(values.rewardType);
                await Object.values(values.rewards || []).forEach((val: any, x: number) => {
                    const keyRewards = Object.keys(rewards)[x]
                    Object(rewards)[keyRewards].chance = Object(values.rewards)[keyRewards].chance
                }
                )
                values.rewards = await rewards
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };

    const setRewardType = (t: string) => {
        form.setFieldsValue({ 'rewardType': t, 'rewards': getRewardItems(t) })
        rewardItems(getRewardItems(t), t)
    }

    const rewardItems = (rewardItemsWheel: any, type: string) => {
        const rewardItemKeys = Object.keys(rewardItemsWheel).sort((a, b) => {
            if (rewardItemsWheel[a].index > rewardItemsWheel[b].index) {
                return 1
            }
            return -1
        })
        const rewardItemInputs = []
        for (let i = 0; i < rewardItemKeys.length / 2; i++) {
            const reward1Key = rewardItemKeys[i * 2],
                reward2Key = rewardItemKeys[i * 2 + 1]
            const reward1 = rewardItemsWheel[reward1Key]
            const reward2 = rewardItemsWheel[reward2Key]

            rewardItemInputs.push(
                <Row gutter={16} key={i}>
                    <Col span={12}>
                        {reward1 && (
                            <Form.Item
                                name={["rewards", `${reward1Key}`, "chance"]}
                                key={`rewards-${reward1Key}`}
                                label={<FormattedMessage id={`wheelLucky.input.rewardItems.${type}.${reward1Key}`} defaultMessage={reward1.name} />}
                                rules={[{ required: true, message: <FormattedMessage id="please_fill_out" defaultMessage="กรุณากรอกข้อมูล" /> }]}
                                hasFeedback>
                                <InputNumber placeholder={""} inputMode="numeric" addonAfter="%" controls={false} />
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={12}>
                        {reward2 && (
                            <Form.Item
                                name={["rewards", `${reward2Key}`, "chance"]}
                                key={`rewards-${reward2Key}`}
                                label={<FormattedMessage id={`wheelLucky.input.rewardItems.${type}.${reward2Key}`} defaultMessage={reward2.name} />}
                                rules={[{ required: true, message: <FormattedMessage id="please_fill_out" defaultMessage="กรุณากรอกข้อมูล" /> }]}
                                hasFeedback>
                                <InputNumber placeholder={""} inputMode="numeric" addonAfter="%" controls={false} />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            )
            if (type === prefix?.setting?.wheel?.rewardType) {
                form.setFieldsValue({ "rewards": prefix?.setting?.wheel?.rewards })
            }
            setRewardItemInput(rewardItemInputs)
        }
    }

    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.wheel)
            rewardItems(getRewardItems(prefix?.setting?.wheel?.rewardType), prefix?.setting?.wheel?.rewardType)
            sumRewardPrecent()
        }
    }, [form])// eslint-disable-line


    useEffect(() => {
        if (form) {
            sumRewardPrecent()
        }
    }, [form, setRewardType])// eslint-disable-line

    const sumRewardPrecent = () => {
        const sumReward = form.getFieldsValue().rewards ? Object.values(form.getFieldsValue().rewards).reduce(
            (val: number, { chance }: any) => val + parseFloat(chance > 0 ? chance : 0),
            0
        ) : 0

        setRewardPrecent(sumReward)

        // return Object.values(form.getFieldsValue().rewards || []).reduce(
        //     (val, x: any) => val + x.chance,
        //     0
        // )
    }

    const text_select_limited_play = FuncFormattedMessage("wheelLucky.input.select_limited_play", "เลือกการจำกัดการเล่น")
    const text_minDeposit = FuncFormattedMessage("wheelLucky.input.minDeposit", "ยอดฝากขั้นต่ำ")
    const text_turn_times = FuncFormattedMessage("turn-times", "เทิร์น (เท่า)")
    const text_select_deposit_type = FuncFormattedMessage("wheelLucky.input.select_deposit_type", "เลือกประเภทยอดฝาก")
    const text_total = FuncFormattedMessage("wheelLucky.total", "ยอดรวม")
    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="wheelLucky.title" defaultMessage="จัดการกงล้อเสี่ยงโชค" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="wheelLuckForm"
                    layout="vertical"
                    onValuesChange={sumRewardPrecent}
                    hideRequiredMark
                >

                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="status" defaultMessage="สถานะ" />}
                                name="enable"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="wheelLucky.input.reward_type" defaultMessage="ประเภทรางวัล" />}
                                name="rewardType"
                                rules={[{ required: true, message: <FormattedMessage id="wheelLucky.input.please_reward_type" defaultMessage="กรุณาเลือกประเภทรางวัล" /> }]}>
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="type1" onClick={() => setRewardType('type1')}><FormattedMessage id="wheelLucky.input.radio_type.type_1" defaultMessage="ประเภทที่ 1" /></Radio.Button>
                                    <Radio.Button value="type2" onClick={() => setRewardType('type2')}><FormattedMessage id="wheelLucky.input.radio_type.type_2" defaultMessage="ประเภทที่ 2" /></Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="rewardLimit"
                                label={<FormattedMessage id="wheelLucky.input.limited_play" defaultMessage="จำกัดการเล่น" />}
                                rules={[{ required: true, message: <FormattedMessage id="wheelLucky.input.please_limited_play" defaultMessage="กรุณาเลือกการจำกัดการเล่น" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={text_select_limited_play}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={'every_hour'} value={'every_hour'}><FormattedMessage id="wheelLucky.input.option_limit_play.every_hour" defaultMessage="เล่นได้ทุก 1 ชั่วโมง" /></Option>
                                    <Option key={'every_day'} value={'every_day'}><FormattedMessage id="wheelLucky.input.option_limit_play.every_day" defaultMessage="เล่นได้ทุกวัน" /></Option>
                                    <Option key={'every_week'} value={'every_week'}><FormattedMessage id="wheelLucky.input.option_limit_play.every_week" defaultMessage="เล่นได้ทุกสัปดาห์" /></Option>
                                    <Option key={'every_month'} value={'every_month'}><FormattedMessage id="wheelLucky.input.option_limit_play.every_month" defaultMessage="เล่นได้ทุกเดือน" /></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="minDeposit"
                                label={text_minDeposit}
                                rules={[
                                    { required: true, message: <FormattedMessage id="wheelLucky.input.please_minDeposit" defaultMessage="กรุณากรอกยอดฝากขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_minDeposit} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="depositType"
                                label={<FormattedMessage id="wheelLucky.input.deposit_type" defaultMessage="ประเภทยอดฝาก" />}
                                rules={[{ required: true, message: <FormattedMessage id="wheelLucky.input.please_deposit_type" defaultMessage="กรุณาเลือกการประเภทยอดฝาก" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={text_select_deposit_type}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option key={'sum'} value={'sum'}><FormattedMessage id="wheelLucky.input.option_deposit_type.sum" defaultMessage="ยอดฝากรวม" /></Option>
                                    <Option key={'tx'} value={'tx'}><FormattedMessage id="wheelLucky.input.option_deposit_type.tx" defaultMessage="ยอดฝากรายครั้ง" /></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="rewardTurn"
                                label={text_turn_times}
                                rules={[
                                    { required: true, message: <FormattedMessage id="please-enter-turn-times" defaultMessage="กรุณากรอกเทิร์น (เท่า)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_turn_times} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {rewardItemInput}
                    <Alert message={`${text_total} : ${rewardPrecent}%`} type="warning" />
                    <Row gutter={16}>
                        <Col span={24}>
                            <h6 className="my-3"><FormattedMessage id="wheelLucky.lucky_wheel_conditions" defaultMessage="เงื่อนไขกงล้อเสี่ยงโชค" /></h6>
                            <Form.Item
                                name={['rules', 'minCredit']}
                                key="minCredit"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="wheelLucky.conditions.minCredit" defaultMessage="ต้องมีเครดิตคงเหลือน้อยกว่า" /> {prefix?.setting?.minCredit}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noWithdraw']}
                                key="noWithdraw"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="wheelLucky.conditions.noWithdraw" defaultMessage="ต้องไม่มีรายการถอน (ของรายการฝาก)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noBonus']}
                                key="noBonus"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="wheelLucky.conditions.noBonus" defaultMessage="ต้องไม่มีรายการรับโบนัส (ของรายการฝาก)" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionWheelLuck