import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/dashboard';


export const useFetchFixTixList = (dateRange: DateRangeType): any => {
	const prefix = PrefixService.getPrefix()
	const { data, mutate, error } = useSWR([`/fixTx/${prefix}`, dateRange?.start, dateRange?.end], () =>
		PrefixService.getFixTxList({ dateRange: dateRange })
	)

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchFixTixList
