import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/dashboard';
import { PaginationParams } from '../@types/api';


export const useFetchReport = <T>(path: string, dateRange: DateRangeType, refreshInterval?: number, params?: PaginationParams): any => {
	const prefix = PrefixService.getPrefix()
	const { data, mutate, error } = useSWR([`/summary/${path}/${prefix}`, dateRange.start, dateRange.end, params?.limit, params?.page], () =>
		PrefixService.getSummaryWithPath<T>(path, dateRange, params)
	)

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchReport
