import React, { Fragment, useEffect, useState, useMemo } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/TablePagination'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import moment from 'moment'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import useFetchReport from '../../../hooks/useFetchReport'
import { CashbackSummaryItem } from '../../../@types/report'
import useDatePagination from '../../../hooks/useDatePagination'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const ReportCashback = () => {
    const { dateRange, setDateRange } = useDateRange()

    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { data, mutate, isLoading } = useFetchReport<CashbackSummaryItem[]>('cashback', dateRange, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columns: any = [
        {
            title: <FormattedMessage id="time" defaultMessage="เวลา" />,
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="member_table" defaultMessage="สมาชิก" />,
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <AvatarMemberComponent
                    fullname={`${record.member.firstName} ${record.member.lastName}`}
                    phone={record.member.phone}
                    search={true}
                />
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: <FormattedMessage id="turn" defaultMessage="ยอดเทิร์น" />,
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
        {
            title: <FormattedMessage id="turn2" defaultMessage="เทิร์น" />,
            dataIndex: 'turn',
            render: (text: any, record: any) => (
                <>
                    {record.turn} <FormattedMessage id="equal" defaultMessage="เท่า" />
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined) {
            setDataSource(data.data)
        }
        // setLoading(isLoading)
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        // setLoading(true)
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="reports.cashback.title" defaultMessage="รายงานการรับแคชแบ็ก" />} subTitle={`${NumberWithCommas(data?.pagination.Count)} ${FuncFormattedMessage("list", "รายการ")}`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </Fragment>
    )
}

export default ReportCashback