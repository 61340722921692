import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Space, Switch, Menu, Dropdown, Button, Tag, Tooltip, Avatar, Checkbox, Row, Col, Collapse } from 'antd'
import { DownOutlined } from '@ant-design/icons' //DollarCircleOutlined
// import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { getBankIcon } from '../../../utils/bank'
// import BadgeComponent from '../../../components/Elements/Badge'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { BankConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import TitleBar from '../../../components/Elements/TitleBar'
import SearchData from '../../../components/Elements/Search'
import TableComponent from '../../../components/Elements/Table'
import AddBank from '../../../components/Banks/Bank/Add'
import EditBank from '../../../components/Banks/Bank/Edit'
import ScbAPIBank from '../../../components/Banks/Bank/ScbAPI'
import useFetchSetting from "../../../hooks/useFetchSetting"
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import AmountCard from '../../../components/Elements/AmountCard'


const Bank = () => {
    const { Panel } = Collapse;
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const { data: fetchSetting, mutate: mutateSetting } = useFetchSetting()
    // const { data: fetchSCB, mutate: mutateSCB } = useFetchSCBAccounts()
    const [addBank, setAddBank] = useState<boolean>(false)
    const [editBank, setEditBank] = useState<boolean>(false)
    const [scbAPIBank, setScbAPIBank] = useState<boolean>(false)
    const toggleAddBank = () => setAddBank(!addBank)
    const toggleEditBank = () => setEditBank(!editBank)
    const toggleSCBAPI = () => setScbAPIBank(!scbAPIBank)
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    const [switchHideLoading, setSwitchHideLoading] = useState<boolean>(false)
    const [switchAppLoading, setSwitchAppLoading] = useState<boolean>(false)
    const [switchIBankLoading, setSwitchIBankLoading] = useState<boolean>(false)
    const [switchSMSLoading, setSwitchSMSLoading] = useState<boolean>(false)
    const [dataSetting, setDataSetting] = useState<any>([])
    // const [dataSCBAccount, setDataSCBAccount] = useState<any>([])
    const [scbBalance, setScbBalance] = useState<any>([])
    const [kbizBalance, setKbizBalance] = useState<any>([])

    const [copGuard, setCopGuard] = useState<boolean>(false)

    const [bankData, setBankData] = useState<any>()
    const handleBank = (object: any) => setBankData(object)

    const [banks, setBanks] = useState<any>([])
    const [scbAccounts, setScbAccounts] = useState<any>([])
    const [kbankAccount, setKbankAccount] = useState<any>([])
    const [filterText, setFilterText] = useState('')
    const [totalBalanceSCB, setTotalBalanceSCB] = useState<number>(0)
    const [totalBalanceKbiz, setTotalBalanceKbiz] = useState<number>(0)


    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const text_confirm = FuncFormattedMessage("confirm", "ตกลง")
    const text_cancel = FuncFormattedMessage("cancel", "ยกเลิก")
    const text_error_scb = FuncFormattedMessage("banks.error_scb", "SCB API มีปัญหาไม่สามารถดึงข้อมูลได้")
    const text_error_kbiz = FuncFormattedMessage("banks.error_kbiz", "Kbiz มีปัญหาไม่สามารถดึงข้อมูลได้")

    const textHandleDelete = {
        title: FuncFormattedMessage("handleDelete.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handleDelete.text", "คุณต้องการลบรายการนี้ใช่หรือไม่")
    }
    const handleDelete = (accountNumber: string) => {
        Swal.fire({
            title: `${textHandleDelete.title}`,
            text: `${textHandleDelete.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.deleteBankAccount(accountNumber)
                    // if (validScbAccount(accountNumber).length > 0) {
                    //     await PrefixService.deleteSCBAccount(accountNumber)
                    // }
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    // const handleStatus = async (status: boolean, values: BankConfig) => {
    //     try {
    //         values.enable = status
    //         const res = await PrefixService.saveBankAccount(values)
    //         toast.success(res.message)
    //         dispatch(requestAuthInit(false))
    //     } catch ({ message }) {
    //         toast.error(message as string)
    //     }
    //     setSwitchLoading(false)
    // }

    // const handleStatusHide = async (status: boolean, values: BankConfig) => {
    //     try {
    //         values.hide = status
    //         const res = await PrefixService.saveBankAccount(values)
    //         toast.success(res.message)
    //         dispatch(requestAuthInit(false))
    //     } catch ({ message }) {
    //         toast.error(message as string)
    //     }
    //     setSwitchHideLoading(false)
    // }

    const handelCopGuard = async (e: any) => {
        try {
            prefix.setting.functions.copGuard = e.target.checked
            setCopGuard(e.target.checked)
            const res = await PrefixService.savePrefixSetting(prefix.setting)
            toast.success(res.message)
            dispatch(requestAuthInit(false))
        } catch ({ message }) {
            toast.error(message as string)
        }
    }
    const textHandelMainDeposit = {
        title: FuncFormattedMessage("handelMainDeposit.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handelMainDeposit.text", "คุณต้องการตั้งบัญชีฝากหลักสำหรับบัญชีธนาคารนี้ใช่หรือไม่")
    }
    const handelMainDeposit = async (accountNumber: string) => {
        Swal.fire({
            title: `${textHandelMainDeposit.title}`,
            text: `${textHandelMainDeposit.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.setMainDeposit(accountNumber)
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                    // mutateSCB()
                    mutateSetting()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const textHandelCancelMainDeposit = {
        title: FuncFormattedMessage("handelCancelMainDeposit.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handelCancelMainDeposit.text", "คุณต้องการยกเลิกบัญชีฝากหลักสำหรับบัญชีธนาคารนี้ใช่หรือไม่")
    }
    const handelCancelMainDeposit = async (accountNumber: string) => {
        Swal.fire({
            title: `${textHandelCancelMainDeposit.title}`,
            text: `${textHandelCancelMainDeposit.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.setCancelMainDeposit(accountNumber)
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                    // mutateSCB()
                    mutateSetting()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const textHandelWithdrawToCentral = {
        title: FuncFormattedMessage("handelWithdrawToCentral.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handelWithdrawToCentral.text", "คุณต้องการโอนเงินเข้าบัญชีกลางใช่หรือไม่")
    }
    const handelWithdrawToCentral = async (accountNumber: string) => {
        Swal.fire({
            title: `${textHandelWithdrawToCentral.title}`,
            text: `${textHandelWithdrawToCentral.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.withdrawToCentral(accountNumber)
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                    // mutateSCB()
                    mutateSetting()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }
    const textHandelSetWithdraw = {
        title: FuncFormattedMessage("handelSetWithdraw.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handelSetWithdraw.text", "คุณต้องการตั้งบัญชีถอนสำหรับบัญชีธนาคารนี้ใช่หรือไม่")
    }
    const handelSetWithdraw = async (accountNumber: string) => {
        Swal.fire({
            title: `${textHandelSetWithdraw.title}`,
            text: `${textHandelSetWithdraw.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.setWithdraw(accountNumber)
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                    // mutateSCB()
                    mutateSetting()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const handleStatusMulti = async (status: boolean, bank: BankConfig, type: string) => {
        try {
            const res = await PrefixService.toggleBank(bank.accountNumber, type)
            toast.success(res.message)
            dispatch(requestAuthInit(false))
        } catch ({ message }) {
            toast.error(message as string)
        }
        setSwitchHideLoading(false)
        setSwitchLoading(false)
        setSwitchSMSLoading(false)
        setSwitchIBankLoading(false)
        setSwitchAppLoading(false)
    }

    const menuSetting = (bank: any) => {
        return (
            <Menu className="setting-bank">
                {bank.bank === 'scb' && (!bank.meta.deviceID && !bank.meta.license && !bank.meta.pin) &&
                    <Menu.Item key="0">
                        <Button type="link" onClick={() => { handleBank(bank); toggleSCBAPI(); }}><FormattedMessage id="banks.set_scbapi" defaultMessage="ตั้งค่า SCB API" /></Button>
                    </Menu.Item>
                }
                {copGuard && dataSetting?.mainDeposit !== bank.accountNumber &&
                    <Menu.Item key="1">
                        <Button type="link" onClick={() => handelMainDeposit(bank.accountNumber)}><FormattedMessage id="banks.set_mainbank" defaultMessage="ตั้งบัญชีฝากหลัก" /></Button>
                    </Menu.Item>
                }
                {copGuard && dataSetting?.mainDeposit === bank.accountNumber &&
                    <Menu.Item key="2">
                        <Button type="link" onClick={() => handelCancelMainDeposit(bank.accountNumber)}><FormattedMessage id="banks.cancel_mainbank" defaultMessage="ยกเลิกบัญชีฝากหลัก" /></Button>
                    </Menu.Item>
                }
                {((((bank.bank === "scb" && ((bank.meta.deviceID && bank.meta.license && bank.meta.pin) || (bank.meta.username && bank.meta.password))) || (bank.bank === "kbank" && (bank.meta?.username && bank.meta?.password))) && !bank.isWithdrawAccount && !checkWithdrawAccount(bank.accountNumber))) &&
                    <Menu.Item key="3">
                        <Button type="link" onClick={() => handelSetWithdraw(bank.accountNumber)}><FormattedMessage id="banks.set_withdrawal" defaultMessage="ตั้งบัญชีถอน" /></Button>
                    </Menu.Item>
                }
                {bank.bank === 'scb' && validScbAccount(bank.accountNumber).length > 0 &&
                    <Menu.Item key="4">
                        <Button type="link" onClick={() => handelWithdrawToCentral(bank.accountNumber)}><FormattedMessage id="banks.transfer_central" defaultMessage="โอนเข้าบัญชีกลาง" /></Button>
                    </Menu.Item>
                }
            </Menu >
        )
    }

    const columns: any = [
        {
            title: <FormattedMessage id="banks.columns.bank" defaultMessage="บัญชีธนาคาร" />,
            dataIndex: 'bank',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Space>
                            {getBankIcon(record.bank) && (<Avatar src={getBankIcon(record.bank)} />)}
                            <Space direction="vertical" >
                                <div className="row-member">
                                    <Space size={1} >
                                        <div className="me-1">{record.accountNumber}</div>
                                        {copGuard && dataSetting?.mainDeposit === record.accountNumber && <Tag color="blue" >บัญชีฝากหลัก</Tag>}
                                        {((record?.isWithdrawAccount) && checkWithdrawAccount(record.accountNumber)) && <Tag color="red">บัญชีถอน</Tag>}
                                    </Space>
                                    <div className="row-member">
                                        <span>{record.accountName}</span>
                                        {record.bank === 'scb' && record.enable
                                            && validScbAPIAccount(record.accountNumber).meta.deviceID && validScbAPIAccount(record.accountNumber).meta.license && validScbAPIAccount(record.accountNumber).meta.pin
                                            && (scbBalance.find((x: any) => x.accountNumber === record.accountNumber)
                                                ? <Tooltip title="ยอดเงินคงเหลือ"><Tag color="purple" className="ms-2">{scbBalance.find((x: any) => x.accountNumber === record.accountNumber).balance}</Tag></Tooltip>
                                                : <i className="fa-solid fa-spinner fa-spin fa-1x spinner-loading ms-2" />)
                                        }
                                        {record.bank === 'kbank' && record.enable
                                            && validKbankAccount(record.accountNumber).meta.username && validKbankAccount(record.accountNumber).meta.password
                                            && (kbizBalance.find((x: any) => x.accountNumber === record.accountNumber)
                                                ? <Tooltip title="ยอดเงินคงเหลือ"><Tag color="purple" className="ms-2">{kbizBalance.find((x: any) => x.accountNumber === record.accountNumber).balance}</Tag></Tooltip>
                                                : <i className="fa-solid fa-spinner fa-spin fa-1x spinner-loading ms-2" />)
                                        }
                                    </div>
                                </div>
                            </Space>
                        </Space>
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id="status" defaultMessage="สถานะ" />,
            dataIndex: 'status',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Switch
                            checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                            unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                            checked={record.enable}
                            loading={switchLoading}
                            onChange={(e) => {
                                setSwitchLoading(true)
                                handleStatusMulti(e, record, "Enable")
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id="banks.columns.hide" defaultMessage="ซ่อนจากเว็บไซต์" />,
            dataIndex: 'hide',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Switch
                            checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                            unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                            checked={record.hide}
                            loading={switchHideLoading}
                            onChange={(e) => {
                                setSwitchHideLoading(true)
                                handleStatusMulti(e, record, "Hide")
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: 'SMS',
            dataIndex: 'sms',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Switch
                            checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                            unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                            loading={switchSMSLoading}
                            checked={record.enableSMS}
                            disabled={false}
                            onChange={(e) => {
                                setSwitchSMSLoading(true)
                                handleStatusMulti(e, record, 'SMS')
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: 'Internet Banking',
            dataIndex: 'internetBanking',
            render: (text: any, record: any) => {
                let checkDisableIBank = true
                if (record.bank === 'scb' && (record.meta.username && record.meta.password)) {
                    checkDisableIBank = false
                } else if (record.bank === 'kbank' && (record.meta.username && record.meta.password)) {
                    checkDisableIBank = false
                }
                return (
                    <>
                        {checkDisableIBank ?
                            <Tooltip title="ยังไม่เปิดใช้งาน Internet Banking">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                    loading={switchIBankLoading}
                                    checked={record.enableInternetBanking}
                                    disabled={checkDisableIBank}
                                    onChange={(e) => {
                                        setSwitchIBankLoading(true)
                                        handleStatusMulti(e, record, 'InternetBanking')
                                    }}
                                />
                            </Tooltip>
                            : <Switch
                                checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                loading={switchIBankLoading}
                                checked={record.enableInternetBanking}
                                disabled={checkDisableIBank}
                                onChange={(e) => {
                                    setSwitchIBankLoading(true)
                                    handleStatusMulti(e, record, 'InternetBanking')
                                }}
                            />
                        }
                    </>
                )
            }
        },
        {
            title: 'Application',
            dataIndex: 'application',
            render: (text: any, record: any) => {
                let checkDisableApp = true
                if (record.bank === 'scb' && (record.meta.deviceID && record.meta.license && record.meta.pin)) {
                    checkDisableApp = false
                }
                return (
                    <>
                        {checkDisableApp ?
                            <Tooltip title="ยังไม่เปิดใช้งาน Application">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                    loading={switchAppLoading}
                                    checked={record.enableApplication}
                                    disabled={checkDisableApp}
                                    onChange={(e) => {
                                        setSwitchAppLoading(true)
                                        handleStatusMulti(e, record, 'Application')
                                    }}
                                />
                            </Tooltip>
                            : <Switch
                                checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                loading={switchAppLoading}
                                checked={record.enableApplication}
                                disabled={checkDisableApp}
                                onChange={(e) => {
                                    setSwitchAppLoading(true)
                                    handleStatusMulti(e, record, 'Application')
                                }}
                            />
                        }
                    </>
                )
            }
        },
        // {
        //     title: <FormattedMessage id="createdAt2" defaultMessage="วันที่สร้าง" />,
        //     dataIndex: 'createdAt',
        //     width: '180px',
        //     render: (text: any, record: any) => (
        //         <div className="row-datetime">
        //             <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
        //             <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
        //         </div>
        //     )
        // },
        {
            title: <FormattedMessage id="action" defaultMessage="จัดการ" />,
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        <Dropdown overlay={() => menuSetting(record)} trigger={['click']}>
                            <button onClick={(e: any) => e.preventDefault()} className="btn btn-info btn-sm text-white me-2">
                                <Space>
                                    <FormattedMessage id="banks.account_settings" defaultMessage="ตั้งค่าบัญชี" />
                                    <DownOutlined />
                                </Space>
                            </button>
                        </Dropdown>
                        <button className="btn btn-warning btn-sm text-white me-2" onClick={() => { toggleEditBank(); handleBank(record) }}>
                            <i className="fa-solid fa-edit me-1"></i>
                            <FormattedMessage id="edit" defaultMessage="แก้ไข" />
                        </button>
                        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDelete(record.accountNumber)}>
                            <i className="fa-solid fa-trash me-1"></i>
                            <FormattedMessage id="delete" defaultMessage="ลบ" />
                        </button>
                    </Space>
                </>
            )
        }
    ]

    const reloadGetSCBBalance = (fetchSCB: any) => {
        setScbBalance([])
        setTotalBalanceSCB(0)
        Object.values(fetchSCB) && Object.values(fetchSCB).forEach(async (item: any) => {
            if (item.accountNumber && item.enable && (item.meta.deviceID && item.meta.license && item.meta.pin)) {
                try {
                    const res = await PrefixService.getSCBBalance(item.accountNumber)
                    setScbBalance((prevArray: any) => [...prevArray, {
                        accountNumber: item.accountNumber,
                        balance: `฿ ${NumberWithCommas(res.balance, 2)}`,
                    }]);
                    setTotalBalanceSCB((prev: any) => prev + Number(res.balance))
                } catch ({ message }) {
                    let messageError = message as string
                    // let messageError = ''
                    // if (message === 'ข้อผิดพลาดจาก SCB :ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง' || message === 'Too many requests, please try again later.') {
                    messageError = text_error_scb
                    // } else {
                    //     messageError = message as string
                    // }
                    toast.error(messageError as string)
                    setScbBalance((prevArray: any) => [...prevArray, {
                        accountNumber: item.accountNumber,
                        balance: messageError
                    }]);
                }
            }
        })
    }

    const reloadGetKbizBalance = (fetchKbiz: any) => {
        setKbizBalance([])
        setTotalBalanceKbiz(0)
        Object.values(fetchKbiz) && Object.values(fetchKbiz).forEach(async (item: any) => {
            if (item.accountNumber && item.enable && (item.meta.username && item.meta.password)) {
                try {
                    const res = await PrefixService.getKbizBalance(item.accountNumber)
                    setKbizBalance((prevArray: any) => [...prevArray, {
                        accountNumber: item.accountNumber,
                        balance: `฿ ${NumberWithCommas(res.balance, 2)}`,
                    }]);
                    setTotalBalanceKbiz((prev: any) => prev + Number(res.balance))
                } catch ({ message }) {
                    let messageError = message as string
                    // if (message === 'Too many requests, please try again later.') {
                    messageError = text_error_kbiz
                    // } else {
                    //     messageError = message as string
                    // }
                    toast.error(messageError as string)
                    setKbizBalance((prevArray: any) => [...prevArray, {
                        accountNumber: item.accountNumber,
                        balance: messageError
                    }]);
                }
            }
        })
    }

    const reduceBank = (banks: any) => {
        return banks.filter(({ bank, meta }: any) => bank !== 'truewallet')
    }

    const reduceBankKbank = (banks: any) => {
        return banks.filter(({ bank, meta }: any) => bank === 'kbank')
    }

    const reduceBankSCB = (banks: any) => {
        return banks.filter(({ bank, meta }: any) => bank === 'scb')
    }

    useEffect(() => {
        if (prefix) {
            const filteredItems = (reduceBank(Object.values(prefix.banks)) as any).filter(
                (item: any) => (
                    (item.accountNumber && item.accountNumber.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.accountName && item.accountName.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            setBanks(filteredItems)
            const kbankAccount = reduceBankKbank(Object.values(prefix.banks))
            setKbankAccount(kbankAccount)
            const scbAccount = reduceBankSCB(Object.values(prefix.banks))
            setScbAccounts(scbAccount)

        }
    }, [prefix, filterText])

    useEffect(() => {
        if (prefix) {
            // setScbAccounts(Object.values(prefix.scbAccounts))
            setCopGuard(prefix?.setting?.functions?.copGuard)
        }
    }, [prefix])

    useEffect(() => {
        if (fetchSetting !== undefined) {
            setDataSetting(fetchSetting?.setting)
            // setDataSCBAccount(Object.values(fetchSetting?.setting?.banks))
            // reloadGetSCBBalance(Object.values(fetchSetting?.setting?.banks))
        }
    }, [fetchSetting])

    // useEffect(() => {
    //     if (fetchSetting) {
    //         setDataSCBAccount(Object.values(fetchSetting?.setting?.banks))
    //     }
    // }, [fetchSetting])

    useMemo(() => {
        if (scbAccounts) {
            reloadGetSCBBalance(scbAccounts)
        }
    }, [scbAccounts]) //eslint-disable-line

    useMemo(() => {
        if (kbankAccount) {
            reloadGetKbizBalance(kbankAccount)
        }
    }, [kbankAccount]) //eslint-disable-line

    const validScbAccount = (accountNumber: string) => {
        return scbAccounts.filter((account: any) => (account.accountNumber === accountNumber))
    }


    const validKbankAccount = (accountNumber: string) => {
        return kbankAccount.find((account: any) => (account.accountNumber === accountNumber))
    }

    // const scbAccountStatusApplication = (accountNumber: string, bank:string) => {
    //     console.log('====================================');
    //     const checkStatus = scbAccounts.find((account: any) => (account.accountNumber === accountNumber)).meta
    //     console.log('====================================');
    //     return (checkStatus.deviceID && checkStatus.license && checkStatus.pin) && 
    // }

    const validScbAPIAccount = (accountNumber: string) => {
        return scbAccounts.find((account: any) => (account.accountNumber === accountNumber))
    }


    const checkWithdrawAccount = (accountNumber: string) => {
        return prefix.setting.withdrawAccount.accountNumber === accountNumber ? true : false
    }

    const text_bank_sum = FuncFormattedMessage("banks.sum", "ยอดคงเหลือรวม")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="menu-banks" defaultMessage="บัญชีธนาคาร" />} subTitle={`${NumberWithCommas(banks.length)} ${FuncFormattedMessage("list", "รายการ")}`} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                <div className="d-lg-flex justify-end-lg-between d-md-flex justify-end-md-between">
                    <button className="btn btn-primary ms-auto ms-md-0 me-3 mt-3 mt-md-0" onClick={toggleAddBank}>
                        <i className="fa-solid fa-plus me-3"></i>
                        <span><FormattedMessage id="banks.add" defaultMessage="เพิ่มบัญชีธนาคาร" /></span>
                    </button>
                </div>
            </div>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Checkbox onClick={handelCopGuard} checked={copGuard}><FormattedMessage id="banks.copGuard" defaultMessage="ป้องกันการแสดงบัญชีเงินฝากทั้งหมด" /></Checkbox>
                <div className="d-lg-flex justify-end-lg-between d-md-flex justify-end-md-between">
                    <button className="btn btn-info ms-auto ms-md-0 me-3 mt-3 mt-md-0"
                        onClick={() => {
                            reloadGetSCBBalance(scbAccounts)
                            reloadGetKbizBalance(kbankAccount)
                        }}
                        disabled={(scbBalance.length > 0 || kbankAccount.length > 0) ? false : true}>
                        <i className="fa-solid fa-arrows-rotate me-3"></i>
                        <span><FormattedMessage id="banks.getBalance" defaultMessage="ดึงยอดเงินคงเหลือ" /></span>
                    </button>
                </div>
            </div>

            <Collapse ghost className="mb-3 antd-custom-panel">
                <Panel header={<FormattedMessage id="banks.panel" defaultMessage="สรุปยอดเงินคงเหลือ" />} key="1">
                    <Row gutter={16}>
                        <Col xs={24} md={12} lg={12}>
                            <AmountCard
                                icon='fa-solid fa-university'
                                theme='info'
                                amount={NumberWithCommas(totalBalanceSCB + totalBalanceKbiz, 2)}
                                amountType={text_bank_sum}
                                loading={false}
                            />
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={banks || []}
                    loading={false}
                />
            </div>
            <AddBank
                isOpen={addBank}
                close={() => toggleAddBank()}
            />
            <EditBank
                data={bankData as any}
                isOpen={editBank}
                close={() => toggleEditBank()}
            />
            <ScbAPIBank
                data={bankData as any}
                isOpen={scbAPIBank}
                close={() => toggleSCBAPI()}
            />
        </Fragment>
    )
}

export default Bank