import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Descriptions, Modal, Space } from 'antd'
import moment from 'moment'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import FileSaver from "file-saver"
import * as XLSX from 'xlsx'
import TitleBar from '../../../components/Elements/TitleBar'
import TableComponent from '../../../components/Elements/TablePromotionCode'
import SearchData from '../../../components/Elements/Search'
import BadgeComponent from '../../../components/Elements/Badge'
import AddData from '../../../components/Promotions/Code/Add'
// import EditData from '../../../components/Promotions/Code/Edit'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import PrefixService from "../../../services/prefix"
import useFetchPromotionCode from "../../../hooks/useFetchPromotionCode"
import useDatePagination from '../../../hooks/useDatePagination'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import { ApplicationState } from '../../../store'
import { useSelector } from 'react-redux'
import { BonusGameTypes, BonusGameTypesAMBKing } from '../../../@types/prefix'

const PromotionCode = () => {
    const [filterText, setFilterText] = useState('')
    const [dataSource, setDataSource] = useState<any>([])
    const [dataCodesExcel, setDataCodesExcel] = useState<any>([]);
    const { pagination, setPagination } = useDatePagination()
    const [searchAlldata, setSearchAllData] = useState<string>("")
    const { game } = useSelector((state: ApplicationState) => state.prefix)

    const { data, mutate, isLoading } = useFetchPromotionCode(searchAlldata, { page: pagination.page, limit: pagination.pageSize })
    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    // const [editDrawer, setEditDrawer] = useState(false)
    // const toggleEdit = () => setEditDrawer(!editDrawer)
    const [isModalVisable, setIsModalVisable] = useState<boolean>(false)
    const toggleView = () => setIsModalVisable(!isModalVisable)

    const [dataEdit, setDataEdit] = useState<any>()
    const handleEdit = (object: any) => setDataEdit(object)



    const textHandleDelete = {
        title: FuncFormattedMessage("handleDelete.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handleDelete.text", "คุณต้องการลบรายการนี้ใช่หรือไม่"),
        confirm: FuncFormattedMessage("confirm", "ตกลง"),
        cancel: FuncFormattedMessage("cancel", "ยกเลิก"),
        error: FuncFormattedMessage("redeem.error_del", "กรุณาเลือกรายการที่ต้องการลบ"),
    }
    const handleDelete = (id: any) => {
        if (id.length === 0) {
            toast.error(textHandleDelete.error)
            return false
        }
        Swal.fire({
            title: textHandleDelete.title,
            text: textHandleDelete.text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: textHandleDelete.cancel,
            confirmButtonText: textHandleDelete.confirm
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.deleteRedeem({
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: {
                            id: !Array.isArray(id) ? [id] : id
                        }
                    })
                    toast.success(res.message)
                    mutate()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const columns: any = [
        {
            title: <FormattedMessage id="redeem.title" defaultMessage="โค้ดโปรโมชั่น" />,
            dataIndex: 'Code',
            // width: '280px',
        },
        {
            title: <FormattedMessage id="redeem.input.amountCredit" defaultMessage="เครดิต" />,
            dataIndex: 'Amount',
            width: '120px',
            align: 'center',
        },
        {
            title: <FormattedMessage id="redeem.input.maxWithdraw" defaultMessage="ยอดถอนสูงสุด (เท่า)" />,
            dataIndex: 'MaxWithdraw',
            width: '180px',
            align: 'center',
        },
        {
            title: <FormattedMessage id="redeem.input.turn" defaultMessage="เทิร์น (เท่า)" />,
            dataIndex: 'Turn',
            width: '120px',
            align: 'center',
        },
        {
            title: <FormattedMessage id="redeem.input.reuse" defaultMessage="ใช้ซ้ำ" />,
            dataIndex: 'Reuse',
            width: '120px',
            align: 'center',
            render: (text: any, record: any) => {
                return (
                    <BadgeComponent text={record.Reuse} className={record.ReuseClass} />
                )
            }
        },
        {
            title: <FormattedMessage id="redeem.reuseAmount" defaultMessage="จำนวนโค้ดใช้ซ้ำ" />,
            dataIndex: 'ReuseAmount',
            width: '120px',
            align: 'center',
        },
        {
            title: <FormattedMessage id="redeem.status_true" defaultMessage="ใช้งานแล้ว" />,
            dataIndex: 'Used',
            width: '120px',
            align: 'center',
        },
        // {
        //     title: <FormattedMessage id="status" defaultMessage="สถานะ" />,
        //     dataIndex: 'Used',
        //     width: '180px',
        //     align: 'center',
        //     render: (text: any, record: any) => {
        //         return (
        //             <BadgeComponent text={record.Used} className={record.UsedClass} />
        //         )
        //     }
        // },
        // {
        //     title: <FormattedMessage id="bonus.input.type_game" defaultMessage="ประเภทเกม" />,
        //     dataIndex: 'Condition',
        //     width: '180px',
        //     render: (text: any, record: any) => (
        //         <span>
        //             {((game).toUpperCase() === "AMBKING") ? (
        //                 record.Condition.gameType ? BonusGameTypesAMBKing.find((item) => item.game === record.Condition.gameType)?.label : "-"
        //             ) : (
        //                 record.Condition.gameType ? BonusGameTypes.find((item) => item.game === record.Condition.gameType)?.label : "-"
        //             )}
        //         </span>
        //     )
        // },
        {
            title: <FormattedMessage id="createdAt2" defaultMessage="วันที่สร้าง" />,
            dataIndex: 'createdAt',
            width: '180px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.CreatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.CreatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="action" defaultMessage="จัดการ" />,
            render: (record: any) => (
                <>
                    <Space size={0}>
                        {/* <button className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleEdit(record)
                                toggleEdit()
                            }}>แก้ไข</button> */}
                        <button className="btn btn-info btn-sm text-white me-2"
                            onClick={() => {
                                handleEdit(record)
                                toggleView()
                            }}>
                            <i className="fa-solid fa-eye me-1"></i>
                            <FormattedMessage id="bonus.condition" defaultMessage="เงื่อนไข" />
                        </button>
                        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDelete(record.key)}>
                            <i className="fa-solid fa-trash me-1"></i>
                            <FormattedMessage id="delete" defaultMessage="ลบ" />
                        </button>
                    </Space>
                </>
            )
        }
    ]
    // const text_statusTrue = FuncFormattedMessage("redeem.status_true", "ใช้งานแล้ว")
    // const text_statusFalse = FuncFormattedMessage("redeem.status_false", "ยังไม่ได้ใช้งาน")
    const text_promotionCode = FuncFormattedMessage("redeem.title", "โค้ดโปรโมชั่น")
    const text_amount = FuncFormattedMessage("redeem.input.amountCredit", "เครดิต")
    const text_maxWithdraw = FuncFormattedMessage("redeem.input.maxWithdraw", "ยอดถอนสูงสุด (เท่า)")
    const text_turn = FuncFormattedMessage("redeem.input.turn", "เทิร์น (เท่า)")
    const text_status = FuncFormattedMessage("status", "สถานะ")
    const text_create = FuncFormattedMessage("createdAt2", "วันที่สร้าง")
    const text_reuseTrue = FuncFormattedMessage("redeem.reuse_true", "ใช้ซ้ำ")
    const text_reuseFalse = FuncFormattedMessage("redeem.reuse_false", "ไม่ใช้ซ้ำ")
    const text_reuseAmount = FuncFormattedMessage("redeem.reuseAmount", "จำนวนใช้ซ้ำ")


    useEffect(() => {
        if (data !== undefined) {
            const promotionCodeData = Object.values(data.data).map((item: any) => {
                return {
                    key: item.id,
                    Code: item.code,
                    Amount: NumberWithCommas(item.amount, 2),
                    Used: NumberWithCommas(item.used),
                    // UsedClass: item.Used ? "badge-danger" : "badge-success",
                    MaxWithdraw: NumberWithCommas(item.maxWithdraw),
                    Turn: item.turn,
                    Reuse: item.reuse ? text_reuseTrue : text_reuseFalse,
                    ReuseClass: item.reuse ? "badge-success" : "badge-danger",
                    ReuseAmount: NumberWithCommas(item.reuseAmount),
                    Condition: item.condition,
                    CreatedAt: item.createdAt
                }
            })
            // const filteredItems = (promotionCodeData as any).filter(
            //     (item: any) => (
            //         (item.Code && item.Code.toLowerCase().includes(filterText.toLowerCase()))
            //     ),
            // );
            setDataSource(promotionCodeData)
            const reData = Object.values(data.data).map((item: any) => {
                return {
                    Code: item.code,
                    Amount: item.amount,
                    Used: item.used,
                    MaxWithdraw: item.maxWithdraw,
                    Turn: item.turn,
                    Reuse: item.reuse ? text_reuseTrue : text_reuseFalse,
                    ReuseAmount: item.reuseAmount,
                    Condition: item.condition,
                    CreatedAt: moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")
                }
            })
            setDataCodesExcel(reData)
        }
        // setLoading(isLoading)
    }, [data, isLoading, filterText, setDataSource]) // eslint-disable-line

    useMemo(() => {
        mutate()
    }, [setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearchAllData(filterText)
        mutate()
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const Heading = [
        {
            Code: text_promotionCode,
            Amount: text_amount,
            Used: text_status,
            MaxWithdraw: text_maxWithdraw,
            Turn: text_turn,
            Reuse: text_reuseTrue,
            ReuseAmount: text_reuseAmount,
            CreatedAt: text_create
        }
    ];

    const exportData = (dataExport: any) => {
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "Reuse", "ReuseAmount", "CreatedAt"],
            skipHeader: true
        });
        // ws["!cols"] = [
        //     { wch: Math.max(...dataExport.map((item: any) => item.Code.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Amount.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Used.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.MaxWithdraw.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Turn.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.CreatedAt.length)) + 5 }
        // ];
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "Reuse", "ReuseAmount", "CreatedAt"],
            skipHeader: true,
            origin: -1
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Export_Redeem" + fileExtension);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // console.log(dataSource);


    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="redeem.title" defaultMessage="โค้ดโปรโมชั่น" />} subTitle={`${NumberWithCommas(data?.pagination.Count || 0)} ${FuncFormattedMessage("list", "รายการ")}`} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2"><FormattedMessage id="search" defaultMessage="ค้นหา" /></button>
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <button className="btn btn-primary ms-auto ms-md-0  me-1" onClick={() => toggleAdd()}>
                        <i className="fa-solid fa-plus me-2"></i>
                        <span><FormattedMessage id="redeem.add" defaultMessage="เพิ่มโค้ดโปรโมชั่น" /></span>
                    </button>
                    <button className="btn btn-danger ms-auto ms-md-0"
                        onClick={() => handleDelete(selectedRowKeys)}
                        disabled={selectedRowKeys.length > 0 ? false : true}>
                        <i className="fa-solid fa-trash me-2"></i>
                        <span><FormattedMessage id="delete" defaultMessage="ลบ" /></span>
                    </button>
                </div>
            </div>
            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-info ms-auto ms-md-0" onClick={() => exportData(dataCodesExcel)}>
                        <i className="fa-solid fa-arrow-down-to-line me-2"></i>
                        <span>Export Excel</span>
                    </button>
                </div>
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
            <AddData
                isOpen={addDrawer}
                close={() => toggleAdd()}
                onloadData={mutate}
            />

            <Modal
                title={<FormattedMessage id="bonus.condition" defaultMessage="เงื่อนไข" />}
                visible={isModalVisable}
                onCancel={() => {
                    toggleView()
                    handleEdit("")
                }}
                footer={false}>
                <div className="d-flex align-items-center">
                    {dataEdit && (
                        <Descriptions title={""} bordered>
                            {dataEdit.Condition.gameType && (
                                <Descriptions.Item label={<FormattedMessage id="bonus.input.type_game" defaultMessage="ประเภทเกม" />} span={3}>
                                    {((game).toUpperCase() === "AMBKING") ? (
                                        dataEdit.Condition.gameType ? BonusGameTypesAMBKing.find((item) => item.game === dataEdit.Condition.gameType)?.label : "-"
                                    ) : (
                                        dataEdit.Condition.gameType ? BonusGameTypes.find((item) => item.game === dataEdit.Condition.gameType)?.label : "-"
                                    )}
                                </Descriptions.Item>
                            )}
                            {dataEdit.Condition.noDepositBetween.enable && (
                                <Descriptions.Item label={<FormattedMessage id="redeem.input.no_deposit" defaultMessage="ไม่มีรายการฝากในระหว่าง" />} span={3}>
                                    <span>
                                        <FormattedMessage id="start_date" defaultMessage="วันที่เริ่มต้น" /> : {dataEdit.Condition.noDepositBetween.startDate} <br />
                                        <FormattedMessage id="end_date" defaultMessage="วันที่สิ้นสุด" /> : {dataEdit.Condition.noDepositBetween.endDate}
                                    </span>
                                </Descriptions.Item>
                            )}
                            {dataEdit.Condition.depositBetween.enable && (
                                <Descriptions.Item label={<FormattedMessage id="redeem.input.depositBetween" defaultMessage="ยอดฝากสะสม" />} span={3}>
                                    <span>
                                        <FormattedMessage id="start_date" defaultMessage="วันที่เริ่มต้น" /> : {dataEdit.Condition.depositBetween.startDate} <br />
                                        <FormattedMessage id="end_date" defaultMessage="วันที่สิ้นสุด" /> : {dataEdit.Condition.depositBetween.endDate} <br />
                                        <FormattedMessage id="redeem.input.minDeposit" defaultMessage="ยอดฝากขั้นต่ำ" /> : {NumberWithCommas(dataEdit.Condition.depositBetween.minDeposit, 2)}
                                    </span>
                                </Descriptions.Item>
                            )}
                        </Descriptions>
                    )}
                </div>
            </Modal>
            {/* <EditData
                isOpen={editDrawer}
                close={() => toggleEdit()}
                onloadData={mutate}
                data={dataEdit}
            /> */}
        </Fragment>
    )
}

export default PromotionCode