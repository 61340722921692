import React, { FC } from 'react'
import { Empty } from 'antd'
import FormattedMessage from './FormattedMessage';

interface EmptyProps {
    description?: string
}
const EmptyComponents: FC<EmptyProps> = ({ description }) => {
    return (
        <div className="empty-data">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description ? description : <FormattedMessage id="no-data-found" defaultMessage="ไม่พบข้อมูล" />} />
        </div>
    );
}

export default EmptyComponents