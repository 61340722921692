import React, { Fragment, useEffect, useState, useMemo } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/Table'
import TablePagination from '../../../components/Elements/TablePagination'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import { Divider } from 'antd'
import moment from 'moment'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import useFetchReport from '../../../hooks/useFetchReport'
import { BonusSummary, BonusTransactions } from '../../../@types/report'
import useDatePagination from '../../../hooks/useDatePagination'
import useFetchReportSummary from '../../../hooks/useFetchReportSummary'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const ReportBonus = () => {
    const { dateRange, setDateRange } = useDateRange()
    const [dataSource, setDataSource] = useState<any>([])
    const [dataTransactionsSource, setDataTransactionsSource] = useState<any>([])

    const { pagination, setPagination } = useDatePagination()
    const { data: dataSummary, isLoading: isLoadingSummary } = useFetchReportSummary<BonusSummary>('promotion/summary', dateRange, 10000)
    const { data, mutate, isLoading } = useFetchReport<BonusTransactions>('promotion', dateRange, 10000, { page: pagination.page, limit: pagination.pageSize })

    const columnsSummary: any = [
        {
            title: <FormattedMessage id="menu-report-bonus" defaultMessage="โบนัส" />,
            dataIndex: 'Name',
        },
        {
            title: <FormattedMessage id="count" defaultMessage="จำนวนครั้ง" />,
            dataIndex: 'Count',
            render: (text: any, record: any) => (
                <div className="row-amount" style={{ minWidth: '56px' }}>
                    {NumberWithCommas(record.Count)}
                </div>
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'Amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.Amount, 2)}
                </div>
            )
        }
    ]

    const columnsTransactions: any = [
        {
            title: <FormattedMessage id="time" defaultMessage="เวลา" />,
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="member_table" defaultMessage="สมาชิก" />,
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    <AvatarMemberComponent
                        fullname={`${record.member.firstName} ${record.member.lastName}`}
                        phone={record.member.phone}
                        search={true}
                    />
                </>
            )
        },
        {
            title: <FormattedMessage id="menu-report-bonus" defaultMessage="โบนัส" />,
            dataIndex: 'bonus',
            render: (text: any, record: any) => (
                <>
                    {record.promotion.name}
                </>
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: <FormattedMessage id="turn" defaultMessage="ยอดเทิร์น" />,
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
        {
            title: <FormattedMessage id="turn2" defaultMessage="เทิร์น" />,
            dataIndex: 'turn',
            render: (text: any, record: any) => (
                <>
                    {record.turn} <FormattedMessage id="equal" defaultMessage="เท่า" />
                </>
            )
        }
    ]

    useEffect(() => {
        setDataSource([])

        if (dataSummary !== undefined) {
            setDataSource(Object.values(dataSummary).map((item: any) => item))
        }
        // setLoading(isLoading)
    }, [dataSummary, isLoadingSummary, setDataSource])

    useEffect(() => {
        setDataTransactionsSource([])
        if (data !== undefined) {
            if (data.transactions.length > 0) {
                setDataTransactionsSource(data.transactions)
            }
        }
        // setLoading(isLoading)
    }, [data, isLoading, setDataTransactionsSource])

    useMemo(() => {
        // setLoading(true)
        mutate()
    }, [setDateRange, setPagination]) // eslint-disable-line

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="reports.bonus.title" defaultMessage="รายงานโบนัส" />} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <h5 className="mt-5 mb-3"><FormattedMessage id="reports.bonus.title_table1" defaultMessage="รายงานการรับโบนัสแต่ละประเภท" /></h5>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columnsSummary}
                    dataSource={dataSource || []}
                    loading={isLoadingSummary}
                />
            </div>
            <Divider />
            <h5 className="mt-5 mb-3"><FormattedMessage id="reports.bonus.title_table2" defaultMessage="รายงานการรับโบนัส" /> <small className="sub-title">{`${NumberWithCommas(data?.pagination.Count)} ${FuncFormattedMessage("list", "รายการ")}`}</small></h5>
            <div className="box-white ant-box-table">
                <TablePagination
                    columns={columnsTransactions}
                    dataSource={dataTransactionsSource || []}
                    loading={isLoading}
                    totalCount={data?.pagination.Count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </Fragment>
    )
}

export default ReportBonus