import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Space,
    Row,
    Col,
    Input,
    Checkbox,
    Divider,
    TimePicker
} from 'antd'
import { toast } from 'react-toastify'
import PrefixService from '../../services/prefix'
import { usersAddConfig } from '../../@types/prefix'
import moment from 'moment'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'
import FormattedMessage from '../Elements/FormattedMessage'

interface EditProps {
    close: () => void
    onloadData: () => void
    isOpen: boolean,
    workingTime: boolean,
    data: any,
    permissionlist: any
}

const EditData: FC<EditProps> = ({ close, isOpen, onloadData, workingTime, data, permissionlist }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("users.edit", "แก้ไขผู้ใช้งาน")
    const [btnLoading, setBtnLoading] = useState(false)

    // const { prefix } = useSelector((state: ApplicationState) => state.prefix)

    const listPermissions = Object.keys(permissionlist).map((key: string, index: any) => {
        // const labelCheckbox = permissionlist[key as keyof typeof permissionlist];
        const labelCheckbox = FuncFormattedMessage(`permissions.${key}`, permissionlist[key as keyof typeof permissionlist])

        return (
            <Form.Item
                name={['permissions', `${key}`]}
                key={index}
                valuePropName="checked"
                className="mb-0">
                <Checkbox>
                    {labelCheckbox}
                </Checkbox>
            </Form.Item>
        )
    })

    const updateData = async (values: usersAddConfig) => {
        try {
            const res = await PrefixService.editUsers(values.username, values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: usersAddConfig) => {
                setBtnLoading(true)
                if (workingTime) {
                    values.work_start = moment(values.work_start).format("HH:mm:ss")
                    values.work_end = moment(values.work_end).format("HH:mm:ss")
                } else {
                    values.work_start = "00:00:00"
                    values.work_end = "00:00:00"
                }
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            form.setFieldsValue(data);
            if (data.workStart && data.workEnd) {
                form.setFieldsValue({
                    work_start: moment(data.workStart, "HH:mm:ss"),
                    work_end: moment(data.workEnd, "HH:mm:ss")
                })
            }
        }
    }, [form, isOpen, data]);

    const text_username = FuncFormattedMessage("username", "ชื่อผู้ใช้งาน")
    const text_password = FuncFormattedMessage("password", "รหัสผ่าน")
    const text_work_start = FuncFormattedMessage("users.input.work_start", "เวลาเริ่มงาน")
    const text_work_end = FuncFormattedMessage("users.input.work_end", "เวลาสิ้นสุดงาน")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="editUserPermissions"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label={text_username}
                                rules={[{ required: true }]}>
                                <Input placeholder={text_username} inputMode="text" disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="password"
                                label={text_password}
                                rules={[{ required: false }, { message: <FormattedMessage id="users.input.please_password" defaultMessage="กรุณากรอกรหัสผ่าน" /> }]}
                                hasFeedback>
                                <Input.Password placeholder={text_password} inputMode="text" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {workingTime &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <Space>
                                    <Form.Item
                                        name="work_start"
                                        label={text_work_start}
                                        rules={[{
                                            type: 'object' as const,
                                            required: true,
                                            message: <FormattedMessage id="users.input.please_work_start" defaultMessage="กรุณากรอกเวลาเริ่มงาน" />
                                        }]}
                                        hasFeedback>
                                        <TimePicker format={"HH:mm"} showNow={false} placeholder={text_work_start} />
                                    </Form.Item>
                                    -
                                    <Form.Item
                                        name="work_end"
                                        label={text_work_end}
                                        rules={[{
                                            type: 'object' as const,
                                            required: true,
                                            message: <FormattedMessage id="users.input.please_work_end" defaultMessage="กรุณากรอกเวลาสิ้นสุดงาน" />
                                        }]}
                                        hasFeedback>
                                        <TimePicker format={"HH:mm"} showNow={false} placeholder={text_work_end} />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            {listPermissions}
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i>
                                <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditData

