import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Space,
    Divider,
    Descriptions,
    Row,
    Col,
    Typography,
    Modal
} from 'antd';
import Button from 'antd-button-color'
import { InfoCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { toast } from 'react-toastify'
import { NavLink } from "react-router-dom";
import TableMiniComponent from '../Elements/TableMini'
import { getBankName } from "../../utils/bank";
import NumberWithCommas from "../../utils/NumberWithCommas";
import PrefixService from '../../services/prefix'
import ViewMember from './View'
import ViewMemberInfo from './MemberInfo'
import FormattedMessage from '../Elements/FormattedMessage';
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage';

interface ViewProps {
    member: any
    close: () => void
    isOpen: boolean
    title: string
}

const View: FC<ViewProps> = ({ close, isOpen, member, title }) => {
    // const { Text } = Typography;

    const [memberAffiliate, setMemberAffiliate] = useState<any>({})
    // const [memberInfo, setMemberInfo] = useState<any>({})
    const [dataBonusTransactions, setDataBonusTransactions] = useState<any>([])
    const [dataAffiliates, setDataAffiliates] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [knowFrom1, setKnowFrom1] = useState<string>("")
    const [knowFrom2, setKnowFrom2] = useState<string>("")

    const [viewDrawer, setViewDrawer] = useState<boolean>(false)
    const toggleView = () => setViewDrawer(!viewDrawer)
    const [isMemberInfoVisible, setIsMemberInfoVisible] = useState<boolean>(false);
    const [isReGameUserVisable, setIsReGameUserVisable] = useState<boolean>(false);
    const [memberInfoLoading, setMemberInfoLoading] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const [memberID, setMemberID] = useState<number>(0)
    const handleViewInfo = async (id: number) => {
        setMemberInfoLoading(true)
        setMemberID(id)
        setIsMemberInfoVisible(true)
        // try {
        //     const res = await PrefixService.getMemberInfo(id, true)
        //     if (res) {

        //         setMemberInfo(res)
        //         setIsMemberInfoVisible(true)
        //     }
        // } catch ({ message }) {
        //     toast.error(message as string)
        // }
        setMemberInfoLoading(false)
    }

    const handleMemberAffiliate = async (id: number) => {
        try {
            const res = await PrefixService.getMemberInfo(id)
            setMemberAffiliate(res.member);
            toggleView()
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const submitReGameUser = async (id: number) => {
        setConfirmLoading(true)
        try {
            const res = await PrefixService.setReGameUser(id)
            toast.success(res.message)
            setIsReGameUserVisable(false)
            setConfirmLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const columnsBonus: any = [
        {
            title: <FormattedMessage id="date_time" defaultMessage="วันที่/เวลา" />,
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    <div>{record.promotion.name}</div>
                </>
            )
        },
        {
            title: <FormattedMessage id="turn" defaultMessage="ยอดเทิร์น" />,
            dataIndex: 'totalTurn',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record.totalTurn, 2)}
                </div>
            )
        },
    ]
    const columnsAffiliates: any = [
        {
            title: <FormattedMessage id="member.title" defaultMessage="สมาชิก" />,
            dataIndex: 'name',
            width: '140px',
            render: (text: any, record: any) => (
                <NavLink
                    className="text-link"
                    to={`/members?search=${record.phone}`}
                    key={record.phone}
                    onClick={close}>
                    <div className="row-member">
                        <div>{record.firstName} {record.lastName}</div>
                        <div className="row-member-phone">
                            <i className="fa-solid fa-phone-square me-2"></i>
                            <span>{record.phone}</span>
                        </div>
                    </div>
                </NavLink>
            )
        },
        {
            title: <FormattedMessage id="createdAt" defaultMessage="วันที่สมัคร" />,
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
    ]

    const getMemberInfo = async () => {
        try {
            const res = await PrefixService.getMemberInfo(member.id, false)
            setDataBonusTransactions(res.bonusTransactions)
            setDataAffiliates(res.affiliates)
        } catch ({ message }) {
            toast.error(message as string)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (member && member.id) {
            getMemberInfo()
            setKnowFrom1((member.knowFrom).split(',')[0])
            setKnowFrom2((member.knowFrom).split(',')[1])
        }

        return () => {
            // setMemberInfo({})
            setDataBonusTransactions([])
            setDataAffiliates([])
            setMemberInfoLoading(false)
        }
    }, [member]) //eslint-disable-line


    const modalUserInformationTitle = FuncFormattedMessage("user-information", "ข้อมูลยูสเซอร์")
    const txtViewMember = FuncFormattedMessage("member.title-view", "ข้อมูลสมาชิก")
    const txtViewKnow_Friend = FuncFormattedMessage("friend", "เพื่อนแนะนำ")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .8}>
                {member &&
                    <>
                        <Row gutter={16} className="mb-3">
                            <Col sm={24} lg={8} >
                                <Divider orientation="left"><FormattedMessage id="member.title-view" defaultMessage="ข้อมูลสมาชิก" /></Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} >
                                    <Descriptions.Item label={<FormattedMessage id="tel" defaultMessage="เบอร์โทรศัพท์" />}>{member.phone}</Descriptions.Item>
                                    {/* <Descriptions.Item label={<FormattedMessage id="phoneVerified" defaultMessage="ยืนยันเบอร์โทรศัพท์ (OTP)" />}>
                                        {member.phoneVerified ? <FormattedMessage id="phoneVerified_true" defaultMessage="ยืนยันแล้ว" />
                                            : <FormattedMessage id="phoneVerified_false" defaultMessage="ยังไม่ยืนยัน" />
                                        }
                                    </Descriptions.Item> */}
                                    <Descriptions.Item label={<FormattedMessage id="member-name" defaultMessage="ชื่อสมาชิก" />}>{member.firstName} {member.lastName}</Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="lineid" defaultMessage="ไอดีไลน์" />}>{member.lineID}</Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="know-from" defaultMessage="รู้จักเราจาก" />}>
                                        {knowFrom1 === "friend" ?
                                            member?.affiliate?.id !== 0 ?
                                                (<Space direction="horizontal">
                                                    <button className="btn btn-warning btn-sm text-white ms-2" onClick={() => handleMemberAffiliate(member?.affiliate?.id)}><FormattedMessage id="friend" defaultMessage="เพื่อนแนะนำ" /> #{member?.affiliate?.id}</button>{knowFrom2 && `| ${knowFrom2}`}
                                                </Space>
                                                )
                                                : knowFrom2 && `${txtViewKnow_Friend} / ${knowFrom2}`
                                            : member.knowFrom}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="createdAt-member" defaultMessage="วันที่สมัครสมาชิก" />}>{member.createdAt ? moment(member.createdAt).format("DD/MM/YYYY HH:mm:ss") : '-'}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col sm={24} lg={8}>
                                <Divider orientation="left"><FormattedMessage id="bank-information" defaultMessage="ข้อมูลธนาคาร" /></Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                    <Descriptions.Item label={<FormattedMessage id="account-number" defaultMessage="เลขบัญชี" />}>{member.bankNumber}</Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="bank" defaultMessage="ธนาคาร" />}>{getBankName(member.bankCode)}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col sm={24} lg={8}>
                                <Divider orientation="left"><FormattedMessage id="credit-information" defaultMessage="ข้อมูลเครดิต" /></Divider>
                                <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                    <Descriptions.Item label={<FormattedMessage id="remaining-friend-invites" defaultMessage="ยอดเชิญเพื่อนคงเหลือ" />}>{NumberWithCommas(member?.affiliate?.balance)}</Descriptions.Item>
                                    {/* <Descriptions.Item label="ยอดคงเหลือขั้นต่ำ">{NumberWithCommas(member?.minAvailable, 2)} บาท</Descriptions.Item> */}
                                    <Descriptions.Item label={<FormattedMessage id="minimum-withdrawal" defaultMessage="ยอดถอนขั้นต่ำ" />}>{NumberWithCommas(member.minWithdraw, 2)} <FormattedMessage id="baht" defaultMessage="บาท" /></Descriptions.Item>
                                    <Descriptions.Item label={<FormattedMessage id="maximum-withdrawal" defaultMessage="ยอดถอนสูงสุด" />}>{NumberWithCommas(member.maxWithdraw, 2)} <FormattedMessage id="baht" defaultMessage="บาท" /></Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Space>

                            <Button type="primary" className="ms-2" loading={memberInfoLoading} onClick={() => handleViewInfo(member.id)}>
                                {/* {
                                    memberInfoLoading
                                        ? (<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>)
                                        : ''
                                } */}
                                <FormattedMessage id="view-user-information" defaultMessage="ดูข้อมูลยูสเซอร์" />
                            </Button>
                            <Button type="danger" className="ms-2" onClick={() => setIsReGameUserVisable(true)}><FormattedMessage id="open-new-user" defaultMessage="เปิดยูสเซอร์ใหม่" /></Button>
                        </Space>
                        <Divider />
                        <Row gutter={16}>
                            <Col sm={24} lg={12} className="mb-3">
                                <h5><FormattedMessage id="bonus-list" defaultMessage="รายการรับโบนัส" /> <small>(50 <FormattedMessage id="latest-list" defaultMessage="รายการล่าสุด" />)</small></h5>
                                <TableMiniComponent
                                    columns={columnsBonus}
                                    dataSource={dataBonusTransactions || []}
                                    loading={loading}
                                    customOffsetHeader={-24}
                                />
                            </Col>
                            <Col sm={24} lg={12} className="mb-3">
                                <h5><FormattedMessage id="friend-affiliate-list" defaultMessage="รายการแนะนำเพื่อน" /></h5>
                                <TableMiniComponent
                                    columns={columnsAffiliates}
                                    dataSource={dataAffiliates || []}
                                    loading={loading}
                                    customOffsetHeader={-24}
                                />
                            </Col>
                        </Row>
                    </>
                }

                {viewDrawer && (
                    <ViewMember
                        member={memberAffiliate as any}
                        isOpen={viewDrawer}
                        close={() => toggleView()}
                        title={`${txtViewMember} #${member?.affiliate?.id}`}
                    />
                )}


                {isMemberInfoVisible && (
                    <ViewMemberInfo
                        memberID={memberID}
                        isOpen={isMemberInfoVisible}
                        close={() => setIsMemberInfoVisible(false)}
                        title={modalUserInformationTitle}
                    />
                )}

                {/* {isMemberInfoVisible && (
                    <Modal
                        title={modalUserInformationTitle}
                        visible={isMemberInfoVisible}
                        onCancel={() => {
                            setMemberInfo({})
                            setIsMemberInfoVisible(false)
                        }}
                        footer={[
                            <Button key="submit" type="primary" onClick={() => setIsMemberInfoVisible(false)}>
                                <FormattedMessage id="close" defaultMessage="ปิด" />
                            </Button>,
                        ]}>
                        {memberInfo && (
                            <>
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary"><FormattedMessage id="username" defaultMessage="ชื่อผู้ใช้งาน" /></Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.username}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary"><FormattedMessage id="password" defaultMessage="รหัสผ่าน" /></Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.password}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary"><FormattedMessage id="credit" defaultMessage="เครดิตคงเหลือ" /></Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.credit, 2)}</Typography.Title>
                                </Space>
                                <Divider className="my-2" />
                                <Space direction="vertical" size={0}>
                                    <Text type="secondary"><FormattedMessage id="turnover" defaultMessage="เทิร์นโอเวอร์" /></Text>
                                    <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.currentTurnover, 2)}</Typography.Title>
                                </Space>
                            </>
                        )}
                    </Modal>
                )} */}

                {isReGameUserVisable && (
                    <Modal
                        visible={isReGameUserVisable}
                        onOk={() => submitReGameUser(member.id)}
                        onCancel={() => setIsReGameUserVisable(false)}
                        footer={[
                            <Button key="cancel" type="danger" onClick={() => setIsReGameUserVisable(false)}><FormattedMessage id="cancel" defaultMessage="ยกเลิก" /></Button>,
                            <Button key="submit" type="primary" loading={confirmLoading} onClick={() => submitReGameUser(member.id)}><FormattedMessage id="confirm" defaultMessage="ตกลง" /></Button>,
                        ]}
                    >
                        <div className="d-flex align-items-center">
                            <InfoCircleTwoTone twoToneColor="#ffc602" style={{ fontSize: '44px' }} />
                            <Space direction="vertical" size={0} className="ps-3">
                                <Typography.Title level={4} style={{ marginBottom: '4px' }}><FormattedMessage id="member.modal-new-user.typography-1" defaultMessage="ต้องการเปิดยูสเซอร์ใหม่?" /></Typography.Title>
                                <p style={{ fontSize: '16px', marginBottom: '0' }}><FormattedMessage id="member.modal-new-user.typography-2" defaultMessage="เมื่อเปิดเปิดยูสเซอร์ใหม่เครดิตเก่าจะหายทั้งหมด" /></p>
                            </Space>
                        </div>
                    </Modal>
                )}
            </Drawer>

        </>
    )
}
export default View
