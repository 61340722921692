import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Radio,
    Select
} from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import MaskedInput from "antd-mask-input"
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { AddSCBAccountData } from '../../../@types/scb' //, AddSCBAccountResponse, ConfirmSCBAccountOTPData
import { ThunkDispatch } from '../../../@types/store'
import PatternNumber from '../../../utils/PatternNumber'
import countryCode from '../../../mockup-json/countryCode.json'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'

interface ScbAPIProps {
    close: () => void
    isOpen: boolean

    data: any
}

const ScbAPI: FC<ScbAPIProps> = ({ close, isOpen, data }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("banks.scbapi.title", "ตั้งค่า SCB API")
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [type, setType] = useState<string>('idCard')
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const { Option } = Select

    const text_please_otp = FuncFormattedMessage("banks.scbapi.please_otp", "กรุณากรอก OTP")

    const text_confirm = FuncFormattedMessage("confirm", "ตกลง")
    const text_cancel = FuncFormattedMessage("cancel", "ยกเลิก")

    const scbOTP = async (values: AddSCBAccountData) => {
        try {
            const res = await PrefixService.requestSCBAccountOTP(values)
            toast.success(res.message)
            form.setFieldsValue({ 'deviceID': res.data.deviceID })
            const DeviceID = res.data.deviceID;
            if (res.data.deviceID) {
                Swal.fire({
                    title: 'OTP',
                    text: text_please_otp,
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: text_cancel,
                    confirmButtonText: text_confirm
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const res: any = await PrefixService.confirmSCBAccountOTP(DeviceID, {
                                accountNumber: values.accountNumber,
                                pin: values.pin,
                                otpCode: result.value,
                            })
                            toast.success(res.message)
                            setBtnLoading(false)
                            dispatch(requestAuthInit(false))
                            close()
                        } catch ({ message }) {
                            toast.error(message as string)
                            setBtnLoading(false)
                            form.setFieldsValue({ 'deviceID': "" })
                        } finally {
                            setBtnLoading(false)
                            form.setFieldsValue({ 'deviceID': "" })
                        }
                    } else {
                        setBtnLoading(false)
                        form.setFieldsValue({ 'deviceID': "" })
                    }
                })
            }
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                if (values.typeCard === "passport") {
                    values.identification = values.countryCode + values.identification
                    delete values.typeCard
                    delete values.countryCode
                }
                scbOTP(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            form.setFieldsValue(data)
            form.setFieldsValue({ "typeCard": "idCard" })
        }
    }, [form, isOpen, data]);

    const optionCountryCode = countryCode.map((item: any) => {
        const langDefault = localStorage.getItem('i18nextLng') || 'th'
        return <Option key={item.countryCode} value={item.countryCode}>{langDefault === "th" ? item.countryDescTh : item.countryDescEn}</Option>
    })

    const keyUpPassport = (value: string) => {
        form.setFieldsValue({ "identification": value.toUpperCase() })
    }

    const text_typeCard = FuncFormattedMessage("banks.scbapi.typeCard", "ประเภทบัตร")
    const text_identification = FuncFormattedMessage("banks.scbapi.identification", "เลขบัตรประชาชน")
    const text_countryCode = FuncFormattedMessage("banks.scbapi.countryCode", "ประเทศ")
    const text_select_countryCode = FuncFormattedMessage("banks.scbapi.select_countryCode", "เลือกประเทศ")
    const text_passport = FuncFormattedMessage("banks.scbapi.passport", "เลขที่หนังสือเดินทาง")
    const text_alienCard = FuncFormattedMessage("banks.scbapi.alienCard", "เลขบัตรต่างด้าว")
    const text_accountNumber = FuncFormattedMessage("banks.scbapi.accountNumber", "เลขที่บัญชี")
    const text_birthDate = FuncFormattedMessage("banks.scbapi.birthDate", "วัน/เดือน/ปีเกิด(ค.ศ.)")
    const text_tel = FuncFormattedMessage("tel", "เบอร์โทรศัพท์")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="ScbAPI"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={text_typeCard}
                                name="typeCard"
                                rules={[{ required: true, message: <FormattedMessage id="banks.scbapi.please_select_typeCard" defaultMessage="กรุณาเลือกประเภทบัตร" /> }]}>
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="idCard" onClick={() => setType('idCard')}><FormattedMessage id="banks.scbapi.option_typeCard.idCard" defaultMessage="บัตรประชาชน" /></Radio.Button>
                                    <Radio.Button value="passport" onClick={() => setType('passport')}><FormattedMessage id="banks.scbapi.option_typeCard.passport" defaultMessage="พาสปอร์ต" /></Radio.Button>
                                    <Radio.Button value="alienCard" onClick={() => setType('alienCard')}><FormattedMessage id="banks.scbapi.option_typeCard.alienCard" defaultMessage="บัตรต่างด้าว" /></Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            {type === "idCard" &&
                                <Form.Item
                                    name="identification"
                                    label={text_identification}
                                    rules={[
                                        { required: true, message: <FormattedMessage id="banks.scbapi.please_identification" defaultMessage="กรุณากรอกเลขบัตรประชาชน" /> },
                                        { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                    ]}
                                    hasFeedback>
                                    <Input placeholder={text_identification} showCount maxLength={13} inputMode='numeric' />
                                </Form.Item>
                            }
                            {type === "passport" &&
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="countryCode"
                                            label={text_countryCode}
                                            rules={[{ required: true, message: <FormattedMessage id="banks.scbapi.please_select_countryCode" defaultMessage="กรุณาเลือกประเทศ" /> }]}
                                            hasFeedback>
                                            <Select placeholder={text_select_countryCode}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {optionCountryCode}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name="identification"
                                            label={text_passport}
                                            rules={[{ required: true, message: <FormattedMessage id="banks.scbapi.please_passport" defaultMessage="กรุณากรอกเลขที่หนังสือเดินทาง" /> }]}
                                            hasFeedback>
                                            <Input placeholder={text_passport} onKeyUp={(e: any) => keyUpPassport(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            {type === "alienCard" &&
                                <Form.Item
                                    name="identification"
                                    label={text_alienCard}
                                    rules={[
                                        { required: true, message: <FormattedMessage id="banks.scbapi.please_alienCard" defaultMessage="กรุณากรอกเลขบัตรต่างด้าว" /> },
                                        { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                    ]}
                                    hasFeedback>
                                    <Input placeholder={text_alienCard} showCount maxLength={13} inputMode='numeric' />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountNumber"
                                label={text_accountNumber}
                                rules={[
                                    { required: true, message: <FormattedMessage id="banks.scbapi.please_accountNumber" defaultMessage="กรุณากรอกเลขที่บัญชี" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <Input placeholder={text_accountNumber} inputMode='numeric' disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="birthDate"
                                label={text_birthDate}
                                rules={[{ required: true, message: <FormattedMessage id="banks.scbapi.please_birthDate" defaultMessage="กรุณากรอกวัน/เดือน/ปีเกิด(ค.ศ.)" /> }]}
                                hasFeedback>
                                <MaskedInput mask={'00/00/0000'} placeholder={text_birthDate} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="phone"
                                label={text_tel}
                                rules={[
                                    { required: true, message: <FormattedMessage id="please-enter-your-phone-number" defaultMessage="กรุณากรอกเบอร์โทรศัพท์" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <Input placeholder={text_tel} showCount maxLength={10} inputMode='numeric' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="pin"
                                label="PIN"
                                rules={[
                                    { required: true, message: <FormattedMessage id="banks.scbapi.please_pin" defaultMessage="กรุณากรอก PIN" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <Input placeholder="PIN" showCount maxLength={6} inputMode='numeric' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i>
                                <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>

            </Drawer>
        </>
    )
}
export default ScbAPI

