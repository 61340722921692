import React, { FC } from 'react'
import ProfileButton from './ProfileButton'
import NamePrefix from './NamePrefix'
// import { MenuUnfoldOutlined, CloseOutlined } from '@ant-design/icons';
// import TranslationButton from './TranslationButton'
interface HeaderProps {
    toggleNavbar: boolean
    setToggleNavbar: (toggle: boolean) => void
}

const HeaderMobile: FC<HeaderProps> = ({ toggleNavbar, setToggleNavbar }) => {
    return (
        <nav className="navbar bo-navbar sticky-top d-lg-none py-3">
            <div className="container-fluid">
                <div className="bo-nav-toggle" onClick={() => setToggleNavbar(!toggleNavbar)}>
                    <i className={"far fa-bars " + (toggleNavbar ? '' : 'open')}></i>
                    <i className={"far fa-times " + (toggleNavbar ? 'open' : '')}></i>
                    {/* {toggleNavbar ?
                        <CloseOutlined className="profile-icon" style={{ fontSize: '20px', padding: '4px 8px' }} /> :
                        <MenuUnfoldOutlined className="profile-icon" style={{ fontSize: '20px', padding: '4px 8px' }} />} */}
                </div>

                <NamePrefix className='name-prefix-mobile' />
                {/* <TranslationButton /> */}
                <ProfileButton />
            </div>
        </nav>
    )
}

export default HeaderMobile