import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Input, Row, Col, Divider, Space, Button, Switch } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import { recaptchaConfig } from '../../../@types/prefix'
import useFetchSetting from "../../../hooks/useFetchSetting"
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage'

const Recaptcha = () => {

    const [form] = Form.useForm();
    const { data, mutate } = useFetchSetting()

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: recaptchaConfig) => {
        try {
            const res = await PrefixService.updateRecaptcha(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: recaptchaConfig) => {
                setBtnLoading(true)
                values.siteKey = values.siteKey.replace(/\s/g, '')
                values.secretKey = values.secretKey.replace(/\s/g, '')
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }


    useEffect(() => {
        if (form && data) {
            form.resetFields();
            form.setFieldsValue(data?.setting?.recaptcha)
        }
    }, [form, data])// eslint-disable-line


    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="menu-setting-recaptcha" defaultMessage="ตั้งค่า reCaptcha" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="recaptchaForm"
                    layout="vertical"
                    hideRequiredMark
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="enable"
                                label={<FormattedMessage id="status" defaultMessage="สถานะ" />}
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="siteKey"
                                label="Site Key"
                            >
                                <Input placeholder="Site Key" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="secretKey"
                                label="Secret Key"
                            >
                                <Input placeholder="Secret Key" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i>
                                <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default Recaptcha