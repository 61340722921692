import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Switch,
    Tooltip,
    message
} from 'antd'
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { BankConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import { bankSelect, bankApp } from '../../../utils/bank'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'

interface EditBankProps {
    close: () => void
    isOpen: boolean

    data: any
}

const EditBank: FC<EditBankProps> = ({ close, isOpen, data }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("banks.edit", "แก้ไขบัญชีธนาคาร")
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [btnClearLoading, setBtnClearLoading] = useState<boolean>(false)
    const [btnApiEndpointLoading, setBtnApiEndpointLoading] = useState<boolean>(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [nameBankApp, setNameBankApp] = useState<string>("")

    const [smsAPIEndpoint, setSmsAPIEndpoint] = useState<string>("")

    const text_confirm = FuncFormattedMessage("confirm", "ตกลง")
    const text_cancel = FuncFormattedMessage("cancel", "ยกเลิก")


    const updateData = async (values: BankConfig) => {
        try {
            const res = await PrefixService.editBankAccount(values)
            setBtnLoading(false)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: BankConfig) => {
                setBtnLoading(true)
                values.bank = data.bank
                values.oldAccountNumber = data.accountNumber
                values.warning = data.warning
                if (values.meta.password_new !== undefined) {
                    values.meta.password = values.meta.password_new
                }
                delete values.meta.password_new
                updateData(values)
            })
            .catch((errorInfo) => { });
    };

    const handleCopy = (values: string) => {
        navigator.clipboard.writeText(values)
        message.success('Successfully copied data');
    }

    const textHandleReGenAPIEndpoint = {
        title: FuncFormattedMessage("handleReGenAPIEndpoint.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handleReGenAPIEndpoint.text", "คุณต้องการสร้าง SMS API ENDPOINT ใหม่ใช่หรือไม่")
    }
    const handleReGenAPIEndpoint = () => {
        setBtnApiEndpointLoading(true)
        Swal.fire({
            title: `${textHandleReGenAPIEndpoint.title}`,
            text: `${textHandleReGenAPIEndpoint.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setBtnApiEndpointLoading(false)
                    const res = await PrefixService.regenSMSAccount(data)
                    dispatch(requestAuthInit(false))
                    toast.success(res.message)
                    setSmsAPIEndpoint(res.endpoint ? res.endpoint : "")
                } catch ({ message }) {
                    toast.error(message as string)
                    setBtnApiEndpointLoading(false)
                }
            } else {
                setBtnApiEndpointLoading(false)
            }
        })
    }
    const textHandleClear = {
        title: FuncFormattedMessage("handleClear.title", "แจ้งเตือน!"),
        text1: FuncFormattedMessage("handleClear.text1", "คุณต้องการล้างการตั้งค่า"),
        text2: FuncFormattedMessage("handleClear.text2", "นี้ใช่หรือไม่")
    }
    const handleClear = async () => {
        Swal.fire({
            title: `${textHandleClear.title}`,
            text: `${textHandleClear.text1} ${nameBankApp} ${textHandleClear.text2}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    data.meta.username = ""
                    data.meta.password = ""
                    data.oldAccountNumber = data.accountNumber
                    const res = await PrefixService.editBankAccount(data)
                    setBtnClearLoading(false)
                    dispatch(requestAuthInit(false))
                    toast.success(res.message)
                    form.setFieldsValue(data)
                    form.setFieldsValue({ "bank": bankSelect[data.bank] })
                    setNameBankApp(bankApp[data.bank])
                } catch ({ message }) {
                    toast.error(message as string)
                    setBtnClearLoading(false)
                }
            }
        })
    }

    useEffect(() => {
        if (form && isOpen && data) {

            // const nameBank = FuncFormattedMessage(`banks.input.option_banks.${data.bank}`, bankSelect[data.bank])
            // console.log('====================================');
            // console.log(nameBank);
            // console.log('====================================');
            form.resetFields();
            form.setFieldsValue(data)
            form.setFieldsValue({ "bank": bankSelect[data.bank] })
            setNameBankApp(bankApp[data.bank])
            setSmsAPIEndpoint(data.apiURL)
        }
    }, [form, isOpen, data]);

    const text_accountName = FuncFormattedMessage("banks.input.accountName", "ชื่อบัญชี")
    const text_accountNumber = FuncFormattedMessage("banks.input.accountNumber", "เลขบัญชี")
    const text_bank = FuncFormattedMessage("banks.input.bank", "ธนาคาร")
    const text_status = FuncFormattedMessage("status", "สถานะ")
    const text_hide = FuncFormattedMessage("banks.columns.hide", "ซ่อนจากเว็บไซต์")
    const text_username = FuncFormattedMessage("banks.input.username", "ชื่อผู้ใช้งาน")
    const text_password = FuncFormattedMessage("banks.input.password", "รหัสผ่าน")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="EditBank"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="enable"
                                label={text_status}
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="hide"
                                label={text_hide}
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountName"
                                label={text_accountName}
                                rules={[{ required: true, message: <FormattedMessage id="banks.input.please_accountName" defaultMessage="กรุณากรอกชื่อบัญชี" /> }]}
                                hasFeedback>
                                <Input placeholder={text_accountName} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="accountNumber"
                                label={text_accountNumber}
                                rules={[{ required: true, message: <FormattedMessage id="banks.input.please_accountNumber" defaultMessage="กรุณากรอกเลขที่บัญชี" /> }]}
                                hasFeedback>
                                <Input placeholder={text_accountNumber} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {data?.bank !== "truewallet" &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="bank"
                                    label={text_bank}>
                                    <Input placeholder={text_bank} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    {/* {data?.apiURL &&  } */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="apiURL"
                                label="SMS API ENDPOINT">
                                <Input.Group className="d-flex" compact>
                                    <Tooltip title={<FormattedMessage id="banks.input.reGenAPIEndpoint" defaultMessage="สร้าง SMS API ENDPOINT ใหม่" />}>
                                        <Button icon={<ReloadOutlined />} loading={btnApiEndpointLoading} onClick={() => handleReGenAPIEndpoint()} />
                                    </Tooltip>
                                    <Input
                                        style={{ width: 'calc(100% - 200px)' }}
                                        placeholder="API ENDPOINT"
                                        disabled
                                        value={smsAPIEndpoint} />
                                    <Tooltip title={<FormattedMessage id="banks.input.copyEndpoint" defaultMessage="คัดลอก SMS API ENDPOINT" />}>
                                        <Button icon={<CopyOutlined />} disabled={data?.apiURL ? false : true} onClick={() => handleCopy(smsAPIEndpoint)} />
                                    </Tooltip>
                                </Input.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    {data?.bank && data?.bank !== "truewallet" &&
                        <>
                            <Divider orientation="left"><FormattedMessage id="banks.input.setting" defaultMessage="ตั้งค่า" /> {nameBankApp}</Divider>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name={['meta', 'username']}
                                        label={text_username}
                                        rules={[{ required: false, message: <FormattedMessage id="banks.input.please_username" defaultMessage="กรุณากรอกชื่อผู้ใช้งาน" /> }]}
                                        hasFeedback>
                                        <Input placeholder={text_username} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name={['meta', 'password_new']}
                                        label={text_password}
                                        rules={[{ required: false, message: <FormattedMessage id="banks.input.please_password" defaultMessage="กรุณากรอกรหัสผ่าน" /> }]}
                                        hasFeedback>
                                        <Input placeholder={text_password} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Button type="primary" size="large" disabled={btnClearLoading} onClick={handleClear}>
                                        <FormattedMessage id="banks.input.clear_setting" defaultMessage="ล้างการตั้งค่า" /> <span className="ms-1">{nameBankApp}</span>
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    }
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditBank

