import { CommonResponse, CommonResponseRegen, CommonResponseRedeem, PaginationParams } from '../@types/api'
import { DateRangeType } from '../@types/dashboard'
import {
	GetMemberResponse,
	GetMemberTransactionListParams,
	MemberTransaction,
	UpdateMemberInfoParams,
	MemberBankAccount,
	MemberDuplicate,
	MemberData,
	GetMemberReportBetflixParams,
	MemberReportBetflixResp
} from '../@types/member'

import {
	AffiliateConfig,
	BankConfig,
	CashbackConfig,
	CentralAccountConfig,
	PromotionConfig,
	PromotionConfigAdd,
	SCBAccountConfig,
	WheelConfig,
	WithdrawAccountConfig,
	NewRegBonusConfig,
	RedeemAddConfig,
	// RedeemDelConfig,
	settingPageConfig,
	PrefixSettingConfig,
	usersConfig,
	usersAddConfig,
	usersEditConfig,
	ambPaymentConfig,
	ambGamesConfigEdit,
	AddBankParams,
	// SCBConfig,
	TrueWalletQrCodeConfig,
	TrueWalletVoucherConfig,
	settingPageNotiConfig,
	RedeemData,
	TrueWalletCallbackConfig,
	RankSetting,
	PointCalurateSetting,
	IUpdateTheme,
	recaptchaConfig,
	ambKingSportBonusConfig
} from '../@types/prefix'
import {
	FixTransactionParams,
	GetFixTxListParams,
	GetTransactionListParams,
	TransactionListResponse,
	WithdrawTransaction,
	GetBalance,
	FixtxDataSummary,
	BankTransactionData,
	FixTransaction
} from '../@types/transactions'
import { ReportByMemberTransaction } from '../@types/report'
import { GetLogListParams, LogsData } from '../@types/logs'
import { AddSCBAccountData, AddSCBAccountResponse, ConfirmSCBAccountOTPData, SCBTransactions } from '../@types/scb'
import { truewalletRequestOTPParams, truewalletConfirmOTPParams, truewalletOTPResponse } from '../@types/truewallet'
import { SetMemberActiveParams } from '../store/prefix/prefix.actions'
import api from '../utils/api'
import { Commission, CommissionStartTxIdUpdate, CommissionStatus, CommissionUpdate } from '../@types/commission'


class PrefixService {
	private prefix: string = ''

	constructor() {
		const prefix = localStorage.getItem('x-prefix')
		if (prefix) {
			this.setPrefix(prefix)
		}
	}

	getPrefix(): string {
		return this.prefix
	}
	setPrefix(prefix: string, save: boolean = false) {
		this.prefix = prefix

		if (save) {
			localStorage.setItem('x-prefix', prefix.toUpperCase())
		}
	}

	savePrefixSetting(params: PrefixSettingConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/website`, params)
	}

	setMainDeposit(data: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/banks/${data}/main-deposit`)
	}

	setCancelMainDeposit(data: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/banks/${data}/main-deposit`)
	}

	getMemberList(dateRange: DateRangeType, search: string = '', params?: PaginationParams): Promise<MemberData> {
		return api.get<void, MemberData>('/members', {
			params: {
				start: dateRange.start,
				end: dateRange.end,
				search: search,
				limit: params?.limit,
				page: params?.page,
			},
		})
	}

	getMemberInfo(id: number | string, credit?: boolean): Promise<GetMemberResponse> {
		if (credit) {
			return api.get<void, GetMemberResponse>(`/members/${id}?credit=${credit}`)
		} else {
			return api.get<void, GetMemberResponse>(`/members/${id}`)
		}
	}

	updateMemberInfo(params: UpdateMemberInfoParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/members/${params.id}`, params)
	}

	setMemberActive(params: SetMemberActiveParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/members/${params.id}/status`, {
			status: params.status,
			remark: params.remark,
		})
	}

	setReGameUser(id: number | string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/members/${id}/game-user`)
	}

	getTransactionList<T>(params: GetTransactionListParams, pagi?: PaginationParams): Promise<TransactionListResponse<T>> {
		return api.get<void, TransactionListResponse<T>>(`/${params.type}`, {
			params: {
				start: params.dateRange?.start,
				end: params.dateRange?.end,
				search: params.search,
				sort: params.sort,
				offset: params.offset,
				status: params.status,
				limit: pagi?.limit,
				page: pagi?.page,
			},
		})
	}

	getTransactionSummary(params: GetFixTxListParams): Promise<BankTransactionData> {
		return api.get<void, BankTransactionData>('/bank-transactions/summary', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
			},
		})
	}

	addPromotionConfig(data: PromotionConfigAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/promotions', data)
	}

	savePromotionConfig(key: string, data: PromotionConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/promotions/${key}`, data)
	}

	deletePromotionConfig(keyPromotion: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/promotions/${keyPromotion}`)
	}

	getNewRegBonus(): Promise<NewRegBonusConfig> {
		return api.get<void, NewRegBonusConfig>(`/new-reg-bonus/setting`)
	}

	saveNewRegBonus(data: NewRegBonusConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/new-reg-bonus/setting`, data)
	}

	saveWheelConfig(data: WheelConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/wheel', data)
	}

	getRedeem(search: string = '', params?: PaginationParams): Promise<RedeemData> {
		return api.get<void, RedeemData>(`/setting/website/redeem`, {
			params: {
				limit: params?.limit,
				page: params?.page,
				search: search,
			},
		}
		)
	}

	addRedeem(data: RedeemAddConfig): Promise<CommonResponseRedeem> {
		return api.post<void, CommonResponseRedeem>('/setting/website/redeem', data)
	}

	editRedeem(data: RedeemAddConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>('/setting/website/redeem', data)
	}

	deleteRedeem(key: any): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/website/redeem`, key)
	}

	getLogList(search: string = '', params: GetLogListParams, pagi?: PaginationParams): Promise<LogsData> {
		return api.get<void, LogsData>('/log', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
				search: search,
				limit: pagi?.limit,
				page: pagi?.page,
			},
		})
	}

	saveCashbackConfig(data: CashbackConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/cashback', data)
	}

	saveAffiliateConfig(data: AffiliateConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/affiliate/setting', data)
	}

	getSetting(): Promise<settingPageConfig> {
		return api.get<void, settingPageConfig>(`/setting/website`)
	}

	updateSetting(data: settingPageConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website', data)
	}

	updateRank(data: RankSetting): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/rank', data)
	}

	updatePointCalurate(data: PointCalurateSetting): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/point-calurate', data)
	}

	updateNotiSetting(data: settingPageNotiConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/noti-broken-withdraw', data)
	}

	updateAmbPayment(data: ambPaymentConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/amb-payment', data)
	}

	updateAmbGames(data: ambGamesConfigEdit): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/ambbet-games', data)
	}

	uploadPromotionImage(
		promotionID: string,
		data: any
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params: {
				key: promotionID
			}
		})
	}

	deletePromotionImage(
		promotionID: string,
	): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/promotions/${promotionID}/image`)
	}

	uploadSettingImage(
		data: any
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	}

	getFixTxList(params: GetFixTxListParams, pagi?: PaginationParams): Promise<FixTransaction[]> {
		return api.get<void, FixTransaction[]>('/fix-tx', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
				limit: pagi?.limit,
				page: pagi?.page,
			},
		})
	}

	getFixTxSummary(params: GetFixTxListParams): Promise<FixtxDataSummary> {
		return api.get<void, FixtxDataSummary>('/fix-tx/summary', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
			},
		})
	}

	fixTx(data: FixTransactionParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/fix-tx', data)
	}
	getUsers(): Promise<usersConfig> {
		return api.get<void, usersConfig>(`/users`)
	}

	getUsersPermissions(): Promise<object> {
		return api.get<void, object>(`/users/permissions`)
	}

	delUsersPermissions(username: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/users/${username}`)
	}

	addUsers(data: usersAddConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/users', data)
	}

	editUsers(username: string, data: usersEditConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/${username}`, data)
	}

	generate2FA(userID: number): Promise<CommonResponse> {
		return api.get<void, CommonResponse>(`/users/2fa/${userID}/generate`)
	}

	enable2FA(userID: number, payload: any): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/2fa/${userID}/enable`, payload)
	}

	disable2FA(userID: number): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/2fa/${userID}/disable`)
	}

	getSummaryWithPath<T>(path: string, dateRange: DateRangeType, pagi?: PaginationParams) {
		return api.get<void, T>(`/summary/${path}`, {
			params: {
				start: dateRange.start,
				end: dateRange.end,
				limit: pagi?.limit,
				page: pagi?.page,
			},
		})
	}

	getReportByMemberList(params: GetTransactionListParams): Promise<ReportByMemberTransaction[]> {
		return api.get<void, ReportByMemberTransaction[]>(`/summary/report-by-member`, {
			params: {
				start: params.dateRange?.start,
				end: params.dateRange?.end,
			},
		})
	}

	getMemberBankAccount(id: number | string): Promise<MemberBankAccount> {
		return api.get<void, MemberBankAccount>(`/members/${id}/bank-account`)
	}

	getMemberTransactionList(params: GetMemberTransactionListParams): Promise<MemberTransaction[]> {
		return api.get<void, MemberTransaction[]>(`/members/${params.id}/transactions`, {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
				type: params.type,
			},
		})
	}

	getMemberReportBetflix(params: GetMemberReportBetflixParams): Promise<MemberReportBetflixResp> {
		return api.get<void, MemberReportBetflixResp>(`/members/${params.id}/game-report`, {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
			},
		})
	}

	saveWithdrawAccount(data: WithdrawAccountConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/withdraw-account', data)
	}

	saveCentralAccount(data: CentralAccountConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/scb-auto', data)
	}

	saveBankAccount(data: BankConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/banks/${data.accountNumber}`, data)
	}

	editBankAccount(data: BankConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/banks/${data.oldAccountNumber}`, data)
	}

	addBankAccount(data: AddBankParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/banks`, data)
	}

	toggleBank(accountNumber: string, type: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/banks/${accountNumber}/toggle?type=${type}`)
	}

	deleteBankAccount(accountNumber: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/banks/${accountNumber}`)
	}

	toggleScbAccount(accountNumber: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${accountNumber}/toggle`)
	}

	regenSMSAccount(data: BankConfig): Promise<CommonResponseRegen> {
		return api.patch<void, CommonResponseRegen>(`/setting/banks/${data.accountNumber}/regen-sms`, data)
	}

	withdrawToCentral(accountNumber: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${accountNumber}/withdraw-central`)
	}

	setWithdraw(accountNumber: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${accountNumber}/withdraw`)
	}

	resetRank(): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/website/reset-rank`)
	}

	getSCBTransactionList(dateRange: DateRangeType): Promise<SCBTransactions[]> {
		return api.get<void, SCBTransactions[]>(`/setting/scb-auto/transactions`, {
			params: {
				start: dateRange.start,
				end: dateRange.end
			},
		})
	}

	// getSCBAccount(): Promise<SCBConfig> {
	// 	return api.get<void, SCBConfig>(`/setting/scb`)
	// }

	saveTrueWalletQrCode(data: TrueWalletQrCodeConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/truewallet-qr-deposit`, data)
	}

	saveTrueWalletVoucher(data: TrueWalletVoucherConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/truewallet-voucher`, data)
	}

	saveTrueWalletCallback(data: TrueWalletCallbackConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/truewallet-callback`, data)
	}

	saveTrueWalletCallbackSwitch(data: any): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/truewallet-callback-switch`, {
			status: data,
		})
	}

	getSCBBalance(accountNumber: string): Promise<GetBalance> {
		return api.get<void, GetBalance>(`/setting/scb/${accountNumber}/balance`)
	}

	getKbizBalance(accountNumber: string): Promise<GetBalance> {
		return api.get<void, GetBalance>(`/setting/banks/${accountNumber}/kbiz-balance`)
	}

	saveSCBAccount(data: SCBAccountConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/scb/${data.accountNumber}`, data)
	}

	deleteSCBAccount(accountNumber: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/scb/${accountNumber}`)
	}

	requestSCBAccountOTP(data: AddSCBAccountData): Promise<AddSCBAccountResponse> {
		return api.post<void, AddSCBAccountResponse>('/setting/scb', data)
	}

	confirmSCBAccountOTP(
		deviceID: string,
		data: ConfirmSCBAccountOTPData
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${deviceID}/confirm-otp`, data)
	}

	requestTruewalletOTP(data: truewalletRequestOTPParams): Promise<truewalletOTPResponse> {
		return api.post<void, truewalletOTPResponse>('/setting/truewallet/request-otp', data)
	}

	confirmTruewalletOTP(data: truewalletConfirmOTPParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/truewallet/confirm-otp', data)
	}

	redoWithdraw(tx: WithdrawTransaction): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/redo`)
	}

	confirmWithdraw(tx: WithdrawTransaction, transfer: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/confirm?transfer=${transfer}`)
	}

	rejectWithdraw(tx: WithdrawTransaction, remark: string, refund: boolean): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/reject`, {
			remark: remark,
			refund: refund,
		})
	}

	checkTxDeposit(txID: number): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/transactions/${txID}/check`)
	}

	confirmTxDeposit(txID: number): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/transactions/${txID}/confirm`)
	}

	cancelTxDeposit(txID: number, remark: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/transactions/${txID}/cancel`, {
			remark: remark,
		})
	}

	duplicateBankTxDeposit(txID: number): Promise<MemberDuplicate[]> {
		return api.get<void, MemberDuplicate[]>(`/fix-tx/${txID}/duplicate`)
	}

	logout(): Promise<CommonResponse> {
		return api.get<void, CommonResponse>(`/logout`)
	}

	confirmDuplicateBankTxDeposit(txID: number, memberID: number): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/fix-tx/${txID}/duplicate`, {
			memberID: memberID,
		})
	}

	updateTheme(data: IUpdateTheme): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/website/theme`, data)
	}

	updateRecaptcha(data: recaptchaConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/website/recaptcha`, data)
	}

	getCommission(): Promise<Commission> {
		return api.get<void, Commission>(`/setting/website/commission`)
	}

	updateStatusCommission(data: CommissionStatus): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/website/commission/status`, data)
	}

	updateCommission(data: CommissionUpdate): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/setting/website/commission`, data)
	}

	updateCommissionStartTxId(data: CommissionStartTxIdUpdate): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/website/commission/start-tx-id`, data)
	}

	updateAMBKingSportBonus(data: ambKingSportBonusConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/website/ambking-sport-bonus`, data)
	}

	updateAMBKingGames(data: ambGamesConfigEdit): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/ambking-games', data)
	}

	deletePopup(key: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/member-popup/${key}`)
	}

	uploadPopupImage(
		popupKey: string,
		data: any
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params: {
				key: popupKey
			}
		})
	}
}

export default new PrefixService()
