import { FixTransaction } from '../@types/transactions'
import NumberWithCommas from './NumberWithCommas'

export const getTxType = (type: string) => {
	switch (type) {
		case 'promotion': {
			return 'รับโบนัส'
		}
		case 'cashback': {
			return 'รับแคชแบ็ก'
		}
		case 'wheel': {
			return 'หมุนกงล้อเสี่ยงโชค'
		}
		case 'affiliate_transfer': {
			return 'โยกเงินเชิญเพื่อน'
		}
		default: {
			return type.toLocaleUpperCase()
		}
	}
}

export const sumFixBank = (transactions: FixTransaction[]) => {
	const sum = transactions.reduce((prev, row) => {
		if (row.type === 'deposit' && row.transferFrom !== 'TRUEWALLET') {
			return prev + row.amount
		}
		return prev
	}, 0)
	return NumberWithCommas(sum, 2)
}
export const sumFixTW = (transactions: FixTransaction[]) => {
	const sum = transactions.reduce((prev, row) => {
		if (row.type === 'deposit' && row.transferFrom === 'TRUEWALLET') {
			return prev + row.amount
		}
		return prev
	}, 0)
	return NumberWithCommas(sum, 2)
}
export const sumFixAddCredit = (transactions: FixTransaction[]) => {
	const sum = transactions.reduce((prev, row) => {
		if (row.type === 'add') {
			return prev + row.amount
		}
		return prev
	}, 0)
	return NumberWithCommas(sum, 2)
}
export const sumFixDeductCredit = (transactions: FixTransaction[]) => {
	const sum = transactions.reduce((prev, row) => {
		if (row.type === 'deduct') {
			return prev + row.amount
		}
		return prev
	}, 0)
	return NumberWithCommas(sum, 2)
}
