import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'

export interface UseAPIRequest<T> {
	data: T | null
	loading: boolean

	updateData: Dispatch<SetStateAction<T | null>>
	update: (v?: boolean) => void
}

export const useAPIRequest = <T>(
	request: () => Promise<T>,

	interval: number = -1,
	hookInterval?: (data: T) => Promise<T>
): UseAPIRequest<T> => {
	const [data, setData] = useState<T | null>(null)
	const [loading, setLoading] = useState(false)

	const mounted = useRef(true)
	const _request = useCallback(
		async (v: boolean = true) => {
			v && setLoading(true)

			try {
				const res = await request()
				if (!mounted.current) {
					return false
				}

				setData(res)
				v && setLoading(false)
			} catch {
				if (!mounted.current) {
					return false
				}

				v && setLoading(false)
			}
		},
		[request]
	)

	useEffect(() => {
		let _updateInterval: NodeJS.Timeout
		if (data && interval > 0) {
			_updateInterval = setTimeout(() => {
				hookInterval &&
					hookInterval(data).then((res) => {
						setData(res)
					})
			}, interval)
		}

		return () => {
			clearTimeout(_updateInterval)
		}
	}, [interval, hookInterval, data])

	// unmount
	useEffect(() => {
		mounted.current = true
		_request()

		return () => {
			mounted.current = false
		}
	}, [_request])

	return {
		data,
		loading,

		updateData: setData,
		update: _request,
	}
}
