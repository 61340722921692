import { Button, Checkbox, Col, DatePicker, Divider, Form, InputNumber, Row, Space, Spin, Switch, Typography } from 'antd'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormattedMessage from '../../../../components/Elements/FormattedMessage';
import PatternNumber from '../../../../utils/PatternNumber';
import { FuncFormattedMessage } from '../../../../utils/FuncFormattedMessage';
import moment from 'moment';
import type { RangePickerProps } from 'antd/es/date-picker';
import PrefixService from '../../../../services/prefix'
import { toast } from 'react-toastify';
import useFetchCommission from '../../../../hooks/useFetchCommission';
import { CommissionStartTxIdUpdate, CommissionUpdate } from '../../../../@types/commission';
interface CommissionProps {
    setActiveKey: (key: string) => void
    setIsUpdate: (isUpdate: boolean) => void
}

const Commission: FC<CommissionProps> = ({ setActiveKey, setIsUpdate }) => {
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [btnLoading2, setBtnLoading2] = useState<boolean>(false)
    const [btnSwitchLoading, setBtnSwitchLoading] = useState<boolean>(false)
    const [depositLoading, setDepositLoading] = useState<boolean>(false)
    const [textStartTxId, setTextStartTxId] = useState<number>(0)
    const [textTotalPercentage, setTextTotalPercentage] = useState<number>(0)
    const [textDepositLastTxId, setTextDepositLastTxId] = useState<number>(0)
    // const commissionData: any = []

    const { data, mutate } = useFetchCommission()

    // const range = (start: number, end: number) => {
    //     const result = [];
    //     for (let i = start; i < end; i++) {
    //         result.push(i);
    //     }
    //     return result;
    // };

    // const isDeleted = (key: number) => {
    //     if (affiliateData[key] !== undefined) {
    //         affiliateData[key].deleted = true
    //     }
    // }

    // const isAdd = () => {
    //     commissionData.push({ percentage: 0 })
    // }

    const updateData = async (values: CommissionStartTxIdUpdate) => {
        try {
            const res = await PrefixService.updateCommissionStartTxId(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const updateData2 = async (values: CommissionUpdate) => {
        try {
            const res = await PrefixService.updateCommission(values)
            setBtnLoading2(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading2(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                values.dateStartTxId = moment(values.dateStartTxId).format('YYYY-MM-DD')
                values.comfirmStartTxIdZero = values.comfirmStartTxIdZero || false
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }
    const handleFormSubmit2 = () => {
        form2.validateFields()
            .then((values: any) => {
                setBtnLoading2(true)
                const transformedData = values.sharePercentage.reduce((acc: any, cur: any, idx: any) => {
                    acc[idx + 1] = cur;
                    return acc;
                }, {});
                values.sharePercentage = transformedData
                delete values.status
                updateData2(values);
            })
            .catch((errorInfo) => {

            });
    }

    useEffect(() => {
        if (form && form2 && data) {
            form.resetFields();
            form2.resetFields();
            const arrayDataSharePercentage = Object.entries(data.data.info.sharePercentage)
                .map(([key, value]: any) => ({ percentage: value.percentage }));
            totalPercentage(arrayDataSharePercentage)
            form.setFieldsValue({
                "dateStartTxId": moment(data.commission.dateStartTxId, "YYYY-MM-DD"),
                "startTxId": data.commission.startTxId,
            })
            form2.setFieldsValue({
                "status": data.data.info.status === "ENABLE" ? true : false,
                "portion": data.data.info.portion,
                "maxPortion": data.data.info.maxPortion,
                "minWithdraw": data.data.info.minWithdraw,
                "sharePercentage": arrayDataSharePercentage,
            })
            setTextStartTxId(data.commission.startTxId)
            setTextDepositLastTxId(data.data.info.depositLastTxId)
        }
    }, [form, form2, data])// eslint-disable-line

    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    // const disabledDateTime = () => ({
    //     disabledHours: () => range(0, 24).splice(4, 20),
    //     disabledMinutes: () => range(30, 60),
    //     disabledSeconds: () => [55, 56],
    // });

    const fetchStartTxId = async (e: any) => {
        setDepositLoading(true)
        try {
            const res: any = await PrefixService.getTransactionList(
                {
                    dateRange: {
                        start: moment(e).format('YYYY-MM-DD'),
                        end: moment(e).format('YYYY-MM-DD')
                    },
                    type: 'deposit',
                    sort: 'ASC',
                    status: 'success'
                }
                , { page: 1, limit: 1 })
            if (res.count > 0) {
                form.setFieldsValue({
                    "startTxId": res.transactions[0].id - 1,
                    "comfirmStartTxIdZero": false
                })
                setTextStartTxId(res.transactions[0].id - 1)
                setDepositLoading(false)
            } else {
                toast.error("ไม่พบรายการฝาก กรุณาเลือกวันอื่น")
                form.setFieldsValue({
                    "startTxId": 0,
                    "comfirmStartTxIdZero": true
                })
                setTextStartTxId(0)
                setDepositLoading(false)
            }
        } catch ({ message }) {
            toast.error(message as string)
            setDepositLoading(false)
        }
    }

    const updateStatusCommission = async (status: boolean) => {
        setBtnSwitchLoading(true)
        try {
            let dataStatus = {
                status: "DISABLE"
            }
            if (status) {
                dataStatus = {
                    status: "ENABLE"
                }
            }
            const res = await PrefixService.updateStatusCommission(dataStatus)
            setBtnSwitchLoading(false)
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnSwitchLoading(false)
        }
    }

    const onValuesChange = (changedValues: any, allValues: any) => {
        if (allValues.sharePercentage) {
            totalPercentage(allValues.sharePercentage)
        }
    }

    const totalPercentage = (sharePercentage: any) => {
        const resultTotalPercentage = sharePercentage.reduce((acc: any, cur: any) => acc + (cur.percentage || 0), 0);
        setTextTotalPercentage(resultTotalPercentage)
    }

    const textPlsEnyrtNumberOnly = FuncFormattedMessage("setting.features.input.pls-enter-numbers-only", "กรุณากรอกเป็นตัวเลขเท่านั้น")
    const textPlsEnyrtNumber1_100Only = FuncFormattedMessage("setting.features.input.pls-enter-numbers-1-100", "กรุณากรอกตัวเลขตั้งแต่ 1 - 100 เท่านั้น")
    const textDateStartTxId = FuncFormattedMessage("setting.features.commission.input.dateStartTxId", "รายการฝากตั้งแต่")
    const textSelectDate = FuncFormattedMessage("setting.features.commission.input.select-date", "กรุณาเลือกวันที่")
    const textPlsSelectDate = FuncFormattedMessage("setting.features.commission.input.pls-select-date", "กรุณาเลือกวันที่!")
    const textRecentDeposits = FuncFormattedMessage("setting.features.commission.input.recent-deposits", "รายการฝากล่าสุด (TxID)")
    const textPlsRecentDeposits = FuncFormattedMessage("setting.features.commission.input.pls-recent-deposits", "กรุณากรอก รายการฝากล่าสุด (TxID)!")
    const textComfirmStartTxIdZero = FuncFormattedMessage("setting.features.commission.input.comfirmStartTxIdZero", "กดที่นี่หากรายการฝากล่าสุด (TxID) เป็น 0 หรือหากต้องการเริ่มคำนวณจากรายการฝากแรกสุดของเว็บ")
    const textStatus = FuncFormattedMessage("setting.features.commission.input.status", "สถานะการเปิดใช้งานระบบ")

    const textPortion = FuncFormattedMessage("setting.features.commission.input.portion", "เปอร์เซ็นต์ที่ปันผลจากยอดฝาก (%)")
    const textPlsPortion = FuncFormattedMessage("setting.features.commission.input.pls-portion", "กรุณากรอก เปอร์เซ็นต์ที่ปันผลจากยอดฝาก (%)!")
    const textMaxPortion = FuncFormattedMessage("setting.features.commission.input.maxPortion", "ยอดแบ่งปันผลสูงสุด (เครดิต)")
    const textPlsMaxPortion = FuncFormattedMessage("setting.features.commission.input.pls-maxPortion", "กรุณากรอก ยอดแบ่งปันผลสูงสุด (เครดิต)!")
    const textMinWithdraw = FuncFormattedMessage("setting.features.commission.input.minWithdraw", "ยอดสะสมคอมมิชชั่นขั้นต่ำ")
    const textPlsMinWithdraw = FuncFormattedMessage("setting.features.commission.input.pls-minWithdraw", "กรุณากรอก ยอดสะสมคอมมิชชั่นขั้นต่ำ!")
    const textExtraMinWithdraw = FuncFormattedMessage("setting.features.commission.input.extra-minWithdraw", "ยอดสะสมคอมมิชชั่นขั้นต่ำที่สมาชิกจะสามารถโยกเข้ากระเป๋าเงินหลัก")
    const textExtraCommission = FuncFormattedMessage("setting.features.commission.input.extra-commission", "ผลรวมต้องไม่เกิน 100%")
    const textPercentage = FuncFormattedMessage("setting.features.commission.input.percentage", "เปอร์เซ็นต์ (%)")
    const textPlsPercentage = FuncFormattedMessage("setting.features.commission.input.pls-percentage", "กรุณากรอกเปอร์เซ็นต์!")
    const textNo = FuncFormattedMessage("setting.features.commission.input.no", "ลำดับ")
    const textAdd = FuncFormattedMessage("setting.features.commission.input.add", "เพิ่มลำดับการปันผล")

    return (
        <Fragment>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between">
                <Typography.Title level={3}>
                    <FormattedMessage id="setting.features.set-commission" defaultMessage="ตั้งค่าคอมมิชชั่น" />
                </Typography.Title>
            </div>
            <Typography.Title level={5} className="mb-4 commission-title" >
                <FormattedMessage id="setting.features.commission.subtitle" defaultMessage="ปัจจุบันคำนวณยอดถึงรายการฝาก (TxID)" /> : {textDepositLastTxId}
            </Typography.Title>
            {/* <Divider /> */}
            <Row gutter={16}>
                <Col sm={24} lg={12} className="mb-3">
                    {/* <div className="box-white ant-box-card"> */}
                    <Divider orientation="left"><Typography.Title level={5}><FormattedMessage id="setting.features.commission.title1" defaultMessage="ตั้งค่าการเริ่มคำนวณคอมมิชชั่น" /></Typography.Title></Divider>
                    <Form
                        form={form}
                        name="CommissionStartTxId"
                        layout="vertical"
                        onValuesChange={onValuesChange}
                        hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="dateStartTxId"
                                    label={textDateStartTxId}
                                    rules={[
                                        { required: true, message: textPlsSelectDate },
                                    ]}
                                    hasFeedback
                                    extra={""}
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        placeholder={textSelectDate}
                                        disabledDate={disabledDate}
                                        // disabledTime={disabledDateTime}
                                        onChange={fetchStartTxId}
                                    // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Typography.Title level={5}>{textRecentDeposits} :  {depositLoading ? <Spin /> : textStartTxId}</Typography.Title>
                            </Col>
                            <Col span={24} style={{ display: "none" }}>
                                <Form.Item
                                    name="startTxId"
                                    label={textRecentDeposits}
                                    rules={[
                                        { required: true, message: textPlsRecentDeposits },
                                        { pattern: PatternNumber, message: textPlsEnyrtNumberOnly }
                                    ]}
                                    hasFeedback
                                    extra={""}
                                >
                                    {depositLoading ? <Spin /> : <InputNumber placeholder={textRecentDeposits} inputMode="numeric" controls={false} readOnly={true} />}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="comfirmStartTxIdZero"
                                    valuePropName="checked">
                                    <Checkbox>
                                        <span style={{ color: "#FF0000" }}>{textComfirmStartTxIdZero}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <div className="drawer-actions">
                            <Space>
                                <Button type="primary" htmlType="submit" disabled={btnLoading} loading={btnLoading} onClick={handleFormSubmit} size="large">
                                    <i className="fa-solid fa-save me-2"></i>
                                    <FormattedMessage id="setting.features.save" defaultMessage="บันทึก" />
                                </Button>
                            </Space>
                        </div>
                    </Form>
                    {/* </div> */}
                </Col>
                <Col sm={24} lg={12} className="mb-3">
                    {/* <div className="box-white ant-box-card"> */}
                    <Divider orientation="left"><Typography.Title level={5}><FormattedMessage id="setting.features.commission.title2" defaultMessage="ตั้งค่าคอมมิชชั่น" /></Typography.Title></Divider>
                    <Form
                        form={form2}
                        name="Commission"
                        layout="vertical"
                        onValuesChange={onValuesChange}
                        hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="status"
                                    label={textStatus}
                                    valuePropName="checked"
                                >
                                    <Switch
                                        loading={btnSwitchLoading}
                                        checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                        unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                        onChange={(e) => {
                                            updateStatusCommission(e)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="portion"
                                    label={textPortion}
                                    rules={[
                                        { required: true, message: textPlsPortion },
                                        { pattern: PatternNumber, message: textPlsEnyrtNumberOnly },
                                        { type: 'number', min: 1, max: 100, message: textPlsEnyrtNumber1_100Only }
                                    ]}
                                    hasFeedback
                                    extra={textPlsEnyrtNumber1_100Only}
                                >
                                    <InputNumber placeholder={textPortion} inputMode="numeric" controls={false} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="maxPortion"
                                    label={textMaxPortion}
                                    rules={[
                                        { required: true, message: textPlsMaxPortion },
                                        { pattern: PatternNumber, message: textPlsEnyrtNumberOnly }
                                    ]}
                                    hasFeedback
                                    extra={""}
                                >
                                    <InputNumber placeholder={textMaxPortion} inputMode="numeric" controls={false} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="minWithdraw"
                                    label={textMinWithdraw}
                                    rules={[
                                        { required: true, message: textPlsMinWithdraw },
                                        { pattern: PatternNumber, message: textPlsEnyrtNumberOnly }
                                    ]}
                                    hasFeedback
                                    extra={textExtraMinWithdraw}
                                >
                                    <InputNumber placeholder={textMinWithdraw} inputMode="numeric" controls={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Divider /> */}
                        <div className="checkbox-collapse mb-2">
                            <Divider orientation="left">
                                <FormattedMessage id="setting.features.commission.title3" defaultMessage="คอมมิชชั่น" />
                                {textTotalPercentage > 100 ? (
                                    <span style={{ color: "#FF0000", marginLeft: "10px" }}>{textTotalPercentage}% ({textExtraCommission})</span>
                                ) : (
                                    <span style={{ marginLeft: "10px" }}>{textTotalPercentage}%</span>
                                )}
                            </Divider>
                            <Form.List name="sharePercentage" key="sharePercentage">
                                {(steps, { add, remove }) => {
                                    return (
                                        <>
                                            {steps.map((field, index) => (
                                                <Space key={`${index}-main`} size="middle" className="mt-2" style={{ display: 'flex' }}>
                                                    <Space size={12} align="center" style={{ marginBottom: 12 }}>
                                                        <Form.Item
                                                            name={[index, "percentage"]}
                                                            key={`${index}-percentage`}
                                                            label={""}
                                                            style={{ marginBottom: "0" }}
                                                            rules={[
                                                                { required: true, message: textPlsPercentage },
                                                                { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                                            ]}
                                                            hasFeedback>
                                                            <InputNumber className="input-affiliate-percentage" addonBefore={`${textNo} ${index + 1}`} addonAfter="%" placeholder={textPercentage} inputMode="numeric" controls={false} />
                                                        </Form.Item>
                                                        {steps.length > 1 && index !== 0 && (
                                                            <MinusCircleOutlined
                                                                key={`${index}-del`}
                                                                style={{ fontSize: 20, color: "#dc3545" }}
                                                                onClick={() => {
                                                                    // isDeleted(field.key)
                                                                    remove(field.name)
                                                                }} />
                                                        )}
                                                    </Space>
                                                </Space>
                                            ))}
                                            <Form.Item className="mb-0">
                                                <Button
                                                    key="add-list"
                                                    type="dashed"
                                                    onClick={() => {
                                                        // isAdd()
                                                        add({ percentage: 0 })
                                                    }}
                                                    style={{ width: "100%", marginTop: "12px" }}
                                                >
                                                    <PlusOutlined /> {textAdd}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    );
                                }}
                            </Form.List>
                            {/* <Divider orientation="left"><span style={{ color: "#FF0000" }}>เปอร์เซ็นรวมของคอมมิชชั่นต้องไม่เกิน 100%</span></Divider> */}
                        </div>
                        <Divider />
                        <div className="drawer-actions">
                            <Space>
                                <Button type="primary" htmlType="submit" disabled={btnLoading2} loading={btnLoading2} onClick={handleFormSubmit2} size="large">
                                    <i className="fa-solid fa-save me-2"></i>
                                    <FormattedMessage id="setting.features.save" defaultMessage="บันทึก" />
                                </Button>
                            </Space>
                        </div>
                    </Form>
                    {/* </div> */}
                </Col>
            </Row>
        </Fragment>
    )
}

export default Commission
