import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Checkbox, Row, Col, Divider, Space, Button } from 'antd'
import { Navigate } from 'react-router-dom'
import TitleBar from '../../../components/Elements/TitleBar'
import { ambGamesConfigEdit } from '../../../@types/prefix'
import useFetchSetting from "../../../hooks/useFetchSetting"
import PrefixService from "../../../services/prefix"
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../../components/Elements/FormattedMessage'

const AmbGames = () => {

    const [form] = Form.useForm();
    const { data, mutate } = useFetchSetting()

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: ambGamesConfigEdit) => {
        try {
            const res = await PrefixService.updateAmbGames(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                values.games = values.ambbetGames
                delete values.ambbetGames
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }

    useEffect(() => {
        if (form && data && data?.setting.ambbetGames !== null) {
            form.resetFields();
            form.setFieldsValue(data?.setting)
        }
    }, [form, data])// eslint-disable-line


    if (data?.setting.ambbetGames === null) {
        const textNoPermissions = FuncFormattedMessage("setting.amb-game.no-permissions", "คุณไม่มีสิทธิ์เข้าถึง (ไม่พบเกม Ambbet)")
        toast.warning(textNoPermissions)
        return <Navigate to="/setting-website" replace />
    }

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.amb-game.setting-amb-game" defaultMessage="ตั้งค่าเกม Ambbet" />} />
            {data?.setting.ambbetGames &&
                <div className="box-white ant-box-card">
                    <Form
                        form={form}
                        name="ambGamesForm"
                        layout="vertical"
                        hideRequiredMark
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <h6 className="my-3"><FormattedMessage id="setting.amb-game.open-game" defaultMessage="เปิดใช้งานเกม" /></h6>
                                <Form.List name="ambbetGames" key="ambbetGames">
                                    {(ambbetGames) => {
                                        return (
                                            <>
                                                {ambbetGames.map((field, index) => (
                                                    <Space key={`${index}-main`} size="middle" style={{ display: 'flex' }}>
                                                        <Form.Item
                                                            name={[index, "enable"]}
                                                            key={`${index}-enable`}
                                                            valuePropName="checked">
                                                            <Checkbox>
                                                                {data?.setting?.ambbetGames[index].label}
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Space>
                                                ))}
                                            </>
                                        );
                                    }}
                                </Form.List>
                            </Col>
                        </Row>
                        <Divider />
                        <div className="drawer-actions">
                            <Space>
                                <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.amb-game.save" defaultMessage="บันทึก" /> </Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            }
        </Fragment>
    )
}

export default AmbGames