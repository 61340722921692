import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface FormattedMessageProps {
    id: string
    defaultMessage?: string
}
const FormattedMessage: FC<FormattedMessageProps> = ({ id, defaultMessage }) => {
    const { t, i18n } = useTranslation()
    return (i18n.exists(id) ? t(id) : (<>{defaultMessage}</>))
}

export default FormattedMessage