import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Divider,
    Space,
    InputNumber,
    // Switch
} from 'antd'
import { toast } from 'react-toastify'
import PatternNumber from '../../utils/PatternNumber'
import { bankNames } from '../../utils/bank'
import PrefixService from '../../services/prefix'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'
import FormattedMessage from '../Elements/FormattedMessage'

interface EditMemberProps {
    close: () => void
    isOpen: boolean
    onloadData: () => void

    member: any

}

const EditMember: FC<EditMemberProps> = ({ close, isOpen, onloadData, member }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("member.edit.title", "แก้ไข / ตั้งค่าสมาชิก")
    const { Option } = Select
    const [btnLoading, setBtnLoading] = useState(false)
    const [loadingBankAccount, setLoadingBankAccount] = useState(false)

    const getDataBank = async (id: number) => {
        try {
            setLoadingBankAccount(true)
            const responseMemberBankAccount = await PrefixService.getMemberBankAccount(id).then()
            form.setFieldsValue({ 'firstName': responseMemberBankAccount?.firstName, 'lastName': responseMemberBankAccount?.lastName })
            setLoadingBankAccount(false)
        } catch ({ message }) {
            toast.error(message as string)
            setLoadingBankAccount(false)
        }
    }

    const optionBankNames = Object.keys(bankNames).map((key: any) => {
        return <Option key={key} value={key}><FormattedMessage id={`bank_name.${key}`} defaultMessage={bankNames[key]} /></Option>
    })

    const updateData = async (values: any) => {
        try {
            const res = await PrefixService.updateMemberInfo(values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = (member: any) => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                member.phone = values.phone
                member.twID = values.twID
                member.firstName = values.firstName
                member.lastName = values.lastName
                member.firstNameEN = values.firstNameEN
                member.lastNameEN = values.lastNameEN
                member.bankCode = values.bankCode
                member.bankNumber = values.bankNumber
                member.affiliate.balance = values.affiliate.balance
                member.totalTurn = values.totalTurn
                member.minWithdraw = values.minWithdraw
                member.maxWithdraw = values.maxWithdraw
                // member.status = values.status
                // member.remark = values.remark
                if (values.gameUsername) {
                    member.gameUsername = values.gameUsername
                }
                if (values.password) {
                    member.password = values.password
                }
                if (values.gamePassword) {
                    member.gamePassword = values.gamePassword
                }
                updateData(member)

            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            form.setFieldsValue(member);
        }
    }, [form, isOpen, member]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={() => handleFormSubmit(member)}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="editMember"
                    layout="vertical"
                    hideRequiredMark>
                    <Row>
                        <Col span={24}>
                            <h6 className="mb-3"><FormattedMessage id="member.title-view" defaultMessage="ข้อมูลสมาชิก" /></h6>
                            {/* <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="สถานะ"
                                        valuePropName="checked">
                                        <Switch
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="remark"
                                        label="หมายเหตุ"
                                        rules={[
                                            { required: false, message: 'กรุณากรอกหมายเหตุ' }
                                        ]}>
                                        <Input placeholder="หมายเหตุ" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="phone"
                                        label={<FormattedMessage id="tel" defaultMessage="เบอร์โทรศัพท์" />}
                                        rules={[
                                            { required: false, message: <FormattedMessage id="please-enter-your-phone-number" defaultMessage="กรุณากรอกเบอร์โทรศัพท์" /> },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}>
                                        <Input placeholder={FuncFormattedMessage("tel", "เบอร์โทรศัพท์")} inputMode="tel" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="twID"
                                        label="Truewallet ID"
                                        rules={[{ required: false, message: <FormattedMessage id="please-enter-truewallet-id" defaultMessage="กรุณากรอก Truewallet ID" /> }]}>
                                        <Input placeholder="Truewallet ID" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="firstName"
                                        label={<FormattedMessage id="firstname" defaultMessage="ชื่อ" />}
                                        rules={[{ required: true, message: <FormattedMessage id="message-firstname" defaultMessage="กรุณากรอกชื่อ" /> }]}
                                        hasFeedback>
                                        <Input placeholder={FuncFormattedMessage("firstname", "ชื่อ")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="lastName"
                                        label={<FormattedMessage id="lastname" defaultMessage="นามสกุล" />}
                                        rules={[{ required: true, message: <FormattedMessage id="message-lastname" defaultMessage="กรุณากรอกนามสกุล" /> }]}
                                        hasFeedback>
                                        <Input placeholder={FuncFormattedMessage("lastname", "นามสกุล")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="firstNameEN"
                                        label={<FormattedMessage id="firstname-en" defaultMessage="ชื่อ (ภาษาอังกฤษ)" />}
                                        rules={[{ required: false, message: <FormattedMessage id="message-firstname-en" defaultMessage="กรุณากรอกชื่อ (ภาษาอังกฤษ)" /> }]}>
                                        <Input placeholder={FuncFormattedMessage("firstname-en", "ชื่อ (ภาษาอังกฤษ)")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="lastNameEN"
                                        label={<FormattedMessage id="lastname-en" defaultMessage="นามสกุล (ภาษาอังกฤษ)" />}
                                        rules={[{ required: false, message: <FormattedMessage id="message-lastname-en" defaultMessage="กรุณากรอกนามสกุล (ภาษาอังกฤษ)" /> }]}>
                                        <Input placeholder={FuncFormattedMessage("lastname-en", "นามสกุล (ภาษาอังกฤษ)")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Button
                                    type="primary"
                                    loading={loadingBankAccount}
                                    size="large"
                                    className="mb-4"
                                    onClick={() => getDataBank(member?.id)}>
                                    <FormattedMessage id="btn-fetch-data-bank" defaultMessage="ดึงข้อมูลจากบัญชีธนาคาร" />
                                </Button>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3"><FormattedMessage id="set-game-username" defaultMessage="ตั้งค่า Game Username" /></h6>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="gameUsername"
                                        label="Game Username"
                                        rules={[{ required: false, message: <FormattedMessage id="message-game-username" defaultMessage="กรุณากรอก Game Username" /> }]}>
                                        <Input placeholder="Game Username" inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3"><FormattedMessage id="set-password" defaultMessage="ตั้งค่ารหัสผ่าน" /></h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="password"
                                        label={<FormattedMessage id="password" defaultMessage="รหัสผ่าน" />}
                                        rules={[{ required: false, message: <FormattedMessage id="message-password" defaultMessage="กรุณากรอกรหัสผ่าน" /> }]}>
                                        <Input placeholder={FuncFormattedMessage("password", "รหัสผ่าน")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="gamePassword"
                                        label={<FormattedMessage id="password-new-user" defaultMessage="รหัสผ่านสำหรับเปิดยูซเซอร์ใหม่" />}
                                        rules={[{ required: false, message: <FormattedMessage id="message-password-new-user" defaultMessage="กรุณากรอกรหัสผ่านสำหรับเปิดยูซเซอร์ใหม่" /> }]}>
                                        <Input placeholder={FuncFormattedMessage("password-new-user", "รหัสผ่านสำหรับเปิดยูซเซอร์ใหม่")} inputMode="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                        </Col>
                        <Col span={24}>
                            <h6 className="mb-3"><FormattedMessage id="bank-information" defaultMessage="ข้อมูลธนาคาร" /></h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="bankCode"
                                        label={<FormattedMessage id="bank" defaultMessage="ธนาคาร" />}
                                        rules={[{ required: true, message: <FormattedMessage id="message-select-bank" defaultMessage="กรุณาเลืิอกธนาคาร" /> }]}
                                        hasFeedback
                                    >
                                        <Select
                                            placeholder={FuncFormattedMessage("select-bank", "เลืิอกธนาคาร")}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {optionBankNames}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="bankNumber"
                                        label={<FormattedMessage id="bank-account-number" defaultMessage="เลขที่บัญชี" />}
                                        rules={[{ required: true, message: <FormattedMessage id="message-bank-account-number" defaultMessage="กรุณากรอกเลขที่บัญชี" /> }]}
                                        hasFeedback
                                    >
                                        <Input placeholder={FuncFormattedMessage("bank-account-number", "เลขที่บัญชี")} inputMode="numeric" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider className="mt-2 mb-4" />
                            <h6 className="mb-3"><FormattedMessage id="setting-turn" defaultMessage="ตั้งค่าเทิร์น" /></h6>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name={['affiliate', 'balance']}
                                        label={<FormattedMessage id="friend-invite" defaultMessage="ยอดเชิญเพื่อน" />}
                                        rules={[
                                            { required: true, message: <FormattedMessage id="message-friend-invite" defaultMessage="กรุณากรอกยอดเชิญเพื่อน" /> },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder={FuncFormattedMessage("friend-invite", "ยอดเชิญเพื่อน")} inputMode="numeric" readOnly={true} controls={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="totalTurn"
                                        label={<FormattedMessage id="turn-to-do" defaultMessage="เทิร์นที่ต้องทำ" />}
                                        rules={[
                                            { required: true, message: <FormattedMessage id="message-turn-to-do" defaultMessage="กรุณากรอกเทิร์นที่ต้องทำ" /> },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder={FuncFormattedMessage("turn-to-do", "เทิร์นที่ต้องทำ")} inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="minWithdraw"
                                        label={<FormattedMessage id="minimum-withdrawal" defaultMessage="ยอดถอนขั้นต่ำ" />}
                                        rules={[
                                            { required: true, message: <FormattedMessage id="message-minimum-withdrawal" defaultMessage="กรุณากรอกยอดถอนขั้นต่ำ" /> },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder={FuncFormattedMessage("minimum-withdrawal", "ยอดถอนขั้นต่ำ")} inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="maxWithdraw"
                                        label={<FormattedMessage id="maximum-withdrawal" defaultMessage="ยอดถอนสูงสุด" />}
                                        rules={[
                                            { required: true, message: <FormattedMessage id="message-maximum-withdrawal" defaultMessage="กรุณากรอกยอดถอนสูงสุด" /> },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}
                                        hasFeedback
                                    >
                                        <InputNumber placeholder={FuncFormattedMessage("maximum-withdrawal", "ยอดถอนสูงสุด")} inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={() => handleFormSubmit(member)} size="large">
                                <i className="fa-solid fa-save me-2"></i><FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                            <Button onClick={close} size="large"><FormattedMessage id="cancel" defaultMessage="ยกเลิก" /></Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditMember

