import React, { FC } from 'react'
import { getBankIcon, getBankName } from '../../utils/bank'

interface AvatarBankProps {
    bankCode: string
    bankNumber: string
    text?: string
}

const AvatarBankComponent: FC<AvatarBankProps> = ({ bankCode, bankNumber, text }) => {
    return (
        <div className="row-bank">
            {getBankIcon(bankCode) && (
                <img src={getBankIcon(bankCode)} alt={bankCode} />
            )}

            <div className="row-bank-info">
                <div>{getBankName(bankCode)} {text && <span>({text})</span>}</div>
                <div><span>{bankNumber}</span></div>
            </div>
        </div>
    )
}

export default AvatarBankComponent
