import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    Col,
    Row,
    Checkbox,
    InputNumber
} from 'antd'
import PatternNumber from '../../../utils/PatternNumber'
import FormattedMessage from '../../Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

interface ConsecutiveDepositBonusProps {
    data: any
}

const ConsecutiveDepositBonus: FC<ConsecutiveDepositBonusProps> = ({ data }) => {
    const [consecutiveDepositBonusChecked, setConsecutiveDepositBonusChecked] = useState<boolean>(data?.consecutiveDepositBonus?.enable)

    useEffect(() => {
        setConsecutiveDepositBonusChecked(data?.consecutiveDepositBonus?.enable)
    }, [data]) // eslint-disable-line

    const text_consecutive_deposit_days = FuncFormattedMessage("bonus.input.consecutive_deposit_days", "ฝากติดต่อกัน (วัน)")
    const text_minimum_deposit_per_day = FuncFormattedMessage("bonus.input.minimum_deposit_per_day", "ยอดฝากขั้นต่ำต่อวัน")

    return (
        <div className="checkbox-collapse mb-2">
            <Form.Item
                name={['consecutiveDepositBonus', 'enable']}
                valuePropName="checked"
                className="mb-0"
            >
                <Checkbox onClick={(e: any) => setConsecutiveDepositBonusChecked(e.target.checked)}>
                    <FormattedMessage id="bonus.input.consecutive_deposit_bonus" defaultMessage="โบนัสฝากติดต่อกัน" />
                </Checkbox>
            </Form.Item>
            {consecutiveDepositBonusChecked &&
                <>
                    <Row gutter={16} className="mt-2">
                        <Col span={12}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'consecutiveDepositDays']}
                                key="consecutiveDepositDays"
                                label={text_consecutive_deposit_days}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_consecutive_deposit_days" defaultMessage="กรุณากรอกฝากติดต่อกัน (วัน)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_consecutive_deposit_days} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'minimumDepositPerDay']}
                                key="minimumDepositPerDay"
                                label={text_minimum_deposit_per_day}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_minimum_deposit_per_day" defaultMessage="กรุณากรอกยอดฝากขั้นต่ำต่อวัน" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_minimum_deposit_per_day} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noWithdraw']}
                                key="noWithdraw"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noWithdraw" defaultMessage="ไม่มีรายการถอน" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noBonus']}
                                key="noBonus"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noBonus" defaultMessage="ไม่มีรายการรับโบนัส" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noCashback']}
                                key="noCashback"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noCashback" defaultMessage="ไม่มีรายการรับแคชแบ็ก" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['consecutiveDepositBonus', 'rules', 'noLuckyWheel']}
                                key="noLuckyWheel"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noLuckyWheel" defaultMessage="ไม่มีรายการหมุนกงล้อเสี่ยงโชค" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}
export default ConsecutiveDepositBonus

