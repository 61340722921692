import useSWR from 'swr'
import PrefixService from '../services/prefix'

export const useFetchCommission = () => {
    const prefix = PrefixService.getPrefix()
    const { data, mutate, error } = useSWR([`/setting/website/commission`, prefix], () => PrefixService.getCommission());

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchCommission
