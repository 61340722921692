import { Divider, Drawer, Space, Typography } from 'antd'
import Button from 'antd-button-color'
import React, { FC, useEffect, useState } from 'react'
import NumberWithCommas from '../../utils/NumberWithCommas'
import FormattedMessage from '../Elements/FormattedMessage'
import PrefixService from '../../services/prefix'
import { toast } from 'react-toastify'
import Loading from '../Elements/Loading'

interface ViewProps {
    memberID: number
    close: () => void
    isOpen: boolean
    title: string
}

const MemberInfo: FC<ViewProps> = ({ close, isOpen, memberID, title }) => {
    const [memberInfo, setMemberInfo] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)

    const getMemberInfo = async (memberID: number) => {
        try {
            const res = await PrefixService.getMemberInfo(memberID, true)
            if (res) {
                setMemberInfo(res)
                setLoading(false)
            }
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    useEffect(() => {
        if (memberID !== 0) {
            setLoading(true)
            getMemberInfo(memberID)
        }
        return () => {
            setMemberInfo(null)
        }
    }, [memberID])


    return (
        <Drawer
            title={title}
            visible={isOpen}
            onClose={close}
            footer={[
                <Button key="submit" type="primary" onClick={close}>
                    <FormattedMessage id="close" defaultMessage="ปิด" />
                </Button>,
            ]}>
            {loading && <Loading />}
            {memberInfo && (
                <>
                    <Space direction="vertical" size={0}>
                        <Typography.Text type="secondary"><FormattedMessage id="username" defaultMessage="ชื่อผู้ใช้งาน" /></Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.username}</Typography.Title>
                    </Space>
                    <Divider className="my-2" />
                    <Space direction="vertical" size={0}>
                        <Typography.Text type="secondary"><FormattedMessage id="password" defaultMessage="รหัสผ่าน" /></Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>{memberInfo?.member?.game?.password}</Typography.Title>
                    </Space>
                    <Divider className="my-2" />
                    <Space direction="vertical" size={0}>
                        <Typography.Text type="secondary"><FormattedMessage id="credit" defaultMessage="เครดิตคงเหลือ" /></Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.credit, 2)}</Typography.Title>
                    </Space>
                    <Divider className="my-2" />
                    <Space direction="vertical" size={0}>
                        <Typography.Text type="secondary"><FormattedMessage id="turnover" defaultMessage="เทิร์นโอเวอร์" /></Typography.Text>
                        <Typography.Title level={5} style={{ margin: 0 }}>฿ {NumberWithCommas(memberInfo?.currentTurnover, 2)}</Typography.Title>
                    </Space>
                </>
            )}
        </Drawer>
    )
}

export default MemberInfo
