import Bronze from '../assets/img/ranking/rank-1.webp'
import Silver from '../assets/img/ranking/rank-2.webp'
import Gold from '../assets/img/ranking/rank-3.webp'
import Platinum from '../assets/img/ranking/rank-4.webp'
import Diamond from '../assets/img/ranking/rank-5.webp'
import Supreme from '../assets/img/ranking/rank-6.webp'

export const getRankingImage = (rank: string) => {
    rank = rank.toUpperCase()
    switch (rank) {
        case 'SILVER': {
            return Silver
        }
        case 'GOLD': {
            return Gold
        }
        case 'PLATINUM': {
            return Platinum
        }
        case 'DIAMOND': {
            return Diamond
        }
        case 'SUPREME': {
            return Supreme
        }
        default: {
            return Bronze
        }
    }
}

