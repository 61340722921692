import { IRoute } from '../@types/IRoute'
import Main from '../views/Main'
import Members from '../views/Members'
import TransactionDeposit from '../views/Transactions/TransactionDeposit'
import TransactionWithdraw from '../views/Transactions/TransactionWithdraw'
import TransactionBank from '../views/Transactions/TransactionBank'
import PromotionBonus from '../views/Promotions/Bonus'
import PromotionCredit from '../views/Promotions/Credit'
import PromotionWheelLuck from '../views/Promotions/WheelLuck'
import PromotionCode from '../views/Promotions/Code'
import PromotionCashback from '../views/Promotions/Cashback'
import PromotionAffiliate from '../views/Promotions/Affiliate'
import FixTx from '../views/FixTx'
import Bank from '../views/Banks/Bank'
import BankTrueWallet from '../views/Banks/TrueWallet'
import BankAutoWithdraw from '../views/Banks/AutoWithdraw'
import BankWithdrawCentral from '../views/Banks/WithdrawCentral'
import ReportSummary from '../views/Reports/Summary'
import ReportBonus from '../views/Reports/Bonus'
import ReportCashback from '../views/Reports/Cashback'
import ReportWheelLuck from '../views/Reports/WheelLuck'
import ReportAffiliate from '../views/Reports/Affiliate'
import ReportPromotionCode from '../views/Reports/PromotionCode'
import ReportTransactionIndividual from '../views/Reports/TransactionIndividual'
import ReportChart from '../views/Reports/ReportChart'
import Users from '../views/Users'
import SettingPrefix from '../views/Setting/Prefix'
import SettingFeatures from '../views/Setting/Features'
import SettingAmbPayment from '../views/Setting/AmbPayment'
import SettingAmbGames from '../views/Setting/AmbGames'
import SettingAMBKingGames from '../views/Setting/AMBKingGames'
import SettingAMBKingSportBonus from '../views/Setting/AMBKingSportBonus'
import SettingTheme from '../views/Setting/Theme'
import SettingRecaptcha from '../views/Setting/Recaptcha'
import SettingPopup from '../views/Setting/Popup'
import LogsReport from '../views/LogsReport'
// import Dashboard from '../views/Dashboard'


export const menuItems: Array<IRoute> = [
        {
                key: "main",
                title: "หน้าหลัก",
                path: "/main",
                content: Main,
                hide: false,
                permission_key: 'manage.all',
                features_key: '',
        },
        {
                key: "members",
                title: "สมาชิก",
                path: "/members",
                content: Members,
                hide: false,
                permission_key: 'manage.members',
                features_key: '',
        },
        {
                key: "transaction-deposit",
                title: "รายการฝาก",
                path: "/transaction-deposit",
                content: TransactionDeposit,
                hide: false,
                permission_key: 'manage.deposit',
                features_key: '',
        },
        {
                key: "transaction-withdraw",
                title: "รายการถอน",
                path: "/transaction-withdraw",
                content: TransactionWithdraw,
                hide: false,
                permission_key: 'manage.withdraw',
                features_key: '',
        },
        {
                key: "transaction-bank",
                title: "รายการเงินเข้า",
                path: "/transaction-bank",
                content: TransactionBank,
                hide: false,
                permission_key: 'manage.deposit',
                features_key: '',
        },
        {
                key: "promotion-bonus",
                title: "โบนัส",
                path: "/promotion-bonus",
                content: PromotionBonus,
                hide: false,
                permission_key: 'manage.bonus',
                features_key: '',
        },
        {
                key: "promotion-credit",
                title: "แจกเครดิต",
                path: "/promotion-credit",
                content: PromotionCredit,
                hide: false,
                permission_key: 'manage.bonus',
                features_key: '',
        },
        {
                key: "promotion-wheel-luck",
                title: "กงล้อเสี่ยงโชค",
                path: "/promotion-wheel-luck",
                content: PromotionWheelLuck,
                hide: false,
                permission_key: 'manage.wheel',
                features_key: '',
        },
        {
                key: "promotion-code",
                title: "โค้ดโปรโมชั่น",
                path: "/promotion-code",
                content: PromotionCode,
                hide: false,
                permission_key: 'manage.redeem',
                features_key: '',
        },
        {
                key: "promotion-cashback",
                title: "คืนยอดเสีย",
                path: "/promotion-cashback",
                content: PromotionCashback,
                hide: false,
                permission_key: 'manage.bonus',
                features_key: '',
        },
        {
                key: "promotion-affiliate",
                title: "แนะนำเพื่อน",
                path: "/promotion-affiliate",
                content: PromotionAffiliate,
                hide: false,
                permission_key: 'manage.bonus',
                features_key: '',
        },
        {
                key: "fix-tx",
                title: "แก้ไขรายการผิดพลาด",
                path: "/fix-tx",
                content: FixTx,
                hide: false,
                permission_key: 'manage.fixTx',
                features_key: '',
        },
        {
                key: "bank",
                title: "บัญชีธนาคาร",
                path: "/bank",
                content: Bank,
                hide: false,
                permission_key: 'manage.bank',
                features_key: '',
        },
        {
                key: "bank-auto-withdraw",
                title: "ตั้งค่าถอนเงินอัตโนมัติ",
                path: "/bank-auto-withdraw",
                content: BankAutoWithdraw,
                hide: false,
                permission_key: 'manage.bank',
                features_key: '',
        },
        {
                key: "bank-withdraw-central",
                title: "ตั้งค่าถอนเข้าบัญชีกลาง",
                path: "/bank-withdraw-central",
                content: BankWithdrawCentral,
                hide: false,
                permission_key: 'manage.bank',
                features_key: '',
        },
        {
                key: "report-summary",
                title: "สรุปข้อมูล",
                path: "/report-summary",
                content: ReportSummary,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-bonus",
                title: "โบนัส",
                path: "/report-bonus",
                content: ReportBonus,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-cashback",
                title: "แคชแบ็ก",
                path: "/report-cashback",
                content: ReportCashback,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-wheel-luck",
                title: "กงล้อเสี่ยงโชค",
                path: "/report-wheel-luck",
                content: ReportWheelLuck,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-affiliate",
                title: "แนะนำเพื่อน",
                path: "/report-affiliate",
                content: ReportAffiliate,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-promotion-code",
                title: "โค้ดโปรโมชั่น",
                path: "/report-promotion-code",
                content: ReportPromotionCode,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "report-transaction-individual",
                title: "ฝากถอนรายบุคคล",
                path: "/report-transaction-individual",
                content: ReportTransactionIndividual,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
        {
                key: "users",
                title: "ผู้ใช้งาน",
                path: "/users",
                content: Users,
                hide: false,
                permission_key: 'manage.users',
                features_key: '',
        },
        {
                key: "setting-website",
                title: "ตั้งค่า Prefix",
                path: "/setting-website",
                content: SettingPrefix,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-amb-payment",
                title: "ตั้งค่า AMB Payment",
                path: "/setting-amb-payment",
                content: SettingAmbPayment,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-amb-games",
                title: "ตั้งค่าเกม Ambbet",
                path: "/setting-amb-games",
                content: SettingAmbGames,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-features",
                title: "ตั้งค่าฟีเจอร์เว็บไซต์",
                path: "/setting-features",
                content: SettingFeatures,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-theme",
                title: "ตั้งค่าธีม",
                path: "/setting-theme",
                content: SettingTheme,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-recaptcha",
                title: "ตั้งค่า Recaptcha",
                path: "/setting-recaptcha",
                content: SettingRecaptcha,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-ambking-games",
                title: "ตั้งค่า AMBKing Games",
                path: "/setting-ambking-games",
                content: SettingAMBKingGames,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-ambking-sport-bonus",
                title: "ตั้งค่า AMBKing Sport Bonus",
                path: "/setting-ambking-sport-bonus",
                content: SettingAMBKingSportBonus,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        {
                key: "setting-popup",
                title: "ตั้งค่าป๊อปอัพ",
                path: "/setting-popup",
                content: SettingPopup,
                hide: false,
                permission_key: 'manage.website',
                features_key: '',
        },
        // {
        //         key: "dashboard",
        //         title: "ภาพรวม",
        //         path: "/dashboard",
        //         content: Dashboard,
        //         hide: false,
        //         permission_key: 'manage.all',
        // features_key: '',
        // },
        {
                key: "logs",
                title: "ประวัติการใช้งาน",
                path: "/logs",
                content: LogsReport,
                hide: false,
                permission_key: 'manage.all',
                features_key: '',
        },
        {
                key: "bank-true-wallet",
                title: "ทรูมันนี่วอลเล็ท",
                path: "/bank-true-wallet",
                content: BankTrueWallet,
                hide: false,
                permission_key: 'manage.bank',
                features_key: '',
        },
        {
                key: "report-chart",
                title: "กราฟข้อมูล",
                path: "/report-chart",
                content: ReportChart,
                hide: false,
                permission_key: 'manage.summary',
                features_key: '',
        },
]
