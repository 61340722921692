import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { requestAuthInitActions } from './auth.actions'

export interface State {
	loading: boolean
	error: string

	init: boolean
	permissions: Record<string, boolean>
}

export const defaultState: State = {
	loading: false,
	error: '',

	init: false,
	permissions: {},
}

export default reducerWithInitialState(defaultState)
	.case(requestAuthInitActions.started, (state, payload) => ({
		...state,
		loading: payload,
		error: '',
	}))
	.case(requestAuthInitActions.done, (state, payload) => ({
		...state,
		loading: false,

		init: true,
		permissions: payload.result.permissions,
	}))
	.case(requestAuthInitActions.failed, (state, payload) => ({
		...state,
		loading: false,
		error: payload.error.message,

		init: true,
	}))
	.default((state) => state)
