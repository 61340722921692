import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Space,
    Divider,
    Upload,
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
// import {
//     PromotionConfig,
// } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'
// import { useSelector } from 'react-redux'
// import { ApplicationState } from '../../../store'

interface EditPopupProps {
    close: () => void
    isOpen: boolean

    data: any
    popupKey: string
}

const EditPopup: FC<EditPopupProps> = ({ close, isOpen, data, popupKey }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("popup.edit", "แก้ไขป๊อปอัพ")
    // const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState<string>("")
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()


    // const updateData = async (values: PromotionConfig) => {
    //     try {
    //         const res = await PrefixService.savePromotionConfig(popupKey, values)
    //         setBtnLoading(false)
    //         dispatch(requestAuthInit(false))
    //         toast.success(res.message)
    //         close()
    //     } catch ({ message }) {
    //         toast.error(message as string)
    //         setBtnLoading(false)
    //     }
    // }

    // const handleFormSubmit = () => {
    //     form.validateFields()
    //         .then((values: PromotionConfig) => {
    //             setBtnLoading(true)
    //             updateData(values)
    //         })
    //         .catch((errorInfo) => { });
    // };


    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}><FormattedMessage id="popup.upload-image" defaultMessage="อัพโหลดรูปภาพ" /></div>
        </div>
    );

    const uploadImage = async (response: any, setting: string) => {
        setLoadingImage(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', setting);

        try {
            const res = await PrefixService.uploadPopupImage(popupKey ? popupKey : '', formData)
            toast.success(res.message)
            setLoadingImage(false)
            getBase64(response.file, (imageUrl: any) => setImageUrl(imageUrl));
            dispatch(requestAuthInit(false))
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setLoadingImage(false)
        }
    }

    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            setImageUrl(data?.image)
        }
    }, [form, isOpen, data]);

    const textUploadPic = FuncFormattedMessage("popup.image", "รูปภาพ")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            // extra={
            //     <Space>
            //         <Button
            //             onClick={handleFormSubmit}
            //             type="primary"
            //             loading={btnLoading}
            //             size="large">
            //             <i className="fa-solid fa-save me-2"></i>
            //             <FormattedMessage id="save" defaultMessage="บันทึก" />
            //         </Button>
            //     </Space>
            // }
            >
                <Form
                    form={form}
                    name="editPopup"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{ marginBottom: '16px' }}>{textUploadPic}</label>
                            <div className="upload-image" style={{ overflow: "hidden" }}>
                                <Upload
                                    name="popup-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={(e) => uploadImage(e, 'memberPopup')}
                                >
                                    {imageUrl ?
                                        loadingImage ? <LoadingOutlined /> : <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                                        : uploadButton}
                                </Upload>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            {/* <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" /></Button> */}
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditPopup

