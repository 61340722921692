import React, { Fragment, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import useFetchSetting from "../../../hooks/useFetchSetting"
import Ranking from './Option/Ranking'
import Point from './Option/Point'
import Commission from './Option/Commission'
import EmptyComponents from '../../../components/Elements/Empty'
import Loading from '../../../components/Elements/Loading'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import { ApplicationState } from '../../../store'
import { useSelector } from 'react-redux'

const Features = () => {

    const { features } = useSelector((state: ApplicationState) => state.prefix)
    const { data, isLoading } = useFetchSetting()
    const [activeKey, setActiveKey] = useState<string>("")
    const [showTab, setShowTab] = useState<boolean>(true)
    const [isUpdate, setIsUpdate] = useState<boolean>(false)

    const handelKey = (key: string) => {
        setActiveKey(key)
    }

    useEffect(() => {
        if (data) {
            if (data.setting.functions.rank) {
                handelKey("1")
            } else {
                if (data.setting.functions.point) {
                    handelKey("2")
                } else {
                    if (features.commission) {
                        handelKey("3")
                    } else {
                        setShowTab(false)
                    }
                }
            }
            // handelKey(activeKey)
        }
    }, [data, features])

    useEffect(() => {
        if (isUpdate) {
            handelKey(activeKey)
        }
    }, [activeKey, isUpdate])

    const textNoDataSetting = FuncFormattedMessage("setting.features.no-data-setting", "ไม่พบข้อมูล กรุณาตั้งค่าเว็บไซต์ก่อนใช้งานเมนูนี้")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.features.setting-features" defaultMessage="ตั้งค่าฟีเจอร์เว็บไซต์" />} />
            <div className="box-white ant-box-card">
                {isLoading ? <Loading /> :
                    showTab ? (
                        <Tabs tabPosition={"top"} activeKey={activeKey} onChange={handelKey}>
                            {/* global.window.innerWidth <= 1200 ? "top" : "left" */}
                            {data?.setting.functions.rank && (
                                <Tabs.TabPane tab={<FormattedMessage id="setting.features.setting-rank" defaultMessage="ตั้งค่าแรงค์" />} key="1" disabled={!data?.setting.functions.rank}>
                                    <Ranking setActiveKey={setActiveKey} setIsUpdate={setIsUpdate} />
                                </Tabs.TabPane>
                            )}
                            {data?.setting.functions.point && (
                                <Tabs.TabPane tab={<FormattedMessage id="setting.features.set-points-redeem" defaultMessage="ตั้งค่าสะสมแต้มแลกของรางวัล" />} key="2" disabled={!data?.setting.functions.point}>
                                    <Point setActiveKey={setActiveKey} setIsUpdate={setIsUpdate} />
                                </Tabs.TabPane>
                            )}
                            {features.commission && (
                                <Tabs.TabPane tab={<FormattedMessage id="setting.features.set-commission" defaultMessage="ตั้งค่าคอมมิชชั่น" />} key="3" disabled={!features.commission}>
                                    <Commission setActiveKey={setActiveKey} setIsUpdate={setIsUpdate} />
                                </Tabs.TabPane>
                            )}
                        </Tabs>
                    ) : (<EmptyComponents description={textNoDataSetting} />)}
            </div>
        </Fragment>
    )
}

export default Features