import { CommonResponse } from '../@types/api'
import { AuthInitResponse, LoginParam, LoginResponse } from '../@types/auth'
import api from '../utils/api'
import PrefixService from './prefix'
// import { toast } from 'react-toastify'

class AuthService {
	private token: string = ''

	constructor() {
		const token = localStorage.getItem('x-auth-token')
		if (token) {
			this.setToken(token)
		}
	}

	getToken(): string {
		return this.token
	}
	setToken(token: string, save: boolean = false) {
		this.token = token
		if (save) {
			localStorage.setItem('x-auth-token', token)
		}
	}
	getUsername(): string {
		const username = localStorage.getItem('username')
		return username || '-'
	}
	setUsername(username: string, save: boolean = false) {
		if (save) {
			localStorage.setItem('username', username)
		}
	}

	async revokeToken() {
		// await this.logoutLogs()
		this.token = ''
		delete api.defaults.headers.common['Authorization']
		localStorage.removeItem('x-auth-token')
		localStorage.removeItem('username')
	}

	async login(data: LoginParam): Promise<boolean> {
		PrefixService.setPrefix(data.prefix || "", true)

		try {
			const { token } = await api.post<void, LoginResponse>(
				'/login',
				data
			)

			this.setToken(token, true)
			this.setUsername(data.username, true)
			return false
		} catch (e) {
			return Promise.reject(e)
		}
	}

	async getAuthInit(): Promise<AuthInitResponse> {
		try {
			const res = await api.get<void, AuthInitResponse>('/auth/init')

			return res
		} catch (e) {
			return Promise.reject(e)
		}
	}

	async logoutLogs() {
		await api.post<void, CommonResponse>('/logout')
		// toast.info(res.message)
		await this.revokeToken()
	}
}

export default new AuthService()
