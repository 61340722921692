import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import AuthService from '../../services/auth'
import styled from 'styled-components'
import { Colors } from '../../utils/color'

const Logout = () => {
    const [showChildren, setShowChildren] = useState(false)
    const Logout = async () => {
        await AuthService.logoutLogs()
        await AuthService.revokeToken()
        setShowChildren(true)
    }

    useEffect(() => {
        // AuthService.logoutLogs()
        Logout()
    }, [])

    if (!showChildren) {
        return (
            <CenterContainer className="app">
                <i className="fa-solid fa-spinner fa-spin fa-2x" />
            </CenterContainer>
        )
    }
    return <Navigate to="/login" replace />
}

export default Logout


const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;

	h4 {
		color: ${Colors.negative};
		font-family: 'Sarabun, sans-serif';
	}
`