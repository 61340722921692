import React from 'react'
import moment from 'moment'
import useFetchTransactionList from '../../hooks/useFetchTransactionList'
import NumberWithCommas from '../../utils/NumberWithCommas'
import { getBankName } from '../../utils/bank'
import Loading from '../Elements/Loading'
import Empty from '../Elements/Empty'
import FormattedMessage from '../Elements/FormattedMessage'

const WithDrawTransactionLastest = () => {
    const dateFormat = "DD/MM/YYYY HH:mm:ss"
    const { data, isLoading } = useFetchTransactionList(`/withdraw`, {
        dateRange: {
            start: moment().add(-1, 'days').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
        },
        type: 'withdraw',
        status: 'pending',
        limit: 20
    }, 30000)
    return (
        <div className="mb-4">
            <h5 className="mb-3"><FormattedMessage id={'main.withdraw-list'} defaultMessage={'รายการถอน'} /></h5>
            {!isLoading ?
                data !== undefined && data?.count !== 0 ?
                    Object.values(data?.transactions as any).map((item: any, index: number) => (
                        <div className="box-white box-list" key={index}>
                            <div className="box-list-head">
                                <div>
                                    <div>{item.member.firstName} {item.member.lastName}</div>
                                    <div><small>{item.member.game.username}</small></div>
                                </div>
                                <div className="ms-auto text-end">
                                    <div><span>฿</span> {NumberWithCommas(item.amount, 2)}</div>
                                    <div className="text-status-warning"><small><FormattedMessage id={'main.pending'} defaultMessage={'รอดำเนินการ'} /></small></div>
                                </div>
                            </div>
                            <div className="box-list-footer">
                                <small><FormattedMessage id="bank-to-transfer" defaultMessage="ธนาคารที่โอนเข้ามา" /> : {getBankName(item.transferTo)} ({item.transferTo})</small>
                                <small><FormattedMessage id="transfer" defaultMessage="ทำรายการโอน" /> : {moment(item?.createdAt).format(dateFormat)}</small>
                                <small><FormattedMessage id="pull-into-the-system" defaultMessage="ดึงเข้าระบบ" /> : {moment(item?.transferTime).format(dateFormat)}</small>
                            </div>
                        </div>
                    ))
                    :
                    <Empty />
                :
                <Loading />
            }
        </div>
    );
}

export default WithDrawTransactionLastest