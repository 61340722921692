export const AMBKingGamesType: Record<string, string> = {
    'casino': 'คาสิโนออนไลน์',
    'slot': 'สล็อตออนไลน์',
    // 'lotto': 'หวย',
    'sport': 'กีฬา',
    'keno': 'คีโน่',
    'poker': 'เกมไพ่',
    'esport': 'อีสปอร์ต',
    'fish': 'ปลา',
    'card': 'การ์ด',
    'trade': 'เทรด',
}

export const AMBKingGamesTypeEN: Record<string, string> = {
    'casino': 'Casino',
    'slot': 'Slot',
    // 'lotto': 'Lotto',
    'sport': 'Sport',
    'keno': 'Keno',
    'poker': 'Poker',
    'esport': 'E-sport',
    'fish': 'Fish',
    'card': 'Card',
    'trade': 'Trade',
}