import { BankConfig } from '../@types/prefix' //, SCBAccountConfig
import baacIcon from '../assets/img/bank-logo/baac.svg'
import bayIcon from '../assets/img/bank-logo/bay.svg'
import bblIcon from '../assets/img/bank-logo/bbl.svg'
import bocIcon from '../assets/img/bank-logo/boc.svg'
import bofaIcon from '../assets/img/bank-logo/bofa.svg'
import cimbIcon from '../assets/img/bank-logo/cimb.svg'
import citiIcon from '../assets/img/bank-logo/citi.svg'
import ghIcon from '../assets/img/bank-logo/gh.svg'
import gsbIcon from '../assets/img/bank-logo/gsb.svg'
import hsbcIcon from '../assets/img/bank-logo/hsbc.svg'
import ibankIcon from '../assets/img/bank-logo/ibank.svg'
import icbcIcon from '../assets/img/bank-logo/icbc.svg'
import kbankIcon from '../assets/img/bank-logo/kbank.svg'
import kkIcon from '../assets/img/bank-logo/kk.svg'
import ktbIcon from '../assets/img/bank-logo/ktb.svg'
import lhIcon from '../assets/img/bank-logo/lh.svg'
import scIcon from '../assets/img/bank-logo/sc.svg'
import scbIcon from '../assets/img/bank-logo/scb.svg'
import tbankIcon from '../assets/img/bank-logo/tbank.svg'
import tiscoIcon from '../assets/img/bank-logo/tisco.svg'
import tmbIcon from '../assets/img/bank-logo/tmb.svg'
import ttbIcon from '../assets/img/bank-logo/ttb.svg'
import truewalletIcon from '../assets/img/bank-logo/truewallet.svg'
import uobtIcon from '../assets/img/bank-logo/uobt.svg'

import baacIconSlip from '../assets/img/scb-slip/bank/baac.png'
import bayIconSlip from '../assets/img/scb-slip/bank/bay.png'
import bblIconSlip from '../assets/img/scb-slip/bank/bbl.png'
import cimbIconSlip from '../assets/img/scb-slip/bank/cimb.png'
import citiIconSlip from '../assets/img/scb-slip/bank/citi.png'
import ghIconSlip from '../assets/img/scb-slip/bank/ghb.png'
import gsbIconSlip from '../assets/img/scb-slip/bank/gsb.png'
import ibankIconSlip from '../assets/img/scb-slip/bank/ibank.png'
import icbcIconSlip from '../assets/img/scb-slip/bank/icbc.png'
import kbankIconSlip from '../assets/img/scb-slip/bank/kbank.png'
import kkIconSlip from '../assets/img/scb-slip/bank/kk.png'
import ktbIconSlip from '../assets/img/scb-slip/bank/ktb.png'
import lhIconSlip from '../assets/img/scb-slip/bank/lhb.png'
import scIconSlip from '../assets/img/scb-slip/bank/sc.png'
import scbIconSlip from '../assets/img/scb-slip/bank/scb.png'
import tbankIconSlip from '../assets/img/scb-slip/bank/tbank.png'
import tiscoIconSlip from '../assets/img/scb-slip/bank/tisco.png'
import ttbIconSlip from '../assets/img/scb-slip/bank/tmb.png'
import uobtIconSlip from '../assets/img/scb-slip/bank/uob.png'
import hsbcIconSlip from '../assets/img/scb-slip/bank/hsbc.png'

export const bankNames: Record<string, string> = {
	KBANK: 'ธนาคารกสิกรไทย',
	BBL: 'ธนาคารกรุงเทพ',
	KTB: 'ธนาคารกรุงไทย',
	BAY: 'ธนาคารกรุงศรีอยุธยา',
	SCB: 'ธนาคารไทยพาณิชย์',
	KK: 'ธนาคารเกียรตินาคิน',
	CITI: 'ธนาคารซิตี้แบ้งค์',
	CIMB: 'ธนาคารซีไอเอ็มบี (ไทย)',
	TMB: 'ธนาคารทหารไทย',
	TTB: 'ธนาคารทหารไทยธนชาต',
	TISCO: 'ธนาคารทิสโก้',
	TBANK: 'ธนาคารธนชาต',
	BAAC: 'ธ.ก.ส.',
	UOBT: 'ธนาคารยูโอบี',
	LH: 'ธนาคารแลนด์ แอนด์ เฮ้าส',
	SC: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
	BOC: 'ธนาคารแห่งประเทศจีน (ไทย)',
	BOFA: 'ธนาคารแห่งอเมริกาฯ',
	GSB: 'ธนาคารออมสิน',
	GH: 'ธนาคารอาคารสงเคราะห์',
	IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
	ICBC: 'ธนาคารไอซีบีซี (ไทย)',
	HSBC: 'ธนาคารฮ่องกงและเซี่ยงไฮ้',
	TRUEWALLET: 'ทรูมันนี่วอลเล็ท',
}

export const bankNamesEN: Record<string, string> = {
	KBANK: 'Kasikorn Bank',
	BBL: 'Bangkok Bank',
	KTB: 'Krung Thai Bank',
	BAY: 'Bank of Ayudhya',
	SCB: 'Siam Commercial Bank',
	KK: 'Kiatnakin Bank',
	CITI: 'Citibank',
	CIMB: 'CIMB Bank (Thai)',
	TMB: 'Thai Military Bank',
	TTB: 'TMB Thanachart Bank',
	TISCO: 'TISCO Bank',
	TBANK: 'Thanachart Bank',
	BAAC: 'BAAC',
	UOBT: 'UOB Bank',
	LH: 'Land and Houses Bank',
	SC: 'Standard Chartered Bank (Thai)',
	BOC: 'Bank of China (Thai)',
	BOFA: 'Bank of America',
	GSB: 'Government Savings Bank',
	GH: 'Government Housing Bank',
	IBANK: 'Islamic Bank of Thailand',
	ICBC: 'ICBC Bank (Thai)',
	HSBC: 'Hong Kong and Shanghai Bank',
	TRUEWALLET: 'True Money Wallet',
}

export const getBankName = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	const langDefault = localStorage.getItem('i18nextLng') || 'th'
	if (code === 'TRUEWALLET') {
		if (langDefault === "th") {
			return 'ทรูมันนี่วอลเล็ท'
		} else {
			return 'True Money Wallet'
		}
	}

	if (bankNames.hasOwnProperty(code)) {
		if (langDefault === "th") {
			return bankNames[code]
		} else {
			return bankNamesEN[code]
		}
	}
	return code
}

export const getBankIcon = (code: string) => {
	code = code.toUpperCase()
	switch (code) {
		case 'KBANK': {
			return kbankIcon
		}
		case 'BBL': {
			return bblIcon
		}
		case 'KTB': {
			return ktbIcon
		}
		case 'BAY': {
			return bayIcon
		}
		case 'SCB': {
			return scbIcon
		}
		case 'KK': {
			return kkIcon
		}
		case 'CITI': {
			return citiIcon
		}
		case 'CIMB': {
			return cimbIcon
		}
		case 'TMB': {
			return tmbIcon
		}
		case 'TTB': {
			return ttbIcon
		}
		case 'TISCO': {
			return tiscoIcon
		}
		case 'TBANK': {
			return tbankIcon
		}
		case 'BAAC': {
			return baacIcon
		}
		case 'UOBT': {
			return uobtIcon
		}
		case 'LH': {
			return lhIcon
		}
		case 'SC': {
			return scIcon
		}
		case 'BOC': {
			return bocIcon
		}
		case 'BOFA': {
			return bofaIcon
		}
		case 'GSB': {
			return gsbIcon
		}
		case 'GH': {
			return ghIcon
		}
		case 'IBANK': {
			return ibankIcon
		}
		case 'ICBC': {
			return icbcIcon
		}
		case 'HSBC': {
			return hsbcIcon
		}
		case 'TRUEWALLET': {
			return truewalletIcon
		}
		default: {
			return ''
		}
	}
}

export const bankSelect: Record<string, string> = {
	scb: 'ธนาคารไทยพาณิชย์',
	kbank: 'ธนาคารกสิกรไทย',
	bay: 'ธนาคารกรุงศรีอยุธยา',
}

export const bankApp: Record<string, string> = {
	scb: 'Easy Net',
	kbank: 'KBiz',
	bay: 'Krungsri Biz',
}


export const getBankIconSlip = (code: string) => {
	code = code.toUpperCase()
	switch (code) {
		case 'KBANK': {
			return kbankIconSlip
		}
		case 'BBL': {
			return bblIconSlip
		}
		case 'KTB': {
			return ktbIconSlip
		}
		case 'BAY': {
			return bayIconSlip
		}
		case 'SCB': {
			return scbIconSlip
		}
		case 'KK': {
			return kkIconSlip
		}
		case 'CITI': {
			return citiIconSlip
		}
		case 'CIMB': {
			return cimbIconSlip
		}
		case 'TTB': {
			return ttbIconSlip
		}
		case 'TISCO': {
			return tiscoIconSlip
		}
		case 'TBANK': {
			return tbankIconSlip
		}
		case 'BAAC': {
			return baacIconSlip
		}
		case 'UOBT': {
			return uobtIconSlip
		}
		case 'LH': {
			return lhIconSlip
		}
		case 'SC': {
			return scIconSlip
		}
		// case 'BOC': {
		// 	return bocIconSlip
		// }
		// case 'BOFA': {
		// 	return bofaIconSlip
		// }
		case 'GSB': {
			return gsbIconSlip
		}
		case 'GH': {
			return ghIconSlip
		}
		case 'IBANK': {
			return ibankIconSlip
		}
		case 'ICBC': {
			return icbcIconSlip
		}
		case 'HSBC': {
			return hsbcIconSlip
		}
		default: {
			return ''
		}
	}
}



export const createBankSelector = (banks: BankConfig[]) =>
	banks
		.filter(
			({ bank, meta }) => bank === 'scb' && meta.username && meta.password
		)
		.map(({ accountNumber, accountName }) => ({
			label: `${accountNumber} - ${accountName}`,
			value: accountNumber,
		}))

export const createBankKbankSelector = (banks: BankConfig[]) =>
	banks
		.filter(
			({ bank, meta }) => bank === 'kbank' && meta.username && meta.password
		)
		.map(({ accountNumber, accountName }) => ({
			label: `${accountNumber} - ${accountName}`,
			value: accountNumber,
		}))

export const createSCBAccountSelector = (
	accounts: BankConfig[]
) =>
	accounts
		.filter(
			({ bank, meta }) => bank === 'scb' && (meta.deviceID && meta.license && meta.pin)
		).map(({ accountNumber, accountName }) => ({
			label: `${accountNumber} - ${accountName}`,
			value: accountNumber,
		}))
