import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import AppLayout from './layouts/App'
import Login from './views/Login'
import Logout from './views/Logout'
import './utils/i18n'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        {/* <Route path="/secure-code" element={SecureCode} /> */}
        <Route path="*" element={<AppLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

