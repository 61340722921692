import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Modal,
    Input,
    InputNumber,
    Space,
    Divider,
    message,
    Checkbox,
    DatePicker,
    Radio
} from 'antd'
import { toast } from 'react-toastify'
import FileSaver from "file-saver"
import * as XLSX from 'xlsx'
import moment from 'moment'
import PrefixService from "../../../services/prefix"
import { BonusGameTypes, BonusGameTypesAMBKing, RedeemAddConfig } from '../../../@types/prefix'
import PatternNumber from '../../../utils/PatternNumber'
import FormattedMessage from '../../Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'

interface AddCodeProps {
    close: () => void
    isOpen: boolean
    onloadData: () => void
}

const AddCode: FC<AddCodeProps> = ({ close, isOpen, onloadData }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("redeem.add", "เพิ่มโค้ดโปรโมชั่น")
    const { game } = useSelector((state: ApplicationState) => state.prefix)

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [noDepositBetweenChecked, setNoDepositBetweenChecked] = useState<boolean>(false)
    const [depositBetweenChecked, setDepositBetweenChecked] = useState<boolean>(false)
    const [isModalVisable, setIsModalVisable] = useState<boolean>(false);
    const [reuse, setReuse] = useState<boolean>(false);
    const [dataCodes, setDataCodes] = useState<any>([]);
    const [dataCodesExcel, setDataCodesExcel] = useState<any>([]);
    const dateFormat = 'DD/MM/YYYY'
    const insertData = async (values: RedeemAddConfig) => {
        try {
            const res = await PrefixService.addRedeem(values)
            setBtnLoading(false)
            onloadData()
            toast.success(res.message)
            close()
            let dataRes = ""
            if (res.codes.length > 0) {
                Object.values(res.codes).forEach((item: any) => {
                    dataRes += `${item.Code}\n`
                })
                const reData = Object.values(res.codes).map((item: any) => {
                    return {
                        Code: item.Code,
                        Amount: item.Amount,
                        Used: item.Used ? "ใช้งานแล้ว" : "ยังไม่ได้ใช้งาน",
                        MaxWithdraw: item.MaxWithdraw,
                        Turn: item.Turn,
                        Reuse: item.Reuse ? "ใช้ซ้ำ" : "ไม่ใช้ซ้ำ",
                        ReuseAmount: item.ReuseAmount,
                        CreatedAt: moment(item.CreatedAt).format("DD/MM/YYYY HH:mm:ss")
                    }
                })
                setDataCodesExcel(reData)
                copyData(dataRes)
                setDataCodes(dataRes)
                setIsModalVisable(true)
            }

        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const copyData = (text: any) => {
        navigator.clipboard.writeText(`${text}`)
        message.success('Successfully copied data')
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const Heading = [
        {
            Code: "โค้ดโปรโมชั่น",
            Amount: "เครดิต",
            Used: "สถานะ",
            MaxWithdraw: "ยอดถอนสูงสุด (เท่า)",
            Turn: "เทิร์น (เท่า)",
            Reuse: "ใช้ซ้ำ",
            ReuseAmount: "จำนวนใช้ซ้ำ",
            CreatedAt: "วันที่สร้าง"
        }
    ];

    const exportData = (dataExport: any) => {
        const ws = XLSX.utils.json_to_sheet(Heading, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "Reuse", "ReuseAmount", "CreatedAt"],
            skipHeader: true
        });
        // ws["!cols"] = [
        //     { wch: Math.max(...dataExport.map((item: any) => item.Code.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Amount.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Used.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.MaxWithdraw.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.Turn.length)) + 5 },
        //     { wch: Math.max(...dataExport.map((item: any) => item.CreatedAt.length)) + 5 }
        // ];
        XLSX.utils.sheet_add_json(ws, dataExport, {
            header: ["Code", "Amount", "Used", "MaxWithdraw", "Turn", "Reuse", "ReuseAmount", "CreatedAt"],
            skipHeader: true,
            origin: -1
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Export_Redeem" + fileExtension);
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: RedeemAddConfig) => {
                setBtnLoading(true)
                values.condition.noDepositBetween.startDate = values.condition.noDepositBetween.startDate ? moment(values.condition.noDepositBetween.startDate).format('YYYY-MM-DD') : ""
                values.condition.noDepositBetween.endDate = values.condition.noDepositBetween.endDate ? moment(values.condition.noDepositBetween.endDate).format('YYYY-MM-DD') : ""
                values.condition.depositBetween.startDate = values.condition.depositBetween.startDate ? moment(values.condition.depositBetween.startDate).format('YYYY-MM-DD') : ""
                values.condition.depositBetween.endDate = values.condition.depositBetween.endDate ? moment(values.condition.depositBetween.endDate).format('YYYY-MM-DD') : ""
                // console.log(values);
                insertData(values)
            })
            .catch((errorInfo) => { });
    };


    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            setNoDepositBetweenChecked(false)
            setDepositBetweenChecked(false)
            form.setFieldsValue({ "code": localStorage.getItem('x-prefix') + '' + Math.floor(Math.random() * 1000000000) })
        }
    }, [form, isOpen]);


    const text_codeAmount = FuncFormattedMessage("redeem.input.codeAmount", "จำนวนโค้ด")
    const textPls_codeAmount = FuncFormattedMessage("redeem.input.plsCodeAmount", "กรุณากรอกจำนวนโค้ด")
    const text_amount = FuncFormattedMessage("redeem.input.amount", "จำนวนเครดิต")
    const textPls_amount = FuncFormattedMessage("redeem.input.plsAmount", "กรุณากรอกจำนวนเครดิต")
    const text_maxWithdraw = FuncFormattedMessage("redeem.input.maxWithdraw", "ยอดถอนสูงสุด (เท่า)")
    const textPls_maxWithdraw = FuncFormattedMessage("redeem.input.plsMaxWithdraw", "กรุณากรอกยอดถอนสูงสุด (เท่า)")
    const text_turn = FuncFormattedMessage("redeem.input.turn", "เทิร์น (เท่า)")
    const textPls_turn = FuncFormattedMessage("redeem.input.plsTurn", "กรุณากรอกเทิร์น (เท่า)")
    const text_reuse = FuncFormattedMessage("redeem.input.reuse", "ใช้ซ้ำ")
    const text_reuseAmount = FuncFormattedMessage("redeem.input.reuseAmount", "จำนวนใช้ซ้ำ")
    const textPls_reuseAmount = FuncFormattedMessage("redeem.input.plsReuseAmount", "กรุณากรอกจำนวนใช้ซ้ำ")
    const text_minDeposit = FuncFormattedMessage("redeem.input.minDeposit", "ยอดฝากขั้นต่ำ")
    const textPls_minDeposit = FuncFormattedMessage("redeem.input.plsMinDeposit", "กรุณากรอกจยอดฝากขั้นต่ำ")
    const text_sdate = FuncFormattedMessage("start_date", "วันที่เริ่มต้น")
    const text_edate = FuncFormattedMessage("end_date", "วันที่สิ้นสุด")

    const gameTypeSupport = ["ambbet", "wingbet", "2goalbet", "betflix", "ambking"];

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="addCode"
                    layout="vertical"
                    hideRequiredMark>
                    {/* <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="code"
                                label="โค้ดโปรโมชั่น"
                                rules={[{ required: true, message: 'กรุณากรอกโค้ดโปรโมชั่น' }]}
                                hasFeedback>
                                <Input placeholder="โค้ดโปรโมชั่น" />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="codeAmount"
                                label={text_codeAmount}
                                rules={[
                                    { required: true, message: textPls_codeAmount },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_codeAmount} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label={text_amount}
                                rules={[
                                    { required: true, message: textPls_amount },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_amount} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="maxWithdraw"
                                label={text_maxWithdraw}
                                rules={[
                                    { required: true, message: textPls_maxWithdraw },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_maxWithdraw} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="turn"
                                label={text_turn}
                                rules={[
                                    { required: true, message: textPls_turn },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_turn} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="checkbox-collapse mb-4">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="reuse"
                                    valuePropName="checked">
                                    {/* <Switch
                                        checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                        unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                        onChange={(e) => setReuse(e)}
                                    /> */}
                                    <Checkbox onClick={(e: any) => setReuse(e.target.checked)}>
                                        {text_reuse}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        {reuse && (
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="reuseAmount"
                                        label={text_reuseAmount}
                                        rules={[
                                            { required: true, message: textPls_reuseAmount },
                                            { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                        ]}
                                        hasFeedback>
                                        <InputNumber placeholder={text_reuseAmount} inputMode="numeric" controls={false} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className="checkbox-collapse mb-4">
                        <Divider orientation='left'><FormattedMessage id="bonus.condition" defaultMessage="เงื่อนไข" /></Divider>
                        {gameTypeSupport.find((item) => item.toUpperCase() === (game).toUpperCase()) !== undefined && (
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item
                                        name={['condition', 'gameType']}
                                        label={<FormattedMessage id="bonus.input.type_game" defaultMessage="ประเภทเกม" />}
                                        hasFeedback>
                                        <Radio.Group buttonStyle="solid" defaultValue={"none"}>
                                            {((game).toUpperCase() === "AMBKING") ? (
                                                BonusGameTypesAMBKing.map(({ game, label }) => (
                                                    <Radio.Button value={game} key={game}><FormattedMessage id={`bonus.radio_option_BonusGameTypes.${game}`} defaultMessage={label} /></Radio.Button>
                                                ))
                                            ) : (
                                                BonusGameTypes.map(({ game, label }) => (
                                                    <Radio.Button value={game} key={game}><FormattedMessage id={`bonus.radio_option_BonusGameTypes.${game}`} defaultMessage={label} /></Radio.Button>
                                                ))
                                            )}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={['condition', 'noDepositBetween', 'enable']}
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Checkbox onClick={(e: any) => setNoDepositBetweenChecked(e.target.checked)}>
                                        <FormattedMessage id="redeem.input.no_deposit" defaultMessage="ไม่มีรายการฝากในระหว่าง" />
                                    </Checkbox>
                                </Form.Item>
                                {noDepositBetweenChecked && (
                                    <Row gutter={16} className="mt-2">
                                        <Col span={12}>
                                            <Form.Item
                                                name={['condition', 'noDepositBetween', 'startDate']}
                                                key="startDate"
                                                label={text_sdate}
                                                rules={[{ required: true, message: <FormattedMessage id="please_start_date" defaultMessage="กรุณากรอกวันที่เริ่มต้น" /> }]}
                                                hasFeedback>
                                                <DatePicker placeholder={text_sdate} format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={['condition', 'noDepositBetween', 'endDate']}
                                                key="endDate"
                                                label={text_edate}
                                                rules={[{ required: true, message: <FormattedMessage id="please_end_date" defaultMessage="กรุณากรอกวันที่สิ้นสุด" /> }]}
                                                hasFeedback>
                                                <DatePicker placeholder={text_edate} format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={['condition', 'depositBetween', 'enable']}
                                    valuePropName="checked"
                                    className="mb-0"
                                >
                                    <Checkbox onClick={(e: any) => setDepositBetweenChecked(e.target.checked)}>
                                        <FormattedMessage id="redeem.input.depositBetween" defaultMessage="ยอดฝากขั้นต่ำ" />
                                    </Checkbox>
                                </Form.Item>
                                {depositBetweenChecked && (
                                    <Row gutter={16} className="mt-2">
                                        <Col span={12}>
                                            <Form.Item
                                                name={['condition', 'depositBetween', 'startDate']}
                                                key="startDate"
                                                label={text_sdate}
                                                rules={[{ required: true, message: <FormattedMessage id="please_start_date" defaultMessage="กรุณากรอกวันที่เริ่มต้น" /> }]}
                                                hasFeedback>
                                                <DatePicker placeholder={text_sdate} format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={['condition', 'depositBetween', 'endDate']}
                                                key="endDate"
                                                label={text_edate}
                                                rules={[{ required: true, message: <FormattedMessage id="please_end_date" defaultMessage="กรุณากรอกวันที่สิ้นสุด" /> }]}
                                                hasFeedback>
                                                <DatePicker placeholder={text_edate} format={dateFormat} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={['condition', 'depositBetween', 'minDeposit']}
                                                label={text_minDeposit}
                                                rules={[
                                                    { required: true, message: textPls_minDeposit },
                                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                                ]}
                                                hasFeedback>
                                                <InputNumber placeholder={text_minDeposit} inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i>
                                <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
            <Modal
                title="โค้ดโปรโมชั่น"
                visible={isModalVisable}
                onCancel={() => setIsModalVisable(false)}
                footer={[
                    <Button key="excel" danger type="primary" onClick={() => exportData(dataCodesExcel)}  ><i className="fa-solid fa-arrow-down-to-line me-2"></i>Export Excel</Button>,
                    <Button key="submit" type="primary" onClick={() => copyData(dataCodes)} ><i className="fa-solid fa-copy me-2"></i><FormattedMessage id="copy" defaultMessage="คัดลอก" /></Button>,
                ]}
            >
                <div className="d-flex align-items-center">
                    <Input.TextArea autoSize={{ minRows: 6 }} disabled={true} value={dataCodes} />
                </div>
            </Modal>
        </>
    )
}
export default AddCode

