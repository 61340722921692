import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    Checkbox,
    Space,
    Button,
    InputNumber
} from 'antd'
import PatternNumber from '../../../utils/PatternNumber'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormattedMessage from '../../Elements/FormattedMessage';
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage';

interface StepSettingProps {
    data: any
}

const StepSetting: FC<StepSettingProps> = ({ data }) => {
    const [stepChecked, setStepChecked] = useState<boolean>(data.step)

    const isDeleted = (key: number) => {
        if (data.steps[key] !== undefined) {
            data.steps[key].deleted = true
        }
    }

    const isAdd = () => {
        data.steps.push({ min: 0, max: 0, bonus: 0, deleted: false })
    }

    useEffect(() => {
        setStepChecked(data.step)
    }, [data]) // eslint-disable-line

    const text_deposit_amount = FuncFormattedMessage("bonus.input.deposit_amount", "ยอดฝาก")
    const text_bonus = FuncFormattedMessage("bonus.input.bonus", "โบนัส")

    return (
        <div className="checkbox-collapse mb-2">
            <Form.Item
                name="step"
                valuePropName="checked"
                className="mb-0"
            >
                <Checkbox onClick={(e: any) => setStepChecked(e.target.checked)}>
                    <FormattedMessage id="bonus.input.use_conditional_bonuses" defaultMessage="ใช้งานโบนัสแบบเงื่อนไข" />
                </Checkbox>
            </Form.Item>
            {stepChecked &&
                <Form.List name="steps" key="steps">
                    {(steps, { add, remove }) => {
                        return (
                            <>
                                {steps.map((field, index) => (
                                    <Space key={`${index}-main`} size="middle" className="mt-2" style={{ display: 'flex' }}>
                                        <Form.Item
                                            name={[index, "min"]}
                                            key={`${index}-min`}
                                            label={text_deposit_amount}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="bonus.input.please_deposit_amount" defaultMessage="กรุณากรอกยอดฝาก" /> },
                                                { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                            ]}
                                            hasFeedback>
                                            <InputNumber placeholder={text_deposit_amount} inputMode="numeric" controls={false} />
                                        </Form.Item>
                                        <FormattedMessage id="bonus.input.to" defaultMessage="ถึง" />
                                        <Form.Item
                                            name={[index, "max"]}
                                            key={`${index}-max`}
                                            label={text_deposit_amount}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="bonus.input.please_deposit_amount" defaultMessage="กรุณากรอกยอดฝาก" /> },
                                                { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                            ]}
                                            hasFeedback>
                                            <InputNumber placeholder={text_deposit_amount} inputMode="numeric" controls={false} />
                                        </Form.Item>
                                        <Form.Item
                                            name={[index, "bonus"]}
                                            key={`${index}-bonus`}
                                            label={text_bonus}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="bonus.input.please_bunus" defaultMessage="กรุณากรอกโบนัส" /> },
                                                { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                            ]}
                                            hasFeedback>
                                            <InputNumber placeholder={text_bonus} inputMode="numeric" controls={false} />
                                        </Form.Item>
                                        {steps.length > 1 && index !== 0 && (
                                            <MinusCircleOutlined key={`${index}-del`} onClick={() => {
                                                isDeleted(field.key)
                                                remove(field.name)
                                            }} />
                                        )}
                                    </Space>
                                ))}
                                <Form.Item className="mb-0">
                                    <Button
                                        key="add-list"
                                        type="dashed"
                                        onClick={() => {
                                            isAdd()
                                            add({ min: 0, max: 0, bonus: 0, deleted: false })
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <PlusOutlined /> <FormattedMessage id="bonus.input.add_condition" defaultMessage="เพิ่มเงื่อนไข" />
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            }
        </div>
    )
}
export default StepSetting

