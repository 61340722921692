import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { toast } from 'react-toastify'
import AuthService from '../services/auth'
import PrefixService from '../services/prefix'
import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';
// import { getDomainWithoutSubdomain } from './domainWithoutSubdomain';

export default function NotifyWebSocket() {
    const SocketUrl = (process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "").replace("http://", "ws://").replace(
        "https://",
        "wss://"
    );

    // let SocketUrlEndPoint = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ""
    // if (window.location.hostname !== 'localhost' && window.location.hostname !== "msnbet.co") {
    //     SocketUrlEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2/backend`
    // }

    // const SocketUrlEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2/backend`
    // const SocketUrl = (SocketUrlEndPoint).replace("http://", "ws://").replace(
    //     "https://",
    //     "wss://"
    // );

    const { name } = useSelector((state: ApplicationState) => state.prefix)
    const token = AuthService.getToken()
    const prefix = PrefixService.getPrefix()
    const { lastMessage } = useWebSocket(`${SocketUrl}/ws?prefix=${prefix ? prefix : name}&token=${token}`);

    const [audio] = useState<HTMLAudioElement | null>(new Audio(require(`../assets/audio/noti.mp3`).default));

    useEffect(() => {
        if (lastMessage !== null) {
            // setMessageHistory((prev: any) => prev.concat(lastMessage));
            if (lastMessage.data !== "PONG") {
                const notify = JSON.parse(lastMessage.data);
                // console.log(notify);
                if (audio) {
                    audio.currentTime = 0;
                    audio.volume = 0.5;
                    audio.play();
                }
                toast(notify.Message, {
                    type: notify.Type === "danger" ? "error" : notify.Type,
                    autoClose: 5000
                })
            }
        }
    }, [lastMessage]) // eslint-disable-line
}

