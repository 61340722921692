import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../../store'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, InputNumber, Row, Col, Switch, Checkbox, Divider, Space, Button, Select } from 'antd';
import PatternNumber from '../../../utils/PatternNumber'
import { CashbackConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const PromotionCashback = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [withdrawLimitType, setWithdrawLimitType] = useState<string>("")
    const { Option } = Select

    const [form] = Form.useForm();

    const updateData = async (values: CashbackConfig) => {
        try {
            const res = await PrefixService.saveCashbackConfig(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CashbackConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.cashback)
            setWithdrawLimitType(prefix?.setting?.cashback.withdrawLimitType)
        }
    }, [form])// eslint-disable-line

    const text_cashback = FuncFormattedMessage("cashback.input.cashback", "แคชแบ็ก")
    const text_maxCashback = FuncFormattedMessage("cashback.input.maxAmountCashback", "จำนวนแคชแบ็กสูงสุด")
    const text_minDeposit = FuncFormattedMessage("cashback.input.minDeposit", "ยอดฝากขั้นต่ำ")
    const text_turnTimes = FuncFormattedMessage("turn-times", "เทิร์น (เท่า)")
    const text_maxWithdrawType = FuncFormattedMessage("cashback.input.maxWithdrawType", "ประเภทถอนสูงสุด")
    const text_select_maxWithdrawType = FuncFormattedMessage("cashback.input.select_maxWithdrawType", "เลือกประเภทถอนสูงสุด")
    const text_maxWithdraw = FuncFormattedMessage("cashback.input.maxWithdraw", "จำนวนถอนสูงสุด")
    const text_maxWithdraw_times = FuncFormattedMessage("cashback.input.maxWithdraw_times", "ถอนสูงสุด (เท่า)")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="cashback.title" defaultMessage="จัดการแคชแบ็ก" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="cashbackForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={<FormattedMessage id="status" defaultMessage="สถานะ" />}
                                name="enable"
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={text_cashback}
                                rules={[
                                    { required: false, message: <FormattedMessage id="cashback.input.please_cashback" defaultMessage="กรุณากรอกแคชแบ็ก" /> },
                                ]}>
                                <Input placeholder={text_cashback} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxAmount"
                                label={text_maxCashback}
                                rules={[
                                    { required: false, message: <FormattedMessage id="cashback.input.please_maxAmountCashback" defaultMessage="กรุณากรอกจำนวนแคชแบ็กสูงสุด" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_maxCashback} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="min"
                                label={text_minDeposit}
                                rules={[
                                    { required: false, message: <FormattedMessage id="cashback.input.please_minDeposit" defaultMessage="กรุณากรอกยอดฝากขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_minDeposit} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="turn"
                                label={text_turnTimes}
                                rules={[
                                    { required: false, message: <FormattedMessage id="please-enter-turn-times" defaultMessage="กรุณากรอกเทิร์น (เท่า)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_turnTimes} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimitType"
                                label={text_maxWithdrawType}
                                rules={[{ required: true, message: <FormattedMessage id="cashback.input.please_maxWithdrawType" defaultMessage="กรุณาเลือกประเภทถอนสูงสุด" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={text_select_maxWithdrawType}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setWithdrawLimitType(value);
                                    }}>
                                    <Option key={'fixed'} value={'fixed'}><FormattedMessage id="cashback.input.option_maxWithdrawType.fixed" defaultMessage="จำนวน" /></Option>
                                    <Option key={'sum'} value={'sum'}><FormattedMessage id="cashback.input.option_maxWithdrawType.sum" defaultMessage="ยอดแคชแบ็ค (เท่า)" /></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {withdrawLimitType === "fixed" ?
                                <Form.Item
                                    name="withdrawLimit"
                                    label={text_maxWithdraw}
                                    rules={[
                                        { required: false, message: <FormattedMessage id="cashback.input.please_maxWithdraw" defaultMessage="กรุณากรอกจำนวนถอนสูงสุด" /> },
                                        { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                    ]}>
                                    <InputNumber placeholder={text_maxWithdraw} inputMode="numeric" controls={false} />
                                </Form.Item>
                                :
                                <Form.Item
                                    name="withdrawLimit"
                                    label={text_maxWithdraw_times}
                                    rules={[
                                        { required: false, message: <FormattedMessage id="cashback.input.please_maxWithdraw_times" defaultMessage="กรุณากรอกถอนสูงสุด (เท่า)" /> },
                                        { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                    ]}>
                                    <InputNumber placeholder={text_maxWithdraw_times} inputMode="numeric" controls={false} />
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <h6 className="my-3"><FormattedMessage id="cashback.conditions_for_returning_losses" defaultMessage="เงื่อนไขคืนยอดเสีย" /></h6>
                            <Form.Item
                                name={['rules', 'minCredit']}
                                key="minCredit"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="cashback.conditions.minCredit" defaultMessage="ต้องมีเครดิตคงเหลือน้อยกว่า" /> {prefix?.setting?.minCredit}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noWithdraw']}
                                key="noWithdraw"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="cashback.conditions.noWithdraw" defaultMessage="ต้องไม่มีรายการถอน (ตลอดทั้งวัน)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noBonus']}
                                key="noBonus"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="cashback.conditions.noBonus" defaultMessage="ต้องไม่มีรายการรับโบนัส (ตลอดทั้งวัน)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'noWheel']}
                                key="noWheel"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="cashback.conditions.noWheel" defaultMessage="ต้องไม่มีรายการหมุนกงล้อ (ตลอดทั้งวัน)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['rules', 'oncePerDay']}
                                key="oncePerDay"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="cashback.conditions.oncePerDay" defaultMessage="รับแคชแบ็กได้ 1 ครั้งต่อวัน" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionCashback