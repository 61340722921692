import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PrefixConfig } from '../../@types/prefix'
import { requestAuthInitActions } from './../auth/auth.actions'

export interface State {
	loading: boolean
	error: string

	init: boolean
	features: Record<string, boolean>
	prefix: PrefixConfig
	game: string
	name: string
}

export const defaultState: State = {
	loading: false,
	error: '',

	init: false,
	features: {},
	prefix: {} as PrefixConfig,
	game: "",
	name: "",
}

export default reducerWithInitialState(defaultState)
	.case(requestAuthInitActions.started, (state, payload) => ({
		...state,
		loading: payload,
		error: '',
	}))
	.case(requestAuthInitActions.done, (state, payload) => ({
		...state,
		loading: false,

		init: true,
		features: payload.result.features,
		prefix: payload.result.prefix,
		name: payload.result.name,
		game: payload.result.game,
	}))
	.case(requestAuthInitActions.failed, (state, payload) => ({
		...state,
		loading: false,
		error: payload.error.message,

		init: true,
	}))
	.default((state) => state)
