import { Avatar, Tooltip } from 'antd'
import React, { FC } from 'react'
import NumberWithCommas from '../../utils/NumberWithCommas'
import { getRankingImage } from '../../utils/rank'

interface AvatarBankProps {
    rank: string
    point: number
}

const AvatarRank: FC<AvatarBankProps> = ({ rank, point }) => {

    return (
        <Tooltip title={`${rank.charAt(0).toUpperCase() + rank.slice(1)} Point : ${NumberWithCommas(point)}`}>
            <Avatar src={getRankingImage(rank)} />
        </Tooltip>
    )
}

export default AvatarRank
