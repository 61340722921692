import moment from 'moment'
import { useState } from 'react'

const useDateRange = () => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})

	return {
		dateRange,
		setDateRange,
	}
}

export default useDateRange
