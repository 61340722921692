import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/IDateRange'
import { PaginationParams } from '../@types/api'

export const useFetchLogs = (search: string, dateRange: DateRangeType, refreshInterval: number, params?: PaginationParams) => {
    const prefix = PrefixService.getPrefix()
    const { data, mutate, error } = useSWR([`/log/${prefix}?search=${search}`, dateRange?.start, dateRange?.end, params?.limit, params?.page], () =>
        PrefixService.getLogList(search, { dateRange: dateRange }, params), { refreshInterval }
    )

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchLogs
