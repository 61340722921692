import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    // Input,
    Checkbox,
    Space,
    Button
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MaskedInput from "antd-mask-input"
import FormattedMessage from '../../Elements/FormattedMessage';
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage';

interface PeriodSettingProps {
    data: any
}

const PeriodSetting: FC<PeriodSettingProps> = ({ data }) => {
    const [periodChecked, setPeriodChecked] = useState<boolean>(data.period)

    const isDeleted = (key: number) => {
        if (data.periods[key] !== undefined) {
            data.periods[key].deleted = true
        }
    }

    const isAdd = () => {
        data.periods.push({ from: "00:00", to: "23:59", deleted: false })
    }

    useEffect(() => {
        setPeriodChecked(data.period)
    }, [data]) // eslint-disable-line

    const text_from = FuncFormattedMessage("bonus.input.from", "จาก")
    const text_to = FuncFormattedMessage("bonus.input.to", "ถึง")

    return (
        <div className="checkbox-collapse mb-2">
            <Form.Item
                name="period"
                valuePropName="checked"
                className="mb-0"
            >
                <Checkbox onClick={(e: any) => setPeriodChecked(e.target.checked)}>
                    <FormattedMessage id="bonus.input.use_bonuses_over_time" defaultMessage="ใช้งานโบนัสตามช่วงเวลา" />
                </Checkbox>
            </Form.Item>
            {periodChecked &&
                <Form.List name="periods" key="periods">
                    {(periods, { add, remove }) => {
                        return (
                            <>
                                {periods.map((field, index) => (
                                    <Space key={`${index}-main`} size="middle" className="mt-2 mb-0" style={{ display: 'flex' }}>
                                        <Form.Item
                                            name={[index, "from"]}
                                            key={`${index}-from`}
                                            label={text_from}
                                            rules={[{ required: true, message: <FormattedMessage id="bonus.input.please_from" defaultMessage="กรุณากรอกจาก" /> }]}
                                            hasFeedback>
                                            {/* <Input placeholder="จาก" inputMode="numeric" /> */}
                                            <MaskedInput mask={'00:00'} placeholder={text_from} inputMode="numeric" />
                                        </Form.Item>
                                        <FormattedMessage id="bonus.input.to" defaultMessage="ถึง" />
                                        <Form.Item
                                            name={[index, "to"]}
                                            key={`${index}-to`}
                                            label={text_to}
                                            rules={[{ required: true, message: <FormattedMessage id="bonus.input.please_to" defaultMessage="กรุณากรอกถึง" /> }]}
                                            hasFeedback>
                                            {/* <Input placeholder="ถึง" inputMode="numeric" /> */}
                                            <MaskedInput mask={'00:00'} placeholder={text_to} inputMode="numeric" />
                                        </Form.Item>
                                        {periods.length > 1 && index !== 0 && (
                                            <MinusCircleOutlined key={`${index}-del`}
                                                onClick={() => {
                                                    isDeleted(field.key)
                                                    remove(field.name)
                                                }} />
                                        )}
                                    </Space>
                                ))}
                                <Form.Item className="mb-0">
                                    <Button
                                        key="add-list"
                                        type="dashed"
                                        onClick={() => {
                                            isAdd()
                                            add({ from: "00:00", to: '23:59', deleted: false })
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <PlusOutlined /> <FormattedMessage id="bonus.input.add_condition" defaultMessage="เพิ่มเงื่อนไข" />
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            }
        </div>
    )
}
export default PeriodSetting

