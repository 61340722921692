import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../../store'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, InputNumber, Row, Col, Checkbox, Divider, Space, Button, Alert } from 'antd'
import PatternNumber from '../../../utils/PatternNumber'
import { AffiliateConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const PromotionAffiliate = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [showTurn, setShowTurn] = useState<boolean>(false)
    const [form] = Form.useForm();

    const updateData = async (values: AffiliateConfig) => {
        try {
            values.withdrawToBank = prefix?.setting?.affiliate?.withdrawToBank
            const res = await PrefixService.saveAffiliateConfig(values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: AffiliateConfig) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.affiliate)
            setShowTurn(prefix?.setting?.affiliate?.withdrawToGame || false)
        }
    }, [form])// eslint-disable-line

    const text_turnTimes = FuncFormattedMessage("turn-times", "เทิร์น (เท่า)")
    const text_friend_recommendation = FuncFormattedMessage("affiliate.input.friend_recommendation", "ยอดแนะนำเพื่อน")
    const text_highest_recommendation = FuncFormattedMessage("affiliate.input.highest_recommendation", "ยอดแนะนำสูงสุด")
    const text_minimum_deposit = FuncFormattedMessage("affiliate.input.minimum_deposit", "ยอดฝากขั้นต่ำ")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="affiliate.title" defaultMessage="จัดการแนะนำเพื่อน" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="affiliateForm"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={text_friend_recommendation}
                                rules={[
                                    { required: false, message: <FormattedMessage id="affiliate.input.please_friend_recommendation" defaultMessage="กรุณากรอกยอดแนะนำเพื่อน" /> },
                                ]}>
                                <Input placeholder={`${text_friend_recommendation} (%)`} inputMode="numeric" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="max"
                                label={text_highest_recommendation}
                                rules={[
                                    { required: false, message: <FormattedMessage id="affiliate.input.please_friend_recommendation" defaultMessage="กรุณากรอกยอดแนะนำสูงสุด" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_highest_recommendation} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="min"
                                label={text_minimum_deposit}
                                rules={[
                                    { required: false, message: <FormattedMessage id="affiliate.input.please_minimum_deposit" defaultMessage="กรุณากรอกยอดฝากขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_minimum_deposit} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="checkbox-collapse">
                        <Form.Item
                            name="withdrawToGame"
                            key="withdrawToGame"
                            valuePropName="checked"
                            className="mb-0">
                            <Checkbox onClick={() => setShowTurn(!showTurn)}>
                                <FormattedMessage id="affiliate.input.withdrawToGame" defaultMessage="โยกยอดแนะนำเพื่อนเข้าเกม" />
                            </Checkbox>
                        </Form.Item>
                        {showTurn &&
                            <Form.Item
                                name="toGameTurn"
                                label={text_turnTimes}
                                className="mt-2 mb-0"
                                rules={[
                                    { required: false, message: <FormattedMessage id="please-enter-turn-times" defaultMessage="กรุณากรอกเทิร์น (เท่า)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}>
                                <InputNumber placeholder={text_turnTimes} inputMode="numeric" controls={false} />
                            </Form.Item>
                        }
                    </div>
                    <Alert message={<FormattedMessage id="affiliate.alert" defaultMessage={"* ระบบจะบังคับผู้ถูกแนะนำรับ \" โบนัสสมัครสมาชิกใหม่\" โดยอัตโนมัติเมื่อเติมเงิน"} />} type="error" className="mt-3" />
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default PromotionAffiliate