import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Tabs,
} from 'antd';
import DepositTab from './HistoryTab/DepositTab'
import WithdrawTab from './HistoryTab/WithdrawTab'
import OtherTab from './HistoryTab/OtherTab'
import DatePickerRange from '../Elements/DatePickerRange'
import DateFilterTabs from '../Elements/DateFilterTabs'
import useDateRange from "../../hooks/useDateRange"
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage';
import FormattedMessage from '../Elements/FormattedMessage';


interface HistoryProps {
    data: any
    close: () => void
    isOpen: boolean
}

const History: FC<HistoryProps> = ({ close, isOpen, data }) => {
    const title = FuncFormattedMessage("member.modal-history.title", "ประวัติการทำรายการ")
    const { TabPane } = Tabs;
    const [defaultActiveKey, setDefaultActiveKey] = useState<string>("1")
    const { dateRange, setDateRange } = useDateRange()

    useEffect(() => {
        if (isOpen) {
            setDefaultActiveKey("1")
        }
    }, [isOpen]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .8}>
                <div className="col-12 col-md-auto mb-3 mb-md-0 mb-md-0">
                    <h2 className="my-auto me-3 mb-2">{(data && Object.keys(data).length > 0) ? `${data.firstName} ${data.lastName}` : ''} </h2>
                    <p className="my-auto me-3">{(data && Object.keys(data).length > 0) ? (<><FormattedMessage id="tel" defaultMessage="เบอร์โทรศัพท์" />  : {data.phone} </>) : ''} </p>
                </div>
                <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4 mt-4">
                    <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                    <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
                </div>
                <Tabs activeKey={defaultActiveKey} centered onChange={(key) => setDefaultActiveKey(key)} style={{ overflow: 'visible' }}>
                    <TabPane tab={<FormattedMessage id="transaction-deposit" defaultMessage="รายการฝาก" />} key="1">
                        <DepositTab member={data || {}} dateRange={dateRange} />
                    </TabPane>
                    <TabPane tab={<FormattedMessage id="transaction-withdraw" defaultMessage="รายการถอน" />} key="2">
                        <WithdrawTab member={data || {}} dateRange={dateRange} />
                    </TabPane>
                    <TabPane tab={<FormattedMessage id="transaction-other" defaultMessage="รายการอื่นๆ" />} key="3">
                        <OtherTab member={data || {}} dateRange={dateRange} />
                    </TabPane>
                </Tabs>
            </Drawer>
        </>
    )
}
export default History