import moment from 'moment'
import { useState } from 'react'

const useDateRangeShowtime = () => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD 00:00:00'),
		end: moment().format('YYYY-MM-DD 23:59:59'),
	})

	return {
		dateRange,
		setDateRange,
	}
}

export default useDateRangeShowtime
