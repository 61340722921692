import React, { FC } from 'react'
import { Table, Spin, Skeleton, Empty } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'
import FormattedMessage from './FormattedMessage'

interface TableProps {
    columns: any
    dataSource: any
    loading?: boolean
    title?: string
    footer?: string
    customOffsetHeader?: number
    rowSelection?: any
    currentPage: number
    totalCount?: number
    onChangePagination: (e: any) => void
}

const TableComponent: FC<TableProps> = ({ columns, dataSource, loading, title, footer, customOffsetHeader, rowSelection, currentPage, totalCount = 0, onChangePagination }) => {
    let options: any = {}

    if (title) {
        options['title'] = () => title
    }
    if (footer) {
        options['footer'] = () => footer
    }

    return (
        <>
            <Spin spinning={loading} tip={<FormattedMessage id="loading" defaultMessage="กำลังโหลด..." />} size="large" >
                <Table
                    columns={columns as any}
                    dataSource={dataSource}
                    rowKey={() => nanoid()}
                    // loading={loading ? {
                    //     spinning: loading,
                    //     indicator: <Spin tip="กำลังโหลด..." size="large" />,
                    // } : false}
                    locale={{
                        emptyText: loading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                    scroll={{ x: true }}
                    sticky={{
                        offsetHeader: customOffsetHeader ? customOffsetHeader : (window.screen.width > 992 ? 84 : 57)
                    }}
                    onChange={ScrollToTopPagination}
                    pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: ` ${FuncFormattedMessage("list", "รายการ")}` },
                        total: totalCount,
                        current: currentPage || 1,
                        onChange: (page: number, pageSize: number) => {
                            onChangePagination({ page: page, pageSize: pageSize })
                        }
                    }}
                    {...options}
                />
            </Spin>
        </>
    )
}
export default TableComponent