import React, { Fragment, useEffect, useState } from 'react'
import TitleBar from '../../components/Elements/TitleBar'
import SearchData from '../../components/Elements/Search'
import TableComponent from '../../components/Elements/Table'
import { Space } from 'antd'
import moment from 'moment'
import useFetchUsersList from '../../hooks/useFetchUsersList'
import EditData from '../../components/Users/Edit'
import PrefixService from '../../services/prefix'
import { toast } from 'react-toastify'
import AddData from '../../components/Users/Add'
import Open2fa from '../../components/Users/Open2fa'
import Swal from 'sweetalert2'
import NumberWithCommas from '../../utils/NumberWithCommas'
// import { ApplicationState } from '../../store'
// import { useSelector } from 'react-redux'
import useFetchSetting from '../../hooks/useFetchSetting'
import FormattedMessage from '../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'


const Users = () => {
    const langDefault = localStorage.getItem('i18nextLng') || 'th'
    if (langDefault === 'th') {
        moment.locale('th')
    } else {
        moment.locale('en')
    }
    const [filterText, setFilterText] = useState('')
    const [dataSource, setDataSource] = useState<any>([])
    const [workingTime, setWorkingTime] = useState<boolean>(false)


    const { data: dataSetting } = useFetchSetting()

    const { data, mutate, isLoading } = useFetchUsersList()

    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    const [editDrawer, setEditDrawer] = useState(false)
    const toggleEdit = () => setEditDrawer(!editDrawer)

    const [open2faDrawer, setOpen2faDrawer] = useState(false)
    const toggleOpen2fa = () => setOpen2faDrawer(!open2faDrawer)

    const [usersData, setUsersData] = useState<any>()
    const handleEdit = (object: any) => setUsersData(object)

    const [dataUserPermissions, setDataPermisstions] = useState<object>({})

    const getUsersPermissions = async () => {
        try {
            const res = await PrefixService.getUsersPermissions()
            setDataPermisstions(res)
        } catch ({ message }) {
            toast.error(message as string)
        }
    }

    const text_confirm = FuncFormattedMessage("confirm", "ตกลง")
    const text_cancel = FuncFormattedMessage("cancel", "ยกเลิก")

    const textHandleDis2fa = {
        title: FuncFormattedMessage("handleDis2fa.title", "คุณต้องการปิดการใช้งาน 2FA?"),
        text: FuncFormattedMessage("handleDis2fa.text", "ตรวจสอบให้แน่ใจว่าคุณต้องการปิดใช้งาน 2FA (Two Factor Authentication) ดังกล่าว")
    }

    const handleDis2fa = (id: number) => {
        Swal.fire({
            title: `${textHandleDis2fa.title}`,
            text: `${textHandleDis2fa.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.disable2FA(id)
                    toast.success(res.message)
                    mutate()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const textHandleDelete = {
        title: FuncFormattedMessage("handleDelete.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handleDelete.text", "คุณต้องการลบรายการนี้ใช่หรือไม่")
    }

    const handleDelete = (username: string) => {
        Swal.fire({
            title: `${textHandleDelete.title}`,
            text: `${textHandleDelete.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.delUsersPermissions(username)
                    toast.success(res.message)
                    mutate()
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const columns: any = [
        {
            title: <FormattedMessage id="username" defaultMessage="ชื่อผู้ใช้งาน" />,
            dataIndex: 'username',
            hidden: true,
        },
        {
            title: <FormattedMessage id="workStartworkEnd" defaultMessage="เวลาเริ่ม-สิ้นสุดงาน" />,
            dataIndex: 'workStartworkEnd',
            hidden: workingTime,
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    {(record.workStart && record.workEnd) ?
                        <div><span>{record.workStart} - {record.workEnd}</span></div>
                        : <FormattedMessage id="notWorkStartworkEnd" defaultMessage="ไม่ได้ตั้งเวลาเริ่ม-สิ้นสุดงาน" />}
                </div>
            )
        },
        {
            title: <FormattedMessage id="lastUsed" defaultMessage="ใช้งานล่าสุด" />,
            dataIndex: 'updatedAt',
            hidden: true,
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                    <div className="bo-badge badge-light mt-2">{moment(record.updatedAt).fromNow()}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="createdAt2" defaultMessage="วันที่สร้าง" />,
            dataIndex: 'createdAt',
            width: '140px',
            hidden: true,
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="action" defaultMessage="จัดการ" />,
            dataIndex: 'action',
            hidden: true,
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        {record.twoFactorSecret ?
                            <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDis2fa(record.id)}>
                                <FormattedMessage id="close" defaultMessage="ปิด" /> 2FA
                            </button>
                            :
                            <button className="btn btn-success btn-sm text-white me-2" onClick={() => {
                                handleEdit(record)
                                toggleOpen2fa()
                            }}><FormattedMessage id="open" defaultMessage="เปิด" /> 2FA</button>
                        }
                        <button className="btn btn-warning btn-sm text-white me-2" onClick={() => {
                            handleEdit(record)
                            toggleEdit()
                        }}>
                            <i className="fa-solid fa-edit me-1"></i><FormattedMessage id="edit" defaultMessage="แก้ไข" />
                        </button>
                        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDelete(record.username)}>
                            <i className="fa-solid fa-trash me-1"></i><FormattedMessage id="delete" defaultMessage="ลบ" />
                        </button>
                    </Space>
                </>
            )
        }
    ].filter(item => item.hidden);

    useEffect(() => {
        if (data !== undefined && data.length > 0) {
            let filteredItems = (data as any).filter(
                (item: any) => (
                    (item.username && item.username.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            setDataSource(filteredItems)
            getUsersPermissions()
        }
        if (dataSetting !== undefined) {
            setWorkingTime(dataSetting?.setting.functions?.WorkingTime)
        }
        // setLoading(isLoading)
    }, [data, isLoading, filterText, setDataSource, dataSetting])

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="menu-users" defaultMessage="ผู้ใช้งาน" />} subTitle={`${NumberWithCommas(dataSource.length)} ${FuncFormattedMessage("list", "รายการ")}`} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                <button className="btn btn-primary ms-auto ms-md-0 mt-3 mt-md-0" onClick={() => {
                    toggleAdd()
                }}>
                    <i className="fa-solid fa-plus me-3"></i>
                    <span><FormattedMessage id="users.add" defaultMessage="เพิ่มผู้ใช้งาน" /></span>
                </button>
            </div>

            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>
            {addDrawer &&
                <AddData
                    isOpen={addDrawer}
                    close={() => toggleAdd()}
                    onloadData={() => mutate()}
                    permissionlist={dataUserPermissions}
                    workingTime={workingTime}
                />
            }
            {editDrawer &&
                <EditData
                    data={usersData as any}
                    isOpen={editDrawer}
                    close={() => toggleEdit()}
                    onloadData={() => mutate()}
                    permissionlist={dataUserPermissions}
                    workingTime={workingTime}
                />
            }
            {open2faDrawer &&
                <Open2fa
                    data={usersData as any}
                    isOpen={open2faDrawer}
                    close={() => toggleOpen2fa()}
                    onloadData={() => mutate()}
                />
            }
        </Fragment>
    )
}

export default Users