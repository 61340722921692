import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { GetTransactionListParams } from '../@types/transactions'
import { PaginationParams } from '../@types/api'

const useGetTransactionList = <T>(key: string, dataReq: GetTransactionListParams, refreshInterval: number, params?: PaginationParams): any => {
    const prefix = PrefixService.getPrefix()
    const { data, mutate, error } = useSWR([`/transactions/${key}/${dataReq?.status}/${prefix}?search=${dataReq.search}`, dataReq?.dateRange?.start, dataReq?.dateRange?.end, params?.limit, params?.page], () =>
        PrefixService.getTransactionList<T>(dataReq, params), { refreshInterval }
    )
    return {
        data: data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useGetTransactionList