import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Input, InputNumber, Row, Col, Checkbox, Upload, Divider, Space, Button, Select } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import TitleBar from '../../../components/Elements/TitleBar'
import PatternNumber from '../../../utils/PatternNumber'
import useFetchSetting from "../../../hooks/useFetchSetting"
import PrefixService from "../../../services/prefix"
import { settingPageConfig } from '../../../@types/prefix'
import { ApplicationState } from '../../../store'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
// import Swal from 'sweetalert2'

const Prefix = () => {

    const [form] = Form.useForm();
    const { Option } = Select
    const { data, mutate } = useFetchSetting()
    const { features } = useSelector((state: ApplicationState) => state.prefix)
    const namePrefix = PrefixService.getPrefix()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageLogoUrl, setImageLogoUrl] = useState<string>('')
    const [imageIconUrl, setImageIconUrl] = useState<string>('')
    const [loadingImageLogo, setLoadingImageLogo] = useState<boolean>(false)
    const [loadingImageIcon, setLoadingImageIcon] = useState<boolean>(false)
    const [showRankSetting, setShowRankSetting] = useState<boolean>(false)
    const [showPointSetting, setShowPointSetting] = useState<boolean>(false)
    // const { Panel } = Collapse;

    // const [textErrorSMLine, setTextErrorSMLine] = useState<string>('')
    // const [lineTokenFixTx, setlineTokenFixTx] = useState('')
    // const [checkPrefixUseHunterAdvance, setCheckPrefixUseHunterAdvance] = useState<boolean>(false)

    const updateData = async (values: settingPageConfig) => {
        try {
            const res = await PrefixService.updateSetting(values)
            // const dataNoti: settingPageNotiConfig = {
            //     enable: true,
            //     token: lineTokenFixTx
            // };
            // await PrefixService.updateNotiSetting(dataNoti)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                if (values?.socialMedia?.line) {
                    values.socialMedia.line = `line://ti/p/${values.socialMedia.line}`
                }
                // if (showRankSetting) {
                //     // values.rank.bronze = 0
                //     // values.rank.silver = 0
                //     // values.rank.gold = 0
                //     // values.rank.platinum = 0
                //     // values.rank.diamond = 0
                //     // values.rank.supreme = 0
                // }
                // values.rank = data?.setting.rank
                // values.pointCalcurate = data?.setting.pointCalcurate
                updateData(values);
            })
            .catch((errorInfo) => {
                console.log(errorInfo);

            });
    }

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadLogoButton = (
        <div>
            {loadingImageLogo ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}><FormattedMessage id="setting.prefix.upload-logo" defaultMessage="อัพโหลด Logo" /></div>
        </div>
    );
    const uploadIconButton = (
        <div>
            {loadingImageIcon ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}><FormattedMessage id="setting.prefix.upload-icon" defaultMessage="อัพโหลด Icon" /></div>
        </div>
    );

    const uploadImage = async (response: any, setting: string) => {
        setBtnLoading(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', setting);
        if (setting === "logo") {
            setLoadingImageLogo(true)
            try {
                const res = await PrefixService.uploadSettingImage(formData)
                toast.success(res.message)
                setLoadingImageLogo(false)
                setBtnLoading(false)
                getBase64(response.file, (imageUrl: any) =>
                    setImageLogoUrl(imageUrl)
                );
            } catch ({ message }) {
                toast.error(message as string)
            }
        } else if (setting === "icon") {
            setLoadingImageIcon(true)
            try {
                const res = await PrefixService.uploadSettingImage(formData)
                toast.success(res.message)
                setLoadingImageIcon(false)
                setBtnLoading(false)
                getBase64(response.file, (imageUrl: any) =>
                    setImageIconUrl(imageUrl)
                );
            } catch ({ message }) {
                toast.error(message as string)
            }
        }
    }
    // const namePefixAdvance: Array<string> = [
    //     "jktt",
    //     "ufa",
    //     "bf",
    //     "pgtt",
    //     "zone",
    //     "superbf",
    //     "lst",
    //     "jkk",
    //     "ambtest"
    // ]
    const handleRankSystem = () => {
        setShowRankSetting(!showRankSetting)
    }
    const handlePointSystem = () => {
        setShowPointSetting(!showPointSetting)
    }

    useEffect(() => {
        if (form && data) {
            form.resetFields();
            if (data.setting?.socialMedia?.line) {
                const re = "line://ti/p/"
                data.setting.socialMedia.line = data.setting.socialMedia.line.replace(re, "");
            }
            form.setFieldsValue(data?.setting)
            setShowRankSetting(data.setting.functions.rank || false)
            setShowPointSetting(data.setting.functions.point || false)
            setImageLogoUrl(data?.setting?.logo)
            setImageIconUrl(data?.setting?.icon)
            // const chkPrefix = namePefixAdvance.find((name: string) => name.toLocaleUpperCase() === namePrefix.toLocaleUpperCase()) ? true : false
            // setCheckPrefixUseHunterAdvance(chkPrefix)
        }
    }, [form, data])// eslint-disable-line


    const onValuesChange = (changedValues: any, allValues: any) => {
        if (Object.keys(changedValues)[0] === "functions") {
            const nameField = Object.keys(changedValues.functions)[0]
            if (nameField === "freespinHunter" || nameField === "freespinHunterAdvance") {
                if (nameField === "freespinHunter") {
                    if (allValues.functions.freespinHunter) {
                        allValues.functions.freespinHunterAdvance = false
                    }
                } else if (nameField === "freespinHunterAdvance") {
                    if (allValues.functions.freespinHunterAdvance) {
                        allValues.functions.freespinHunter = false
                    }
                }
                form.setFieldsValue(allValues)
            }
        }
        if (Object.keys(changedValues)[0] === "socialMedia") {
            if (Object.keys(changedValues.socialMedia)[0] === "line") {
                // const regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);// eslint-disable-line
                const regex = new RegExp(/(\b(https?|http|line):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);// eslint-disable-line
                const t = allValues.socialMedia.line;
                if (t.match(regex)) {
                    // console.log("Successful match");
                    allValues.socialMedia.line = ""
                    // setTextErrorSMLine("กรุณากรอกไอดี Line เช่น @yourname")
                } else {
                    // setTextErrorSMLine("")
                }
                form.setFieldsValue(allValues)
            }
        }

    }

    // const [loadingRank, setLoadingRank] = useState<boolean>(false)
    // const handleResetRank = () => {
    //     setLoadingRank(true)
    //     Swal.fire({
    //         title: `คุณต้องการปรับรีเซ็ตแรงค์ของสมาชิกใช่หรือไม่ ?`,
    //         text: `ตรวจสอบให้แน่ใจว่าคุณต้องการรีเซ็ตแรงค์ของสมาชิก หากคุณยืนยันการรีเซ็ตแรงค์ของสมาชิก แรงค์ของสมาชิกทั้งหมดจะถูกลบทิ้ง`,
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         cancelButtonText: 'ยกเลิก',
    //         confirmButtonText: 'ตกลง'
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             try {
    //                 const res = await PrefixService.resetRank()
    //                 setLoadingRank(false)
    //                 toast.success(res.message)
    //             } catch ({ message }) {
    //                 setLoadingRank(false)
    //                 toast.error(message as string)
    //             }
    //         } else {
    //             setLoadingRank(false)
    //         }
    //     })
    // }

    const textWebsiteName = FuncFormattedMessage("setting.prefix.input.website-name", "ชื่อเว็บไซต์")
    const textPlsWebsiteName = FuncFormattedMessage("setting.prefix.input.pls-website-name", "กรุณากรอกชื่อเว็บไซต์")
    const textGame = FuncFormattedMessage("setting.prefix.input.game", "เกม")
    const textPlsgame = FuncFormattedMessage("setting.prefix.input.pls-game", "เกม")
    const textPlsAgent = FuncFormattedMessage("setting.prefix.input.pls-agent", "กรุณากรอก Agent")
    const textTurn = FuncFormattedMessage("setting.prefix.input.turn", "เทิร์น")
    const textPlsTurn = FuncFormattedMessage("setting.prefix.input.pls-turn", "กรุณาเลือกเทิร์น")
    const textSelectTurn = FuncFormattedMessage("setting.prefix.input.select-turn", "เลือกเทิร์น")
    const textTurnOver = FuncFormattedMessage("setting.prefix.input.turn-over", "เทิร์นโอเวอร์")
    const textTurnMoney = FuncFormattedMessage("setting.prefix.input.turn-money", "เทิร์นเงิน")
    const textLinkGame = FuncFormattedMessage("setting.prefix.input.link-game", "ลิงก์เข้าเล่นเกม")
    const textLinkLineProblem = FuncFormattedMessage("setting.prefix.input.link-line-problem", "ลิงก์ไลน์ (แจ้งปัญหา)")
    const textLinkDownloadios = FuncFormattedMessage("setting.prefix.input.link-download-ios", "ลิงก์ดาวน์โหลด (iOS)")
    const textLinkDownloadandroid = FuncFormattedMessage("setting.prefix.input.link-download-android", "ลิงก์ดาวน์โหลด (Android)")
    const textLineToken_DepositWithdrawal = FuncFormattedMessage("setting.prefix.input.line-token-w-d", "Line Token (แจ้งเตือนรายการฝาก - ถอน)")
    const textLineTokenMistake = FuncFormattedMessage("setting.prefix.input.line-noti-mistake", "Line Token (แจ้งเตือนแก้ไขรายการผิดพลาด)")
    const textLinkFacebook = FuncFormattedMessage("setting.prefix.input.pls-link-facebook", "กรอกลิงก์ Facebook เช่น")
    const textLinkInstagram = FuncFormattedMessage("setting.prefix.input.pls-link-Instagram", "กรอกลิงก์ Instagram เช่น")
    const textLineID = FuncFormattedMessage("setting.prefix.input.pls-link-line", "กรอกไอดี Line เช่น")
    const textTelegram = FuncFormattedMessage("setting.prefix.input.pls-link-Telegram", "กรอกลิงก์ Telegram Group เช่น")
    const textLogoWebsite = FuncFormattedMessage("setting.prefix.input.logo-website", "โลโก้เว็บไซต์")
    const textIconWebsite = FuncFormattedMessage("setting.prefix.input.icon-website", "ไอคอนเว็บไซต์")
    const textMinimumBalanceCredit = FuncFormattedMessage("setting.prefix.input.minimum-balance-credit", "ยอดคงเหลือขั้นต่ำ (เติมเครดิต)")
    const textPlsMinimumBalanceCredit = FuncFormattedMessage("setting.prefix.input.pls-minimum-balance-credit", "กรุณากรอกยอดคงเหลือขั้นต่ำ (เติมเครดิต)")
    const textPlsEnterNumbersOnly = FuncFormattedMessage("setting.prefix.please-enter-numbers-only", "กรุณากรอกเป็นตัวเลขเท่านั้น")
    const textMinimumBalance = FuncFormattedMessage("setting.prefix.input.minimum-balance", "ยอดถอนขั้นต่ำ")
    const textPlsMinimumBalance = FuncFormattedMessage("setting.prefix.input.pls-minimum-balance", "กรุณากรอกยอดถอนขั้นต่ำ")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.prefix.setting-website" defaultMessage="ตั้งค่าเว็บไซต์" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="prefixForm"
                    layout="vertical"
                    hideRequiredMark
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label={textWebsiteName}
                                rules={[
                                    { required: true, message: textPlsWebsiteName },
                                ]}
                                hasFeedback
                            >
                                <Input placeholder={textWebsiteName} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="game"
                                label={textGame}
                                rules={[
                                    { required: true, message: textPlsgame },
                                ]}
                            >
                                <Input placeholder={textGame} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="agent"
                                label="Agent"
                                rules={[
                                    { required: true, message: textPlsAgent },
                                ]}
                            >
                                <Input placeholder="Agent" disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    {data?.isCasino &&
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="turnType"
                                    label={textTurn}
                                    rules={[
                                        { required: true, message: textPlsTurn },
                                    ]}
                                    hasFeedback
                                >
                                    <Select
                                        placeholder={textSelectTurn}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        <Option key={'turnover'} value={'turnover'}>{textTurnOver}</Option>
                                        <Option key={'turn'} value={'turn'}>{textTurnMoney}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="gameLink"
                                label={textLinkGame}
                            >
                                <Input placeholder={textLinkGame} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lineLink"
                                label={textLinkLineProblem}
                            >
                                <Input placeholder={textLinkLineProblem} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="linkDownloadIOS"
                                label={textLinkDownloadios}
                            >
                                <Input placeholder={textLinkDownloadios} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="linkDownloadAndroid"
                                label={textLinkDownloadandroid}
                            >
                                <Input placeholder={textLinkDownloadandroid} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {features.lineNotify &&
                        <>
                            <Divider />
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="lineToken"
                                        label={textLineToken_DepositWithdrawal}
                                    >
                                        <Input placeholder={textLineToken_DepositWithdrawal} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="lineTokenFixTx"
                                        label={textLineTokenMistake}
                                    >
                                        <Input placeholder={textLineTokenMistake} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                    <Divider />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['socialMedia', 'facebook']}
                                label="Social Media (Facebook)"
                                extra={textLinkFacebook + ` https://www.facebook.com/${namePrefix}`}
                            >
                                <Input placeholder="Social Media (Facebook)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['socialMedia', 'instagram']}
                                label="Social Media (Instagram)"
                                extra={textLinkInstagram + ` https://www.instagram.com/${namePrefix}`}
                            >
                                <Input placeholder="Social Media (Instagram)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['socialMedia', 'line']}
                                label="Social Media (Line ID)"
                                // validateStatus={"error"}
                                // help={textErrorSMLine}
                                extra={textLineID + ` @${namePrefix}`}
                            >
                                <Input placeholder="Social Media (Line ID)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['socialMedia', 'telegram']}
                                label="Social Media (Telegram)"
                                extra={textTelegram + ` https://t.me/${namePrefix}`}
                            >
                                <Input placeholder="Social Media (Telegram)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name={['socialMedia', 'phoneNumber']}
                                label="Social Media (Phone Number)"
                            >
                                <Input placeholder="Social Media (Phone Number)" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <label style={{ marginBottom: '16px' }}>{textLogoWebsite}</label>
                            <Upload
                                name="logo-image"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={(e) => uploadImage(e, 'logo')}
                            >
                                {imageLogoUrl ? <img src={imageLogoUrl} alt="Logo" style={{ width: '100%' }} /> : uploadLogoButton}
                            </Upload>
                        </Col>
                        <Col span={12}>
                            <label style={{ marginBottom: '16px' }}>{textIconWebsite}</label>
                            <Upload
                                name="icon-image"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={(e) => uploadImage(e, 'icon')}
                            >
                                {imageIconUrl ? <img src={imageIconUrl} alt="Icon" style={{ width: '100%' }} /> : uploadIconButton}
                            </Upload>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minCredit"
                                label={textMinimumBalanceCredit}
                                rules={[
                                    { required: true, message: textPlsMinimumBalanceCredit },
                                    { pattern: PatternNumber, message: textPlsEnterNumbersOnly }
                                ]}
                                hasFeedback
                            >
                                <InputNumber placeholder={textMinimumBalanceCredit} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="minWithdraw"
                                label={textMinimumBalance}
                                rules={[
                                    { required: true, message: textPlsMinimumBalance },
                                    { pattern: PatternNumber, message: textPlsEnterNumbersOnly }
                                ]}
                                hasFeedback
                            >
                                <InputNumber placeholder={textMinimumBalance} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    {/* {showPointSetting &&
                        <>
                            <Collapse ghost defaultActiveKey={['1']} className="me-3 mb-3">
                                <Panel header="ตั้งค่าสะสมแต้ม" key="1">
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="pointCalcurate"
                                                label="จำนวนหารยอดฝาก"
                                                rules={[
                                                    { required: showPointSetting, message: 'กรุณากรอกจำนวนหารยอดฝาก' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' },
                                                    { type: 'number', min: 1, max: 99, message: `กรุณากรอกตัวเลขตั้งแต่ 1 - 99 เท่านั้น` }
                                                ]}
                                                hasFeedback
                                                extra={`โดยระบบจะเอา ยอดฝาก หาร ตัวเลขที่กรอกด้านบน = คะแนนสะสม`}
                                            >
                                                <InputNumber placeholder="จำนวนหารยอดฝาก" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Divider />
                        </>
                    }
                    {showRankSetting &&
                        <>
                            <div className="row mb-md-3 align-items-md-center">
                                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                                    <Button type="primary" danger size="middle" loading={loadingRank} onClick={handleResetRank}>รีเซ็ตแรงค์สมาชิก</Button>
                                </div>
                            </div>
                            <Collapse ghost defaultActiveKey={['1']} className="me-3 mb-3">
                                <Panel header="ตั้งค่าแรงค์" key="1">
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "bronze"]}
                                                label="Bronze"
                                                rules={[
                                                    { required: false, message: 'กรุณากรอก Bronze' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Bronze" inputMode="numeric" controls={false} defaultValue="0" readOnly />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "silver"]}
                                                label="Silver"
                                                rules={[
                                                    { required: showRankSetting, message: 'กรุณากรอก Silver' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Silver" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "gold"]}
                                                label="Gold"
                                                rules={[
                                                    { required: showRankSetting, message: 'กรุณากรอก Gold' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Gold" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "platinum"]}
                                                label="Platinum"
                                                rules={[
                                                    { required: showRankSetting, message: 'กรุณากรอก Platinum' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Platinum" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "diamond"]}
                                                label="Diamond"
                                                rules={[
                                                    { required: showRankSetting, message: 'กรุณากรอก Diamond' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Diamond" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["rank", "supreme"]}
                                                label="Supreme"
                                                rules={[
                                                    { required: showRankSetting, message: 'กรุณากรอก Supreme' },
                                                    { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                                ]}
                                                hasFeedback
                                            >
                                                <InputNumber placeholder="Supreme" inputMode="numeric" controls={false} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Divider />
                        </>
                    } */}

                    <Row gutter={16}>
                        <Col span={24}>
                            <h6 className="my-3"><FormattedMessage id="setting.prefix.system-website" defaultMessage="ระบบเว็บไซต์" /></h6>
                            <Form.Item
                                name={['functions', 'depositToGame']}
                                key="depositToGame"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.run-deposit-game" defaultMessage="รันรายการฝากเข้าเกม" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'withdrawFromGame']}
                                key="withdrawFromGame"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.withdrawal-in-game" defaultMessage="ถอนเงินจากเกม" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'bonusHunterGuard']}
                                key="bonusHunterGuard"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.withdrawal-list-new-user" defaultMessage="ตรวจสอบรายการถอน (สมาชิกใหม่)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'freespinHunter']}
                                key="freespinHunter"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.check-turnover-deposit-list" defaultMessage="ตรวจสอบรายการฝากตัดยอดเทิร์น" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'freespinHunterAdvance']}
                                key="freespinHunterAdvance"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.check-turnover-deposit-list-hight" defaultMessage="ตรวจสอบรายการฝากตัดยอดเทิร์น(ขั้นสูง)" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'enableBonusFormCredit']}
                                key="enableBonusFormCredit"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.get-bonus-balance" defaultMessage="รับโบนัสจากยอดเงินคงเหลือ" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'scanqr']}
                                key="scanqr"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                    <FormattedMessage id="setting.prefix.bank-slip-verification" defaultMessage="ระบบตรวจสอบสลิปธนาคาร" />
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'rank']}
                                key="rank"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox onChange={handleRankSystem}>
                                    <FormattedMessage id="setting.prefix.rank-system-menu" defaultMessage="ระบบแรงค์" />
                                    {showRankSetting && <span className="text-danger  ms-3">*<FormattedMessage id="setting.prefix.des-rank-system-menu" defaultMessage="เมื่อเปิดใช้งานแล้วกรุณาตั้งค่าระบบแรงค์ที่เมนู ตั้งค่าฟีเจอร์เว็บไซต์" /></span>}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'point']}
                                key="point"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox onChange={handlePointSystem}>
                                    <FormattedMessage id="setting.prefix.redeeming-rewards" defaultMessage="ระบบสะสมแต้มแลกของรางวัล" />
                                    {showPointSetting && <span className="text-danger ms-3">*<FormattedMessage id="setting.prefix.des-redeeming-rewards" defaultMessage="เมื่อเปิดใช้งานแล้วกรุณาตั้งค่าระบบสะสมแต้มที่เมนู ตั้งค่าฟีเจอร์เว็บไซต์" /></span>}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name={['functions', 'WorkingTime']}
                                key="WorkingTime"
                                valuePropName="checked"
                                className="mb-0">
                                <Checkbox>
                                <FormattedMessage id="setting.prefix.limitation-system" defaultMessage="ระบบจำกัดเวลาเข้างานของผู้ใช้งาน" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.prefix.save" defaultMessage="บันทึก" /></Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default Prefix