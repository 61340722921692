import React, { FC } from 'react'
import BadgeComponent from '../../components/Elements/Badge'
import { FixTransaction } from '../../@types/transactions'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'

interface FixTxBadgeProps {
    tx: FixTransaction
}

const FixTxBadge: FC<FixTxBadgeProps> = ({ tx }) => {
    if (tx.type === 'deduct') {
        const text_FixTxBadge = FuncFormattedMessage("fixTx.fixTxBadge.deduct", "ตัดเครดิต")
        return <BadgeComponent className="badge-danger" text={text_FixTxBadge} />
    }
    if (tx.type === 'add') {
        const text_FixTxBadge = FuncFormattedMessage("fixTx.fixTxBadge.add", "เติมเครดิต")
        return <BadgeComponent className="badge-success" text={text_FixTxBadge} />
    }
    if (tx.type === 'deposit' && tx.transferFrom === 'TRUEWALLET') {
        const text_FixTxBadge = FuncFormattedMessage("fixTx.fixTxBadge.truewallet", "ทรูมันนี่วอลเล็ทไม่รัน")
        return <BadgeComponent className="badge-warning" text={text_FixTxBadge} />
    }
    const text_FixTxBadge = FuncFormattedMessage("fixTx.fixTxBadge.bank", "ธนาคารไม่รัน")
    return <BadgeComponent className="badge-info" text={text_FixTxBadge} />
}

export default FixTxBadge