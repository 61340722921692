import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import './app.scss';
import "./assets/fontawesome-pro/css/all.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
const stylesheets = {
    light: `css/light-theme.css`, //${process.env.REACT_APP_PUBLIC_URL}/
    dark: `css/dark-theme.css`
}
ReactDOM.render(
    <Provider store={store}>
        <ThemeSwitcherProvider themeMap={stylesheets} defaultTheme={localStorage.getItem("theme") || "light"}>
            <App />
        </ThemeSwitcherProvider>
        <ToastContainer theme="colored" />
    </Provider>
    , document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
