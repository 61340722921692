import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { Row, Col, message } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import useDateRange from "../../../hooks/useDateRange"
import Chart from 'react-apexcharts'
import AmountCard from '../../../components/Elements/AmountCard'
// import { toast } from 'react-toastify';
import { OverviewSummary } from '../../../@types/report'
import useFetchReport from '../../../hooks/useFetchReport'
import { calculateNewProfit, depositAndbonus } from '../../../utils/summary'
import Loading from '../../../components/Elements/Loading'
import moment from 'moment'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../../components/Elements/FormattedMessage'

const ReportSummary = () => {
    const { dateRange, setDateRange } = useDateRange()
    const [dataChart, setDataChart] = useState<any>([])
    const [dataSource, setDataSource] = useState<any>([])
    const [dataProfit, setDataProfit] = useState<number>(0)
    const [dataWithdrawTotal, setDataWithdrawTotal] = useState<any>([])

    const { data, mutate, isLoading } = useFetchReport<OverviewSummary>('overview', dateRange, 10000)

    const text_deposit_amount = FuncFormattedMessage("reports.summary.deposit_amount", "ยอดฝาก")
    const text_deposit_bonus_amount = FuncFormattedMessage("reports.summary.deposit_bonus_amount", "ยอดฝาก + โบนัส")
    const text_winhdrawal_success = FuncFormattedMessage("reports.summary.winhdrawal_success", "ยอดถอน (สำเร็จ)")
    const text_winhdrawal_failed = FuncFormattedMessage("reports.summary.winhdrawal_failed", "ยอดถอน (ไม่สำเร็จ)")
    const totalProfitChart = {
        options: {
            series: dataChart,
            labels: [text_deposit_amount, text_deposit_bonus_amount, text_winhdrawal_success, text_winhdrawal_failed],
            colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545'],
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false
            },
            fill: {
                colors: ['#17c5a2', '#5952c0', '#fd7e14', '#dc3545']
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2)
                    },
                }
            }
        },
    };

    useEffect(() => {
        setDataChart([])
        setDataSource([])
        if (data !== undefined) {
            if (Object.keys(data).length > 0) {
                const dataSetChart: any = [
                    data.depositTotal,
                    depositAndbonus(data),
                    data.withdrawTotal.success,
                    data.withdrawTotal.reject
                ];
                setDataChart(dataSetChart)
                setDataProfit(calculateNewProfit(data))
                setDataWithdrawTotal(data.withdrawTotal)
                setDataSource(data)
            }
        }
    }, [data, setDataChart, setDataSource, setDataProfit, setDataWithdrawTotal]) // eslint-disable-line

    useMemo(() => {
        mutate()
    }, [setDateRange])// eslint-disable-line

    const text_bath = FuncFormattedMessage("baht", "บาท")
    const text_account = FuncFormattedMessage("account", "บัญชี")
    const profitTotal = NumberWithCommas(dataProfit, 2)
    const depositTotal = NumberWithCommas(dataSource.depositTotal, 2)
    const depositbonus = NumberWithCommas(depositAndbonus(dataSource), 2)
    const dataWithdrawTotalSuccess = NumberWithCommas(dataWithdrawTotal.success, 2)
    const dataWithdrawTotalReject = NumberWithCommas(dataWithdrawTotal.reject, 2)

    const memberCount = `${NumberWithCommas(dataSource.memberCount)} ${text_account}`
    const memberFirstDepositCount = `${NumberWithCommas(dataSource.memberFirstDepositCount)} ${text_account}`
    const promotionTotal = NumberWithCommas(dataSource.promotionTotal, 2)
    const affiliateTotal = NumberWithCommas(dataSource.affiliateTotal, 2)
    const cashbackTotal = NumberWithCommas(dataSource.cashbackTotal, 2)
    const wheelTotal = NumberWithCommas(dataSource.wheelTotal, 2)


    const text_summary = FuncFormattedMessage("reports.summary.summary", "สรุปข้อมูล")
    const text_deposit_summary = FuncFormattedMessage("reports.summary.deposit_summary", "สรุปยอดฝาก")
    const text_summary_withdrawals_successful = FuncFormattedMessage("reports.summary.summary_withdrawals_successful", "สรุปยอดถอน (สำเร็จ)")
    const text_summary_withdrawals_cancel = FuncFormattedMessage("reports.summary.summary_withdrawals_cancel", "สรุปยอดถอน (ยกเลิกรายการ)")
    const text_profit_summary = FuncFormattedMessage("reports.summary.profit_summary", "สรุปกำไร (ยอดฝาก - ยอดถอน)")
    const text_bonus_summary = FuncFormattedMessage("reports.summary.bonus_summary", "สรุปยอดแนะนำเพื่อน")
    const text_refer_friend_summary = FuncFormattedMessage("reports.summary.refer_friend_summary", "สรุปยอดแนะนำเพื่อน")
    const text_cashback_summary = FuncFormattedMessage("reports.summary.cashback_summary", "สรุปยอดแคชแบ็ก")
    const text_summary_wheel_fortune = FuncFormattedMessage("reports.summary.summary_wheel_fortune", "สรุปยอดกงล้อเสี่ยงโชค")
    const text_open_new_user = FuncFormattedMessage("reports.summary.open_new_user", "เปิดยูสเซอร์ใหม่")
    const text_open_new_user_first_deposit = FuncFormattedMessage("reports.summary.open_new_user_first_deposit", "เปิดยูสเซอร์ใหม่ (ฝากครั้งแรก)")
    const text_first_deposit = FuncFormattedMessage("reports.summary.first_deposit", "ยอดฝากครั้งแรก")
    const text_bonus_amount = FuncFormattedMessage("reports.summary.bonus_amount", "ยอดรับโบนัส")
    const text_friend_recommendation = FuncFormattedMessage("reports.summary.friend_recommendation", "ยอดแนะนำเพื่อน")
    const text_cashback = FuncFormattedMessage("reports.summary.cashback", "ยอดรับแคชแบ็ก")
    const text_spin_the_wheel = FuncFormattedMessage("reports.summary.spin_the_wheel", "ยอดหมุนกงล้อ")
    const copyData = () => {
        navigator.clipboard.writeText(`${text_summary} (${moment(dateRange.start).format('DD/MM/YYYY')} - ${moment(dateRange.end).format('DD/MM/YYYY')})
${text_deposit_summary} : ${depositTotal} ${text_bath}
${text_summary_withdrawals_successful} : ${dataWithdrawTotalSuccess} ${text_bath}
${text_summary_withdrawals_cancel} : ${dataWithdrawTotalReject} ${text_bath}
${text_profit_summary} : ${profitTotal} ${text_bath}
${text_bonus_summary} : ${promotionTotal} ${text_bath}
${text_refer_friend_summary} : ${affiliateTotal} ${text_bath}
${text_cashback_summary} : ${cashbackTotal} ${text_bath}
${text_summary_wheel_fortune} : ${wheelTotal} ${text_bath}
${text_open_new_user} : ${memberCount}
${text_open_new_user_first_deposit} : ${memberFirstDepositCount}`)
        // toast.success('คัดลอกช้อมูลสำเร็จ')
        message.success('Successfully copied data');
    }

    return (
        <Fragment>
            <TitleBar title={text_summary} />

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <Row gutter={16}>
                <Col sm={24} lg={12}>
                    <div className="box-white ant-box-table p-3 p-lg-4 mb-2 mb-lg-0">
                        <Row>
                            <Col xs={24} sm={12} lg={12}>
                                {isLoading ? <Loading /> :
                                    <>
                                        <div className="pie-chart-wrapper">
                                            <Chart
                                                type="donut"
                                                options={totalProfitChart.options as object}
                                                series={totalProfitChart.options.series}
                                            />
                                            <i className="fa-duotone fa-chart-line"></i>
                                        </div>
                                        <div className="drawer-actions">
                                            <p className="m-0"><FormattedMessage id="reports.summary.total_profit" defaultMessage="กำไรทั้งหมด" /></p>
                                            <h5 className="row-amount"><span>฿</span> {profitTotal}</h5>
                                            <button className='btn btn-primary btn-sm' onClick={copyData}><FormattedMessage id="copy_data" defaultMessage="คัดลอกข้อมูล" /></button>
                                        </div>
                                    </>
                                }
                            </Col>
                            <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                                <h5><FormattedMessage id="reports.summary.summarize_all" defaultMessage="สรุปยอดทั้งหมด" /></h5>
                                <AmountCard
                                    icon='fa-solid fa-plus-circle'
                                    className='no-box'
                                    theme='success'
                                    amount={depositTotal}
                                    amountType={text_deposit_amount}
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-plus-circle'
                                    className='no-box'
                                    theme='info'
                                    amount={depositbonus}
                                    amountType={text_deposit_bonus_amount}
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-minus-circle'
                                    className='no-box'
                                    theme='warning'
                                    amount={dataWithdrawTotalSuccess}
                                    amountType={text_winhdrawal_success}
                                    loading={isLoading}
                                />
                                <AmountCard
                                    icon='fa-solid fa-minus-circle'
                                    className='no-box mb-0'
                                    theme='danger'
                                    amount={dataWithdrawTotalReject}
                                    amountType={text_winhdrawal_failed}
                                    loading={isLoading}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm={24} lg={12} className="mt-3 mt-xl-0">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-user-plus'
                                theme='success'
                                amount=''
                                number={memberCount}
                                amountType={text_open_new_user}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-user-check'
                                theme='primary'
                                amount=''
                                number={memberFirstDepositCount}
                                amountType={text_first_deposit}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-gift'
                                theme='third'
                                amount={promotionTotal}
                                amountType={text_bonus_amount}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-users'
                                theme='info'
                                amount={affiliateTotal}
                                amountType={text_friend_recommendation}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-redo'
                                theme='warning'
                                amount={cashbackTotal}
                                amountType={text_cashback}
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <AmountCard
                                icon='fa-solid fa-dharmachakra'
                                theme='danger'
                                amount={wheelTotal}
                                amountType={text_spin_the_wheel}
                                loading={isLoading}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ReportSummary