import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/dashboard'

const useFetchTransactionSummary = (dateRange: DateRangeType, refreshInterval: number): any => {
    const prefix = PrefixService.getPrefix()
    const { data, mutate, error } = useSWR([`/bank-transactions/summary/${prefix}`, dateRange?.start, dateRange?.end], () =>
        PrefixService.getTransactionSummary({ dateRange: dateRange }), { refreshInterval }
    )
    return {
        data: data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchTransactionSummary