import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Checkbox, Row, Col, Divider, Space, Button } from 'antd'
// import { Navigate } from 'react-router-dom'
import TitleBar from '../../../components/Elements/TitleBar'
import { ambGamesConfigEdit } from '../../../@types/prefix'
import useFetchSetting from "../../../hooks/useFetchSetting"
import PrefixService from "../../../services/prefix"
// import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { AMBKingGamesType, AMBKingGamesTypeEN } from '../../../utils/ambking'
import i18n from '../../../utils/i18n'

const AMBKingGames = () => {

    const [form] = Form.useForm();
    const { data, mutate } = useFetchSetting()

    const langDefalut = localStorage.getItem('i18nextLng') || 'th'
    const [ambKingGameList, setAmbKingGameList] = useState<any>(langDefalut === "th" ? AMBKingGamesType : AMBKingGamesTypeEN)
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: ambGamesConfigEdit) => {
        try {
            const res = await PrefixService.updateAMBKingGames(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const filteredData = Object.entries(values).reduce((acc: any, [key, value]: any) => {
                    if (value.enable === undefined) {
                        value.enable = false;
                    }
                    acc[key] = value;
                    value.game = key;
                    value.label = AMBKingGamesType[key];
                    return acc;
                }, {});
                const dataUpdate = {
                    games: Object.values(filteredData).map((item: any) => ({
                        game: item.game,
                        enable: item.enable,
                        label: item.label
                    }))
                }
                updateData(dataUpdate);
            })
            .catch((errorInfo) => {

            });
    }
    const funcAMBGameList = useCallback(() => {
        const lang = localStorage.getItem('i18nextLng') || 'th'
        setAmbKingGameList(AMBKingGamesType)
        if (lang === 'en') {
            setAmbKingGameList(AMBKingGamesTypeEN)
        }
    }, []);

    useEffect(() => {
        i18n.on('languageChanged', funcAMBGameList);
        return () => {
            i18n.off('languageChanged', funcAMBGameList);
        };
    }, [funcAMBGameList]);

    useEffect(() => {
        if (form && data && data?.setting.ambkingGames !== null) {
            form.resetFields();
            const transformedData = data?.setting.ambkingGames.reduce((result: any, game: any) => {
                result[game.game] = {
                    enable: game.enable
                };
                return result;
            }, {});
            form.setFieldsValue(transformedData)
        }
    }, [form, data])// eslint-disable-line


    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.ambking-game.setting-ambking-game" defaultMessage="ตั้งค่าเกม AMBKing" />} />
            {ambKingGameList &&
                <div className="box-white ant-box-card">
                    <Form
                        form={form}
                        name="ambKingGamesForm"
                        layout="vertical"
                        hideRequiredMark
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <h6 className="my-3"><FormattedMessage id="setting.ambking-game.open-game" defaultMessage="เปิดใช้งานเกม" /></h6>
                                {Object.entries(ambKingGameList).map((item: any, index) => (
                                    <Space key={`${item}-main`} size="middle" style={{ display: 'flex' }}>
                                        <Form.Item
                                            name={[item[0], "enable"]}
                                            key={`${item[0]}-enable`}
                                            valuePropName="checked">
                                            <Checkbox>
                                                {item[1]}
                                            </Checkbox>
                                        </Form.Item>
                                    </Space>
                                ))}
                            </Col>
                        </Row>
                        <Divider />
                        <div className="drawer-actions">
                            <Space>
                                <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.amb-game.save" defaultMessage="บันทึก" /> </Button>
                            </Space>
                        </div>
                    </Form>
                </div>
            }
        </Fragment>
    )
}

export default AMBKingGames