import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Space,
    Divider,
    Upload
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
// import { PromotionConfigAdd } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'

interface AddPopupProps {
    close: () => void
    isOpen: boolean
}

const AddPopup: FC<AddPopupProps> = ({ close, isOpen }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("popup.add", "เพิ่มป๊อปอัพ")
    // const [btnLoading, setBtnLoading] = useState(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [imageUrl, setImageUrl] = useState<string>('')
    const [loadingImage, setLoadingImage] = useState<boolean>(false)

    // const insertData = async (values: PromotionConfigAdd) => {
    //     // try {
    //     //     const res = await PrefixService.addPromotionConfig(values)
    //     //     setBtnLoading(false)
    //     //     dispatch(requestAuthInit(false))
    //     //     toast.success(res.message)
    //     //     close()
    //     // } catch ({ message }) {
    //     //     toast.error(message as string)
    //     //     setBtnLoading(false)
    //     // }
    // }

    // const handleFormSubmit = () => {
    //     form.validateFields()
    //         .then((values: PromotionConfigAdd) => {
    //             setBtnLoading(true)
    //             insertData(values)
    //         })
    //         .catch((errorInfo) => { });
    // };


    useEffect(() => {
        if (form && isOpen) {
            form.resetFields();
            setImageUrl("")
        }
    }, [form, isOpen]);

    const uploadLogoButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}><FormattedMessage id="popup.upload-image" defaultMessage="อัพโหลดรูปภาพ" /></div>
        </div>
    );

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadImage = async (response: any, setting: string) => {
        // setBtnLoading(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', setting);
        setLoadingImage(true)
        try {
            const res = await PrefixService.uploadSettingImage(formData)
            toast.success(res.message)
            setLoadingImage(false)
            // setBtnLoading(false)
            getBase64(response.file, (imageUrl: any) =>
                setImageUrl(imageUrl)
            );
            dispatch(requestAuthInit(false))
            close()
        } catch ({ message }) {
            setLoadingImage(false)
            // setBtnLoading(false)
            toast.error(message as string)
        }
    }

    const textUploadPic = FuncFormattedMessage("popup.image", "รูปภาพ")

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            // extra={
            //     <Space>
            //         <Button
            //             onClick={handleFormSubmit}
            //             type="primary"
            //             loading={btnLoading}
            //             size="large">
            //             <i className="fa-solid fa-save me-2"></i>
            //             <FormattedMessage id="save" defaultMessage="บันทึก" />
            //         </Button>
            //     </Space>
            // }
            >
                <Form
                    form={form}
                    name="addPopup"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{ marginBottom: '16px' }}>{textUploadPic}</label>
                            <Upload
                                name="popup-image"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={(e) => uploadImage(e, 'memberPopup')}
                            >
                                {imageUrl ? <img src={imageUrl} alt="popup" style={{ width: '100%' }} /> : uploadLogoButton}
                            </Upload>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            {/* <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button> */}
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default AddPopup

