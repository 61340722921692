import useSWR from 'swr'
import { PaginationParams } from '../@types/api';
import PrefixService from '../services/prefix'

export const useFetchPromotionCode = (search?: string, params?: PaginationParams) => {
    const prefix = PrefixService.getPrefix()
    const { data, mutate, error } = useSWR([`/setting/website/redeem?search=${search}`, prefix, params?.limit, params?.page], () => PrefixService.getRedeem(search, params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchPromotionCode
