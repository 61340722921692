import useSWR from 'swr';
import PrefixService from '../services/prefix'

export const useFetchUsersList = (): any => {
	const prefix = PrefixService.getPrefix()
	const { data, mutate, error } = useSWR([`/users`, prefix], () => PrefixService.getUsers());

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchUsersList
