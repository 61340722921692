import { RewardType } from '../@types/prefix'

export const getRewardItems = (t: string): Record<string, RewardType> => {
    switch (t) {
        case 'type1': {
            return {
                '50b': { name: '50 บาท', amount: 50, index: 1, chance: 0 },
                '100b': { name: '100 บาท', amount: 100, index: 2, chance: 0 },
                '150b': { name: '150 บาท', amount: 150, index: 3, chance: 0 },
                '200b': { name: '200 บาท', amount: 200, index: 4, chance: 0 },
                '250b': { name: '250 บาท', amount: 250, index: 5, chance: 0 },
                '300b': { name: '300 บาท', amount: 300, index: 6, chance: 0 },
                '500b': { name: '500 บาท', amount: 500, index: 7, chance: 0 },
                '1000b': {
                    name: '1,000 บาท',
                    amount: 1000,
                    index: 8,
                    chance: 0,
                },
                '2000b': {
                    name: '2,000 บาท',
                    amount: 2000,
                    index: 9,
                    chance: 0,
                },
                'gold': { name: 'ทอง 1 บาท', amount: 0, index: 10, chance: 0 },
            }
        }
        case 'type2': {
            return {
                '5b': { name: '5 บาท', amount: 5, index: 1, chance: 0 },
                '10b': { name: '10 บาท', amount: 10, index: 2, chance: 0 },
                '15b': { name: '15 บาท', amount: 15, index: 3, chance: 0 },
                '20b': { name: '20 บาท', amount: 20, index: 4, chance: 0 },
                '25b': { name: '25 บาท', amount: 25, index: 5, chance: 0 },
                '50b': { name: '50 บาท', amount: 50, index: 6, chance: 0 },
                '100b': { name: '100 บาท', amount: 100, index: 7, chance: 0 },
                '200b': { name: '200 บาท', amount: 200, index: 8, chance: 0 },
                '300b': { name: '300 บาท', amount: 300, index: 9, chance: 0 },
                'gold': { name: 'ทอง 1 บาท', amount: 0, index: 10, chance: 0 },
            }
        }
        default: {
            return {}
        }
    }
}
