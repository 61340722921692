import React, { FC, ReactNode } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface loadingProps {
    tip?: string
    children?: string | ReactNode
    spinning?: boolean
}
const Loading: FC<loadingProps> = ({ tip, children, spinning = false }) => {
    return (
        <>
            {children ?
                <Spin size="large" indicator={<LoadingOutlined />} tip={tip ? tip : "กำลังโหลด..."} spinning={spinning}>
                    {children}
                </Spin>
                : <div className="loading"><Spin size="large" indicator={<LoadingOutlined style={{ color: '#FFC602' }} />} tip={tip ? tip : "กำลังโหลด..."} /></div>}
        </>
    );
}

export default Loading