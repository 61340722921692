import React, { FC, useEffect, useState } from 'react'
import {
    Form,
    Col,
    Row,
    Checkbox,
    DatePicker
} from 'antd'
import FormattedMessage from '../../Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

interface NoDepositBetweenProps {
    data: any
}

const NoDepositBetween: FC<NoDepositBetweenProps> = ({ data }) => {
    const [noDepositBetweenChecked, setNoDepositBetweenChecked] = useState<boolean>(data?.noDepositBetween?.enable)
    const dateFormat = 'DD/MM/YYYY'

    useEffect(() => {
        setNoDepositBetweenChecked(data?.noDepositBetween?.enable)
    }, [data]) // eslint-disable-line

    const text_sdate = FuncFormattedMessage("start_date", "วันที่เริ่มต้น")
    const text_edate = FuncFormattedMessage("end_date", "วันที่สิ้นสุด")

    return (
        <div className="checkbox-collapse mb-4">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name={['noDepositBetween', 'enable']}
                        valuePropName="checked"
                        className="mb-0"
                    >
                        <Checkbox onClick={(e: any) => setNoDepositBetweenChecked(e.target.checked)}>
                            <FormattedMessage id="bonus.input.no_deposit" defaultMessage="ไม่มียอดฝาก" />
                        </Checkbox>
                    </Form.Item>
                    {noDepositBetweenChecked &&
                        <>
                            <Row gutter={16} className="mt-2">
                                <Col span={12}>
                                    <Form.Item
                                        name={['noDepositBetween', 'startDate']}
                                        key="startDate"
                                        label={text_sdate}
                                        rules={[{ required: true, message: <FormattedMessage id="please_start_date" defaultMessage="กรุณากรอกวันที่เริ่มต้น" /> }]}
                                        hasFeedback>
                                        <DatePicker placeholder={text_sdate} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={['noDepositBetween', 'endDate']}
                                        key="endDate"
                                        label={text_edate}
                                        rules={[{ required: true, message: <FormattedMessage id="please_end_date" defaultMessage="กรุณากรอกวันที่สิ้นสุด" /> }]}
                                        hasFeedback>
                                        <DatePicker placeholder={text_edate} format={dateFormat} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noWithdraw']}
                                        key="noWithdraw"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Checkbox>
                                            <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noWithdraw" defaultMessage="ไม่มีรายการถอน" />
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noBonus']}
                                        key="noBonus"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Checkbox>
                                            <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noBonus" defaultMessage="ไม่มีรายการรับโบนัส" />
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noCashback']}
                                        key="noCashback"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Checkbox>
                                            <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noCashback" defaultMessage="ไม่มีรายการรับแคชแบ็ก" />
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name={['noDepositBetween', 'rules', 'noLuckyWheel']}
                                        key="noLuckyWheel"
                                        valuePropName="checked"
                                        className="mb-0"
                                    >
                                        <Checkbox>
                                            <FormattedMessage id="bonus.input.consecutiveDepositBonus_rules.noLuckyWheel" defaultMessage="ไม่มีรายการหมุนกงล้อเสี่ยงโชค" />
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </div>
    )
}
export default NoDepositBetween

