import React, { FC, Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Row, Col, Divider, Space, Button, InputNumber, Typography } from 'antd'
import useFetchSetting from "../../../../hooks/useFetchSetting"
import PrefixService from "../../../../services/prefix"
import PatternNumber from '../../../../utils/PatternNumber'
import Swal from 'sweetalert2'
import { RankSetting } from '../../../../@types/prefix'
import FormattedMessage from '../../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../../utils/FuncFormattedMessage'

interface RankingProps {
    setActiveKey: (key: string) => void
    setIsUpdate: (isUpdate: boolean) => void
}

const Ranking: FC<RankingProps> = ({ setActiveKey, setIsUpdate }) => {

    const [form] = Form.useForm();
    const { data: dataSetting, mutate } = useFetchSetting()

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: RankSetting) => {
        try {
            const res = await PrefixService.updateRank(values)
            setBtnLoading(false)
            toast.success(res.message)
            setActiveKey("1")
            setIsUpdate(true)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                values.rank.bronze = 0
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }

    const textConfrimRank = FuncFormattedMessage("setting.features.confrim-rank", "คุณต้องการปรับรีเซ็ตแรงค์ของสมาชิกใช่หรือไม่")
    const textConfrimText = FuncFormattedMessage("setting.features.confrim-text", "ตรวจสอบให้แน่ใจว่าคุณต้องการรีเซ็ตแรงค์ของสมาชิก หากคุณยืนยันการรีเซ็ตแรงค์ของสมาชิก แรงค์ของสมาชิกทั้งหมดจะถูกลบทิ้ง")
    const textCancel = FuncFormattedMessage("setting.features.cancel", "ยกเลิก")
    const textConfrim = FuncFormattedMessage("setting.features.confrim-submit", "ตกลง")

    const [loadingRank, setLoadingRank] = useState<boolean>(false)
    const handleResetRank = () => {


        setLoadingRank(true)
        Swal.fire({
            title: textConfrimRank,
            text: textConfrimText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: textCancel,
            confirmButtonText: textConfrim
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.resetRank()
                    setLoadingRank(false)
                    toast.success(res.message)
                } catch ({ message }) {
                    setLoadingRank(false)
                    toast.error(message as string)
                }
            } else {
                setLoadingRank(false)
            }
        })
    }


    useEffect(() => {
        if (form && dataSetting) {
            form.resetFields();
            form.setFieldsValue({ rank: dataSetting?.setting.rank })
        }
    }, [form, dataSetting])// eslint-disable-line

    const textPlsBronze = FuncFormattedMessage("setting.features.input.pls-Bronze", "กรุณากรอก Bronze")
    const textPlsSilver = FuncFormattedMessage("setting.features.input.pls-Silver", "กรุณากรอก Silver")
    const textPlsGold = FuncFormattedMessage("setting.features.input.pls-Gold", "กรุณากรอก Gold")
    const textPlsPlatinum = FuncFormattedMessage("setting.features.input.pls-Platinum", "กรุณากรอก Platinum")
    const textPlsDiamond = FuncFormattedMessage("setting.features.input.pls-Diamond", "กรุณากรอก Diamond")
    const textPlsSupreme = FuncFormattedMessage("setting.features.input.pls-Supreme", "กรุณากรอก Supreme")
    const textPlsnumbersOnly = FuncFormattedMessage("setting.features.input.pls-enter-numbers-only", "กรุณากรอกเป็นตัวเลขเท่านั้น")

    return (
        <Fragment>
            <Form
                form={form}
                name="featuresForm"
                layout="vertical"
                hideRequiredMark>

                <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                    <Typography.Title level={3}><FormattedMessage id="setting.features.setting-rank" defaultMessage="ตั้งค่าแรงค์" /></Typography.Title>
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                            <Button type="primary" danger size="middle" loading={loadingRank} onClick={handleResetRank}><FormattedMessage id="setting.features.reset-rank" defaultMessage="รีเซ็ตแรงค์สมาชิก" /></Button>
                        </div>
                    </div>
                </div>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "bronze"]}
                            label="Bronze"
                            rules={[
                                { required: false, message: textPlsBronze },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Bronze" inputMode="numeric" controls={false} defaultValue="0" readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "silver"]}
                            label="Silver"
                            rules={[
                                { required: true, message: textPlsSilver },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Silver" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "gold"]}
                            label="Gold"
                            rules={[
                                { required: true, message: textPlsGold },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Gold" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "platinum"]}
                            label="Platinum"
                            rules={[
                                { required: true, message: textPlsPlatinum },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Platinum" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "diamond"]}
                            label="Diamond"
                            rules={[
                                { required: true, message: textPlsDiamond },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Diamond" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={["rank", "supreme"]}
                            label="Supreme"
                            rules={[
                                { required: true, message: textPlsSupreme },
                                { pattern: PatternNumber, message: textPlsnumbersOnly }
                            ]}
                            hasFeedback
                        >
                            <InputNumber placeholder="Supreme" inputMode="numeric" controls={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <div className="drawer-actions">
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.features.save" defaultMessage="บันทึก" /> </Button>
                    </Space>
                </div>
            </Form>
        </Fragment>
    )
}

export default Ranking