import React, { Fragment, useState, useEffect } from 'react'
import { Space, Switch, Avatar } from 'antd'
// import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { getBankIcon } from '../../../utils/bank'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import { BankConfig } from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import SearchData from '../../Elements/Search'
import TableComponent from '../../Elements/Table'
import EditTruewallet from './Edit'
import AddTruewallet from './Add'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'


const ListBank = () => {
    const { prefix, features } = useSelector((state: ApplicationState) => state.prefix)
    const [editTruewallet, setEditTruewallet] = useState<boolean>(false)
    const [addTrueWallet, setAddTrueWallet] = useState<boolean>(false)
    const toggleEditTruewallet = () => setEditTruewallet(!editTruewallet)
    const toggleAddTrueWallet = () => setAddTrueWallet(!addTrueWallet)
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    const [switchHideLoading, setSwitchHideLoading] = useState<boolean>(false)

    const [truewalletData, setTruewalletData] = useState<any>()
    const handleTruewallet = (object: any) => setTruewalletData(object)

    const [banks, setBanks] = useState<any>([])
    const [filterText, setFilterText] = useState('')

    const dispatch: ThunkDispatch<AnyAction> = useDispatch()


    const text_confirm = FuncFormattedMessage("confirm", "ตกลง")
    const text_cancel = FuncFormattedMessage("cancel", "ยกเลิก")

    const textHandleDelete = {
        title: FuncFormattedMessage("handleDelete.title", "แจ้งเตือน!"),
        text: FuncFormattedMessage("handleDelete.text", "คุณต้องการลบรายการนี้ใช่หรือไม่")
    }
    const handleDelete = (accountNumber: string) => {
        Swal.fire({
            title: `${textHandleDelete.title}`,
            text: `${textHandleDelete.text}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: text_cancel,
            confirmButtonText: text_confirm,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await PrefixService.deleteBankAccount(accountNumber)
                    toast.success(res.message)
                    dispatch(requestAuthInit(false))
                } catch ({ message }) {
                    toast.error(message as string)
                }
            }
        })
    }

    const handleStatusMulti = async (status: boolean, bank: BankConfig, type: string) => {
        try {
            const res = await PrefixService.toggleBank(bank.accountNumber, type)
            toast.success(res.message)
            dispatch(requestAuthInit(false))
        } catch ({ message }) {
            toast.error(message as string)
        }
        setSwitchHideLoading(false)
        setSwitchLoading(false)
    }

    const columns: any = [
        {
            title: <FormattedMessage id="truemoneywallet.columns.tw_account" defaultMessage="บัญชีทรูมันนี่วอลเล็ท" />,
            dataIndex: 'bank',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Space>
                            {getBankIcon(record.bank) && (<Avatar src={getBankIcon(record.bank)} />)}
                            <Space direction="vertical" >
                                <div className="row-member">
                                    <Space size={1} >
                                        <div className="me-1">{record.accountNumber}</div>
                                    </Space>
                                    <div className="row-member">
                                        <span>{record.accountName}</span>
                                    </div>
                                </div>
                            </Space>
                        </Space>
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id="status" defaultMessage="สถานะ" />,
            dataIndex: 'status',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Switch
                            checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                            unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                            checked={record.enable}
                            loading={switchLoading}
                            onChange={(e) => {
                                setSwitchLoading(true)
                                // handleStatus(e, record)
                                handleStatusMulti(e, record, "Enable")
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: <FormattedMessage id="truemoneywallet.columns.hide" defaultMessage="ซ่อนจากเว็บไซต์" />,
            dataIndex: 'hide',
            render: (text: any, record: any) => {
                return (
                    <>
                        <Switch
                            checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                            unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                            checked={record.hide}
                            loading={switchHideLoading}
                            onChange={(e) => {
                                setSwitchHideLoading(true)
                                // handleStatusHide(e, record)
                                handleStatusMulti(e, record, "Hide")
                            }}
                        />
                    </>
                )
            }
        },
        // {
        //     title: <FormattedMessage id="createdAt2" defaultMessage="วันที่สร้าง" />,
        //     dataIndex: 'createdAt',
        //     width: '180px',
        //     render: (text: any, record: any) => (
        //         <div className="row-datetime">
        //             <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
        //             <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
        //         </div>
        //     )
        // },
        {
            title: <FormattedMessage id="action" defaultMessage="จัดการ" />,
            dataIndex: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space size={0}>
                        <button className="btn btn-warning btn-sm text-white me-2" onClick={() => { toggleEditTruewallet(); handleTruewallet(record) }}>
                            <i className="fa-solid fa-edit me-1"></i>
                            <FormattedMessage id="edit" defaultMessage="แก้ไข" />
                        </button>
                        <button className="btn btn-danger btn-sm text-white me-2" onClick={() => handleDelete(record.accountNumber)}>
                            <i className="fa-solid fa-trash me-1"></i>
                            <FormattedMessage id="delete" defaultMessage="ลบ" />
                        </button>
                    </Space>
                </>
            )
        }
    ]



    const reduceBank = (banks: any) => {
        return banks.filter(({ bank, meta }: any) => bank === 'truewallet')
    }

    useEffect(() => {
        if (prefix) {
            const filteredItems = (reduceBank(Object.values(prefix.banks)) as any).filter(
                (item: any) => (
                    (item.accountNumber && item.accountNumber.toLowerCase().includes(filterText.toLowerCase())) ||
                    (item.accountName && item.accountName.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            setBanks(filteredItems)
        }
    }, [prefix, filterText])


    return (
        <Fragment>
            {features.truewallet &&
                <>
                    <h5 className="mt-5 mb-3"><FormattedMessage id="truemoneywallet.title2" defaultMessage="บัญชีทรูมันนี่วอลเล็ท" /> <small className="sub-title">{`${NumberWithCommas(banks.length)} ${FuncFormattedMessage("list", "รายการ")}`}</small></h5>
                    <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                        <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                        <div className="d-lg-flex justify-end-lg-between d-md-flex justify-end-md-between">
                            <button className="btn btn-primary ms-auto ms-md-0 mt-3 mt-md-0" onClick={toggleAddTrueWallet}>
                                <i className="fa-solid fa-plus me-3"></i>
                                <span><FormattedMessage id="truemoneywallet.add" defaultMessage="เพิ่มบัญชีทรูมันนี่วอลเล็ท" /></span>
                            </button>
                        </div>
                    </div>
                    <div className="box-white ant-box-table">
                        <TableComponent
                            columns={columns}
                            dataSource={banks || []}
                            loading={false}
                        />
                    </div>
                    <EditTruewallet
                        data={truewalletData as any}
                        isOpen={editTruewallet}
                        close={() => toggleEditTruewallet()}
                    />
                    <AddTruewallet
                        isOpen={addTrueWallet}
                        close={() => toggleAddTrueWallet()}
                    />
                </>
            }
        </Fragment>
    )
}

export default ListBank