import React, { Fragment } from 'react'
import TitleBar from '../../components/Elements/TitleBar'
import DepositTransactionLastest from '../../components/Main/DepositTransactionLastest'
import WithDrawTransactionLastest from '../../components/Main/WithDrawTransactionLastest'
import BankTransactionLastest from '../../components/Main/BankTransactionLastest'
import FormattedMessage from '../../components/Elements/FormattedMessage'

const Main = () => {
    return (
        <Fragment>
            <TitleBar
                title={<FormattedMessage id={'main.title'} defaultMessage={'รายการล่าสุด'} />}
                subTitle={<FormattedMessage id={'main.sub-title'} defaultMessage={'รายการที่รอดำเนินการ / รายการที่ค้าง'} />}
            />
            <div className="row">
                <div className="col-lg-4">
                    <DepositTransactionLastest />
                </div>
                <div className="col-lg-4">
                    <WithDrawTransactionLastest />
                </div>
                <div className="col-lg-4">
                    <BankTransactionLastest />
                </div>
            </div>
        </Fragment>
    )
}

export default Main