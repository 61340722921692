import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Input, Row, Col, Divider, Space, Button } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import { ambPaymentConfig } from '../../../@types/prefix'
import useFetchSetting from "../../../hooks/useFetchSetting"
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage'

const AmbPayment = () => {

    const [form] = Form.useForm();
    const { data, mutate } = useFetchSetting()

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: ambPaymentConfig) => {
        try {
            const res = await PrefixService.updateAmbPayment(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: ambPaymentConfig) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }


    useEffect(() => {
        if (form && data) {
            form.resetFields();
            form.setFieldsValue(data?.setting?.ambPayment)
        }
    }, [form, data])// eslint-disable-line


    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.amb-payment.setting-amb-payment" defaultMessage="ตั้งค่า AMB Payment" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="ambPaymentForm"
                    layout="vertical"
                    hideRequiredMark
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="Username"
                            >
                                <Input placeholder="Username" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="secret"
                                label="Secret"
                            >
                                <Input placeholder="Secret" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.amb-payment.save" defaultMessage="บันทึก" /> </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AmbPayment