import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Row, Col, Divider, Space, Radio } from 'antd'
// import type { RadioChangeEvent } from 'antd';
import TitleBar from '../../../components/Elements/TitleBar'
import ThemePreview from '../../../components/Settings/ThemePreview'
import useFetchSetting from '../../../hooks/useFetchSetting'
import PrefixService from "../../../services/prefix"
import { IUpdateTheme } from '../../../@types/prefix'
import { toast } from 'react-toastify'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const Theme = () => {
    const [form] = Form.useForm();
    const { data, mutate } = useFetchSetting()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const updateData = async (values: IUpdateTheme) => {
        try {
            const res = await PrefixService.updateTheme(values)
            setBtnLoading(false)
            toast.success(res.message)
            mutate()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {
                console.log(errorInfo);

            });
    }

    useEffect(() => {
        if (form && data) {
            form.resetFields();
            form.setFieldsValue({ "themeID": data.setting.themeID ? data.setting.themeID : 0 })
        }
    }, [form, data])// eslint-disable-line

    const textThemeWebsite = FuncFormattedMessage("setting.theme.input.theme-website", "ธีมเว็บไซต์")

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.theme.setting-theme" defaultMessage="ตั้งค่าธีม" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="themeForm"
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="themeID"
                                label={textThemeWebsite}>
                                <Radio.Group className="radio-setting-theme">
                                    <Row gutter={[8, 16]}>
                                        <Col>
                                            <Radio value={0}>
                                                <ThemePreview colorPrimary='#ffc800' colorSecondary='#933fde' />
                                            </Radio>
                                        </Col>
                                        <Col>
                                            <Radio value={1}>
                                                <ThemePreview colorPrimary='#0ec454' colorSecondary='#ffc800' />
                                            </Radio>
                                        </Col>
                                        <Col>
                                            <Radio value={2}>
                                                <ThemePreview colorPrimary='#0067ff' colorSecondary='#fc392c' />
                                            </Radio>
                                        </Col>
                                        <Col>
                                            <Radio value={3}>
                                                <ThemePreview colorPrimary='#933fde' colorSecondary='#f9d421' />
                                            </Radio>
                                        </Col>
                                        <Col>
                                            <Radio value={4}>
                                                <ThemePreview colorPrimary='#f91f7e' colorSecondary='#933fde' />
                                            </Radio>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.theme.save" defaultMessage="บันทึก" /> </Button>
                        </Space>
                    </div>
                </Form>
            </div>

        </Fragment>
    )
}

export default Theme