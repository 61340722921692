import React, { Fragment, useEffect, useState } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Input, Row, Col, Select, Divider, Space, Button, InputNumber } from 'antd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PatternNumber from '../../../utils/PatternNumber'
import { bankNames } from '../../../utils/bank'
import { CentralAccountConfig } from '../../../@types/prefix'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import History from '../../../components/Banks/WithdrawCentral/History'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const WithdrawCentral = () => {

    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const [historyDrawer, setHistoryDrawer] = useState<boolean>(false)
    const toggleHistory = () => setHistoryDrawer(!historyDrawer)
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const { Option } = Select;
    const updateData = async (values: CentralAccountConfig) => {
        try {
            const res = await PrefixService.saveCentralAccount(values)
            setBtnLoading(false)
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CentralAccountConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };

    const optionBankNames = Object.keys(bankNames).map((key: any) => {
        return <Option key={key} value={key}><FormattedMessage id={`bank_name.${key}`} defaultMessage={bankNames[key]} /></Option>
    })

    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.centralAccount)
        }
    }, [form])// eslint-disable-line 

    const text_minAmount = FuncFormattedMessage("withdrawCentral.input.minAmount", "ยอดคงเหลือขั้นต่ำ")
    const text_bank = FuncFormattedMessage("withdrawCentral.input.bank", "ธนาคาร")
    const text_select_bank = FuncFormattedMessage("withdrawCentral.input.select_bank", "เลือกธนาคาร")
    const text_accountNumber = FuncFormattedMessage("withdrawCentral.input.accountNumber", "เลขที่บัญชีกลาง")


    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="withdrawCentral.title" defaultMessage="ตั้งค่าถอนเข้าบัญชีกลาง" />} />
            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-primary ms-auto ms-md-0" onClick={toggleHistory}>
                        <i className="fa-solid fa-clock-rotate-left me-1"></i>
                        <span><FormattedMessage id="withdrawCentral.history" defaultMessage="ประวัติการโอนเงิน" /></span>
                    </button>
                </div>
            </div>
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="withdrawCentralForm"
                    layout="vertical"
                    hideRequiredMark
                    size={'large'}
                >
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="minAmount"
                                label={text_minAmount}
                                rules={[
                                    { required: true, message: <FormattedMessage id="withdrawCentral.input.please_minAmount" defaultMessage="กรุณากรอกยอดคงเหลือขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_minAmount} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="bankCode"
                                label={text_bank}
                                rules={[{ required: true, message: <FormattedMessage id="withdrawCentral.input.please_bank" defaultMessage="กรุณาเลือกธนาคาร" /> }]}>
                                <Select
                                    placeholder={text_select_bank}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionBankNames}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="accountNumber"
                                label={text_accountNumber}
                                rules={[
                                    { required: true, message: <FormattedMessage id="withdrawCentral.input.please_accountNumber" defaultMessage="กรุณากรอกเลขที่บัญชีกลาง" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <Input placeholder={text_accountNumber} inputMode="numeric" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>

            <History
                isOpen={historyDrawer}
                close={() => toggleHistory()}
            />
        </Fragment>
    )
}

export default WithdrawCentral