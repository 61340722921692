import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/IDateRange'
import { PaginationParams } from '../@types/api'

export const useFetchMember = (dateRange: DateRangeType, search?: string, params?: PaginationParams) => {
	const prefix = PrefixService.getPrefix()
	const { data, mutate, error } = useSWR([`/members/${prefix}?search=${search}`, dateRange.start, dateRange.end, params?.limit, params?.page], () => PrefixService.getMemberList(dateRange, search, params));

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchMember
