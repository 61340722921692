export const typeName: Record<string, string> = {
	deduct: 'ตัดเครดิต',
	add: 'เติมเครดิต',
	promotion: 'รับโบนัส',
	wheel: 'กงล้อเสี่ยงโชค',
	cashback: 'รับแคชแบ็ก',
	redeem: 'โค้ดโปรโมชั่น',
	deduaffiliate_transferct: 'โยกยอดแนะนำเพื่อน',
	'new-reg-bonus': 'แจกเครดิต (สมาชิกใหม่)',
}

export const getTypeName = (code: string = 'UNKNOWN') => {
	if (typeName.hasOwnProperty(code)) {
		return typeName[code]
	}
	return code
}
