import React, { Fragment, useState, useEffect } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import DatePickerRange from '../../../components/Elements/DatePickerRange'
import DateFilterTabs from '../../../components/Elements/DateFilterTabs'
import TableComponent from '../../../components/Elements/Table'
import AvatarMemberComponent from '../../../components/Elements/AvatarMember'
import SearchData from '../../../components/Elements/Search'
import { Select } from 'antd'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { ReportByMemberTransaction } from '../../../@types/report'
import useDateRange from "../../../hooks/useDateRange"
import useFetchReportByMemberList from '../../../hooks/useFetchReportByMemberList'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const ReportTransactionIndividual = () => {
    const { Option } = Select;

    const [newData, setNewData] = useState<any>()
    const [filterText, setFilterText] = useState('')
    const [defaultSort, setDefaultSort] = useState<string>('')
    const { dateRange, setDateRange } = useDateRange()

    const { data, isLoading } = useFetchReportByMemberList(dateRange, 10000)

    const calcReportTransaction = (
        type: string,
        typeCompare: string,
        value: number,
        added: number
    ) => (
        type === typeCompare ? (value ? value : 0) + added : (value ? value : 0)
    )

    const reduceData = data?.reduce((item: any, { amount, type, member }: ReportByMemberTransaction) => {
        item[member.id] = {
            id: member?.id,
            member: member,
            deposit: calcReportTransaction(type, 'deposit', item[member.id]?.deposit, amount),
            depositCount: calcReportTransaction(type, 'deposit', item[member.id]?.depositCount, 1),
            withdraw: calcReportTransaction(type, 'withdraw', item[member.id]?.withdraw, amount),
            withdrawCount: calcReportTransaction(type, 'withdraw', item[member.id]?.withdrawCount, 1)
        }
        return item;
    }, {})

    const columns: any = [
        {
            title: <FormattedMessage id="member_table" defaultMessage="สมาชิก" />,
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    <AvatarMemberComponent
                        fullname={`${record?.member.firstName} ${record?.member.lastName}`}
                        phone={record?.member.phone}
                        search={true}
                    />
                </>
            )
        },
        {
            title: <FormattedMessage id="reports.transactionIndividual.deposit_amount" defaultMessage="ยอดฝาก" />,
            dataIndex: 'amount',
            sorter: (a: any, b: any) => a.deposit - b.deposit,
            render: (text: any, record: any) => (
                <>
                    <div className="d-flex align-items-center">
                        <div className="row-amount"><span>฿</span> {NumberWithCommas(record.deposit, 2)}</div>
                        <div className="bo-badge badge-light ms-2">{NumberWithCommas(record?.depositCount)} <FormattedMessage id="times" defaultMessage="ครั้ง" /></div>
                    </div>
                </>
            )
        },
        {
            title: <FormattedMessage id="reports.transactionIndividual.withdrawal_amount" defaultMessage="ยอดถอน" />,
            dataIndex: 'totalTurn',
            sorter: (a: any, b: any) => a.withdraw - b.withdraw,
            render: (text: any, record: any) => (
                <>
                    <div className="d-flex align-items-center">
                        <div className="row-amount"><span>฿</span> {NumberWithCommas(record.withdraw, 2)}</div>
                        <div className="bo-badge badge-light ms-2">{NumberWithCommas(record?.withdrawCount)} <FormattedMessage id="times" defaultMessage="ครั้ง" /></div>
                    </div>
                </>
            )
        },
        {
            title: <FormattedMessage id="reports.transactionIndividual.profit_loss" defaultMessage="กำไร/ขาดทุน" />,
            dataIndex: 'turn',
            sorter: (a: any, b: any) => (a.deposit - a.withdraw) - (b.deposit - b.withdraw),
            render: (text: any, record: any) => (
                <div className="row-amount">
                    <span>฿</span> {NumberWithCommas(record?.deposit - record?.withdraw, 2)}
                </div>
            )
        }
    ]

    useEffect(() => {
        setDefaultSort('deposit')
        const newReduceData = Object.values(reduceData || []).sort((a: any, b: any) => parseFloat(b.deposit) - parseFloat(a.deposit)) as any
        const filteredItems = (newReduceData as any).filter(
            (item: any) => (
                (item.member.firstName && item.member.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.member.lastName && item.member.lastName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.member.phone && item.member.phone.toLowerCase().includes(filterText.toLowerCase()))
            ),
        );
        setNewData(filteredItems)
    }, [dateRange, isLoading, filterText]) // eslint-disable-line

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="reports.transactionIndividual.title" defaultMessage="รายการฝาก/ถอนรายบุคคล" />} subTitle={`${NumberWithCommas(newData !== undefined ? newData.length : 0)} ${FuncFormattedMessage("list", "รายการ")}`} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilterTabs onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <SearchData onChangeSearch={setFilterText} filterText={filterText} />
                <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                    <label className="me-2"><FormattedMessage id="sort_of" defaultMessage="เรียงจาก" /> :</label>
                    <Select
                        size="large"
                        dropdownMatchSelectWidth={false}
                        placement="bottomRight"
                        value={defaultSort}
                        onSelect={(value: any) => {
                            setDefaultSort(value)
                            setNewData(Object.values(reduceData || []).sort((a: any, b: any) => (
                                value === 'balance'
                                    ? (parseFloat(b.deposit) - parseFloat(b.withdraw)) - (parseFloat(a.deposit) - parseFloat(a.withdraw))
                                    : parseFloat(b[value]) - parseFloat(a[value])
                            )) as any)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        <Option value="deposit"><FormattedMessage id="reports.transactionIndividual.deposit_amount" defaultMessage="ยอดฝาก" /></Option>
                        <Option value="withdraw"><FormattedMessage id="reports.transactionIndividual.withdrawal_amount" defaultMessage="ยอดถอน" /></Option>
                        <Option value="balance"><FormattedMessage id="reports.transactionIndividual.profit_loss" defaultMessage="กำไร/ขาดทุน" /></Option>
                    </Select>
                </div>
            </div>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={newData}
                    loading={isLoading}
                />
            </div>
        </Fragment>
    )
}

export default ReportTransactionIndividual