import React, { FC } from 'react'
import { DatePicker, Space } from 'antd'
import moment from 'moment'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage'
interface DatePickerRangeProps {
    onChangeDate: (e: any) => void
    onDateActive: any
    funClear?: boolean
}

const DatePickerRange: FC<DatePickerRangeProps> = ({ onChangeDate, onDateActive, funClear }) => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'
    // const [dateRange, setDateRange] = useState({
    // 	start: "",
    // 	end: "",
    // })

    const onDateSearch = (date: any) => {
        if (date !== null) {
            onChangeDate && onChangeDate({
                start: moment(date[0]).format('YYYY-MM-DD'),
                end: moment(date[1]).format('YYYY-MM-DD'),
            })
        } else {
            if (funClear) {
                onChangeDate && onChangeDate({
                    start: "",
                    end: "",
                })
            }
        }
    }



    return (
        <div className="d-flex mb-3 mb-lg-0">
            <Space
                direction="vertical"
                size={12}
            >
                <RangePicker
                    placeholder={[FuncFormattedMessage("start_date", "วันที่เริ่มต้น"), FuncFormattedMessage("end_date", "วันที่สิ้นสุด")]}
                    value={[onDateActive?.start ? moment(onDateActive?.start) : null, onDateActive?.end ? moment(onDateActive?.end) : null]}
                    ranges={{
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'This week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                        'Last week': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                        'This month': [moment().startOf('month'), moment().endOf('month')],
                        'Last month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                    }}
                    format={dateFormat}
                    onChange={onDateSearch}
                />
            </Space>
            {/* <button type="button" className="btn btn-primary ms-3" onClick={onloadData}>ค้นหา</button> */}
        </div>
    )
}

export default DatePickerRange