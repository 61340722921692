import useSWR from 'swr'
import PrefixService from '../services/prefix'
import { DateRangeType } from '../@types/dashboard';


export const useFetchReportByMemberList = (dateRange: DateRangeType, refreshInterval: number): any => {
	const prefix = PrefixService.getPrefix()
	const { data, mutate, error } = useSWR([`/summary/report-by-member/${prefix}`, dateRange.start, dateRange.end], () =>
		PrefixService.getReportByMemberList({
			dateRange: dateRange,
		}),
	)

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchReportByMemberList
