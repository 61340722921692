import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Row, Col, Divider, Space, Button, Select } from 'antd'
import TitleBar from '../../../components/Elements/TitleBar'
import { ambKingSportBonusConfig } from '../../../@types/prefix'
import PrefixService from "../../../services/prefix"
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import { ApplicationState } from '../../../store'
import { useSelector } from 'react-redux'
import { ThunkDispatch } from '../../../@types/store'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import Loading from '../../../components/Elements/Loading'

const AMBKingSportBonus = () => {

    const [form] = Form.useForm();
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [dataSource, setDataSource] = useState<any>([])

    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const updateData = async (values: ambKingSportBonusConfig) => {
        try {
            const res = await PrefixService.updateAMBKingSportBonus(values)
            setBtnLoading(false)
            toast.success(res.message)
            dispatch(requestAuthInit(false))
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }
    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: ambKingSportBonusConfig) => {
                setBtnLoading(true)
                updateData(values);
            })
            .catch((errorInfo) => {

            });
    }


    useEffect(() => {
        if (form && prefix.setting) {
            form.resetFields();
            form.setFieldsValue({
                "bonusKey": prefix.setting.ambkingBonusSport
            })
        }
    }, [form, prefix.setting])// eslint-disable-line

    useEffect(() => {
        if (Object.keys(prefix.promotions).length !== 0 || !prefix.promotions) {
            setLoading(false)
            let sortable = Object.values(prefix.promotions).filter((x) => x.enable).map((element: any, idx: any) => {
                const item: any = element
                const keyBonus = Object.keys(prefix.promotions)[idx];
                item.key = keyBonus
                return item
            });

            sortable.sort((a, b) => {
                if (!a.enable) {
                    return 1
                } else if (!b.enable) {
                    return -1
                } else {
                    if (a.order !== 0) {
                        if (b.order === 0) {
                            return -1
                        }
                        if (a.order < b.order) {
                            return -1
                        } else {
                            return 1
                        }
                    }
                }
                return 0
            })
            setDataSource(sortable)
        }
    }, [prefix.promotions]) // eslint-disable-line

    const textBonus = FuncFormattedMessage("setting.bonus", "โบนัส")
    const textSelectBonus = FuncFormattedMessage("setting.select-bonus", "เลืิอกโบนัส")

    const optionBonus = Object.values(dataSource).map((item: any) => {
        return <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>
    })

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="setting.ambkingBonusSport" defaultMessage="ตั้งค่า AMBKing Sport Bonus" />} />
            <div className="box-white ant-box-card">
                {loading ? <Loading /> :
                    <Form
                        form={form}
                        name="AMBKingSportBonusForm"
                        layout="vertical"
                        hideRequiredMark
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="bonusKey"
                                    label={textBonus}>
                                    <Select
                                        placeholder={textSelectBonus}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        <Select.Option key={""} value={""}>{textSelectBonus}</Select.Option>
                                        {optionBonus}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <div className="drawer-actions">
                            <Space>
                                <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i><FormattedMessage id="setting.amb-payment.save" defaultMessage="บันทึก" /> </Button>
                            </Space>
                        </div>
                    </Form>
                }
            </div>
        </Fragment>
    )
}

export default AMBKingSportBonus