import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import TitleBar from '../../../components/Elements/TitleBar'
import AddData from '../../../components/Settings/Popup/Add'
import ListData from '../../../components/Settings/Popup/List'
import EditData from '../../../components/Settings/Popup/Edit'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const Popup = () => {
    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const [loading, setLoading] = useState<boolean>(true)
    const [dataSource, setDataSource] = useState<any>([])


    const [addDrawer, setAddDrawer] = useState(false)
    const toggleAdd = () => setAddDrawer(!addDrawer)

    const [editDrawer, setEditDrawer] = useState(false)
    const toggleEdit = () => setEditDrawer(!editDrawer)
    const [popupEdit, setPopupEdit] = useState<object>({})
    const [popupKey, setPopupKey] = useState<string>("")
    const handleEdit = (key: string) => {
        setPopupEdit(prefix.setting.memberPopup[key])
        setPopupKey(key)
        toggleEdit()
    }

    useEffect(() => {
        setDataSource([])
        if (Object.keys(prefix.setting.memberPopup).length > 0) {
            let popupData = Object.values(prefix.setting.memberPopup).map((element: any, idx: any) => {
                const item: any = element
                const keyPopup = Object.keys(prefix.setting.memberPopup)[idx];
                item.key = keyPopup
                return item
            });
            setLoading(false)
            setDataSource(popupData)
        }
    }, [prefix.setting.memberPopup]) // eslint-disable-line

    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="menu-setting-popup" defaultMessage="ป๊อปอัพ" />} subTitle={`${NumberWithCommas(dataSource.length || 0)} ${FuncFormattedMessage("list", "รายการ")}`} />

            <div className="row mb-md-3 align-items-md-center">
                <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                    <button className="btn btn-primary ms-auto ms-md-0" onClick={() => toggleAdd()}>
                        <i className="fa-solid fa-plus me-3"></i>
                        <span><FormattedMessage id="popup.add" defaultMessage="เพิ่มป๊อปอัพ" /></span>
                    </button>
                </div>
            </div>

            <ListData dataSource={dataSource} loading={loading} handleEdit={handleEdit} />

            <AddData
                isOpen={addDrawer}
                close={() => toggleAdd()}
            />
            <EditData
                isOpen={editDrawer}
                close={() => toggleEdit()}
                data={popupEdit}
                popupKey={popupKey}
            />
        </Fragment>
    )
}

export default Popup