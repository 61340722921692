import { useState } from 'react'

const useDatePagination = () => {
    const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 25 })

    return {
        pagination,
        setPagination,
    }
}

export default useDatePagination
