import React, { FC } from 'react';
import { Skeleton } from 'antd'
import { FuncFormattedMessage } from '../../utils/FuncFormattedMessage';

interface AmountCardBankProps {
    icon: string
    number?: string
    amount?: any
    amountType: string
    textList?: string
    loading: boolean
    theme: string
    className?: string
}

const AmountCardBank: FC<AmountCardBankProps> = ({ icon, number, amount, amountType, textList, loading, theme, className }) => {

    return (
        <div className={`amount-card ${className}`}>
            <div className={`amount-card-icon ${theme && (`icon-${theme}`)}`}>
                <img src={icon} alt={amountType} />
            </div>
            <div className="amount-card-text">
                {loading ? <Skeleton.Button block active shape="round" /> :
                    <>
                        <div className={theme && (`text-${theme}`)}>{amountType} {textList && textList}</div>
                        <div className="amount-card-text-amount">
                            {amount ? (<><span>฿ </span> {amount}</>) : `${number} ${FuncFormattedMessage("list", "รายการ")}`}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default AmountCardBank
