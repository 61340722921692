import { FC, useState, createContext } from 'react';

interface ThemeContextState {
  theme: string
  toggleTheme: (e: any) => void
}

const themeCookie = localStorage.getItem('theme')

const defaultState = {
  theme: themeCookie || "light",
  toggleTheme: () => { }
}

export const ThemeContext = createContext<ThemeContextState>(defaultState);

const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState(defaultState.theme)
  const changeTheme = () => localStorage.setItem('theme', theme)

  const toggleTheme = (e: any) => {
    setTheme((e === true) ? "dark" : "light")
    changeTheme()
  }

  changeTheme();

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;