import React, { FC } from 'react'

interface Props {
    colorPrimary: string
    colorSecondary: string
}

const ThemePreview: FC<Props> = ({ colorPrimary, colorSecondary }) => {
    return (
        <svg width="120px" height="80px" viewBox="0 0 120 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-219.000000, -466.000000)">
                    <g id="Group" transform="translate(219.000000, 466.000000)">
                        <rect fill="#121416" x="0" y="0" width="120" height="80"></rect>
                        <rect fill={colorPrimary} x="8" y="8" width="104" height="36" rx="4"></rect>
                        <rect fill="#282828" x="8" y="52" width="104" height="20" rx="4"></rect>
                        <rect fill={colorSecondary} x="86" y="58" width="20" height="8" rx="4"></rect>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default ThemePreview

