import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Space,
    Divider,
    Select,
    Radio,
    Upload,
    Switch,
    InputNumber
} from 'antd'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import PrefixService from "../../../services/prefix"
import { requestAuthInit } from '../../../store/auth/auth.actions'
import {
    BonusAmountTypes,
    BonusGameTypes,
    BonusGameTypesAMBKing,
    PromotionConfig,
    PromotionLimits,
    PromotionLimitTypes,
    PromotionUfaCashbacks,
    WithdrawLimitTypes
} from '../../../@types/prefix'
import { ThunkDispatch } from '../../../@types/store'
import PatternNumber from '../../../utils/PatternNumber'
import StepSetting from './StepSetting'
import PeriodSetting from './PeriodSetting'
import ConsecutiveDepositBonus from './ConsecutiveDepositBonus'
import NoDepositBetween from './NoDepositBetween'
import moment from "moment";
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'
import FormattedMessage from '../../Elements/FormattedMessage'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'

interface EditBonusProps {
    close: () => void
    isOpen: boolean

    data: any
    bonusKey: string
}

const EditBonus: FC<EditBonusProps> = ({ close, isOpen, data, bonusKey }) => {
    const [form] = Form.useForm();
    const title = FuncFormattedMessage("bonus.edit", "แก้ไขโบนัส")
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [imageUrl, setImageUrl] = useState<string>("")
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const [category, setCategory] = useState<string>("")
    const [withdrawLimitType, setWithdrawLimitType] = useState<string>("")
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const { game } = useSelector((state: ApplicationState) => state.prefix)

    const updateData = async (values: PromotionConfig) => {
        try {
            const res = await PrefixService.savePromotionConfig(bonusKey, values)
            setBtnLoading(false)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            close()
        } catch (error: any) {
            toast.error(error.message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PromotionConfig) => {
                setBtnLoading(true)
                // values.steps = steps
                values.steps = values.steps && values.steps.filter((x) => !x.deleted)
                // values.periods = periods
                values.periods = values.periods && values.periods.filter((x) => !x.deleted)
                values.noDepositBetween.startDate = values.noDepositBetween.startDate ? moment(values.noDepositBetween.startDate).format('YYYY-MM-DD') : ""
                values.noDepositBetween.endDate = values.noDepositBetween.endDate ? moment(values.noDepositBetween.endDate).format('YYYY-MM-DD') : ""
                updateData(values)
            })
            .catch((errorInfo) => { });
    };


    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}><FormattedMessage id="upload" defaultMessage="อัพโหลด" /></div>
        </div>
    );

    const uploadPromotionImage = async (response: any) => {
        setLoadingImage(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', 'promotion');

        try {
            const res = await PrefixService.uploadPromotionImage(bonusKey ? bonusKey : '', formData)
            toast.success(res.message)
            setLoadingImage(false)
            getBase64(response.file, (imageUrl: any) => setImageUrl(imageUrl));
        } catch (error: any) {
            toast.error(error.message as string)
            setLoadingImage(false)
        }
    }

    const deleteImage = async () => {
        try {
            await PrefixService.deletePromotionImage(bonusKey ? bonusKey : '')
            // const text_success = FuncFormattedMessage("delete_img_success_ms", "ลบรูปภาพสำเร็จ")
            // console.log(text_success);
            toast.success(<FormattedMessage id="delete_img_success" defaultMessage="ลบรูปภาพสำเร็จ" />)
            setImageUrl("")
        } catch (error: any) {
            toast.error(error.message as string)
        }
    }


    useEffect(() => {
        if (form && isOpen && data) {
            form.resetFields();
            data.gameType = data.gameType ? data.gameType : 'none'
            data.steps = data.steps || [{ min: 0, max: 0, bonus: 0, deleted: false }]
            data.periods = data.periods || [{ from: "00:00", to: "23:59", deleted: false }]
            data.consecutiveDepositBonus.rules = data.consecutiveDepositBonus?.rules || {
                noBonus: false,
                noCashback: false,
                noLuckyWheel: false,
                noWithdraw: false
            }
            data.noDepositBetween.rules = data.noDepositBetween?.rules || {
                noBonus: false,
                noCashback: false,
                noLuckyWheel: false,
                noWithdraw: false
            }
            data.noDepositBetween.startDate = data.noDepositBetween.startDate ? moment(data.noDepositBetween.startDate) : ""
            data.noDepositBetween.endDate = data.noDepositBetween.endDate ? moment(data.noDepositBetween.endDate) : ""
            form.setFieldsValue(data)

            setCategory(data?.category)
            setWithdrawLimitType(data?.withdrawLimitType)
            setImageUrl(data?.image)
        }
    }, [form, isOpen, data]);

    return (
        <>
            <Drawer
                title={title}
                onClose={close}
                visible={isOpen}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .7}
                extra={
                    <Space>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large">
                            <i className="fa-solid fa-save me-2"></i>
                            <FormattedMessage id="save" defaultMessage="บันทึก" />
                        </Button>
                    </Space>
                }>
                <Form
                    form={form}
                    name="EditBonus"
                    layout="vertical"
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="enable"
                                label={<FormattedMessage id="status" defaultMessage="สถานะ" />}
                                valuePropName="checked"
                                className="mb-0"
                            >
                                <Switch
                                    checkedChildren={<FormattedMessage id="open" defaultMessage="เปิด" />}
                                    unCheckedChildren={<FormattedMessage id="close" defaultMessage="ปิด" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={<FormattedMessage id="bonus.input.name" defaultMessage="ชื่อโบนัส" />}
                                rules={[{ required: true, message: <FormattedMessage id="bonus.input.message_name" defaultMessage="กรุณากรอกชื่อโบนัส" /> }]}
                                hasFeedback>
                                <Input placeholder={FuncFormattedMessage("bonus.input.name", "ชื่อโบนัส")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label={<FormattedMessage id="bonus.input.details" defaultMessage="รายละเอียด" />}
                                rules={[{ required: true, message: <FormattedMessage id="bonus.input.message_details" defaultMessage="กรุณากรอกรายละเอียด" /> }]}
                                hasFeedback>
                                {/* <Input.TextArea rows={3} placeholder="รายละเอียด" /> */}
                                <Input placeholder={FuncFormattedMessage("bonus.input.details", "รายละเอียด")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" orientationMargin="0">
                        <FormattedMessage id="bonus.input.type_bonus" defaultMessage="ประเภทโบนัส" />
                    </Divider>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="category"
                                label={<FormattedMessage id="bonus.input.type_bonus" defaultMessage="ประเภทโบนัส" />}
                                rules={[{ required: true, message: <FormattedMessage id="bonus.input.message_type_bonus" defaultMessage="กรุณาเลือกประเภทโบนัส" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={<FormattedMessage id="bonus.input.select_type_bonus" defaultMessage="เลือกประเภทโบนัส" />}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setCategory(value);
                                    }}>
                                    {PromotionLimitTypes.map(({ value, label }) => (
                                        <Option value={value} key={value} ><FormattedMessage id={`bonus.select_option.${value}`} defaultMessage={label} /></Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {category === 'deposit' && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="limit"
                                    label=""
                                    hasFeedback>
                                    <Radio.Group buttonStyle="solid" className="ant-radio-button-custom">
                                        {PromotionLimits.map(({ limit, label }) => (
                                            <Radio.Button value={limit} key={limit}><FormattedMessage id={`bonus.radio_option_deposit.${limit}`} defaultMessage={label} /></Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {category === 'ufa-sport-wl-cashback' && (
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="limit"
                                    label=""
                                    hasFeedback>
                                    <Radio.Group buttonStyle="solid" className="ant-radio-button-custom">
                                        {PromotionUfaCashbacks.map(({ limit, label }) => (
                                            <Radio.Button value={limit} key={limit}><FormattedMessage id={`bonus.radio_option_ufa.${limit}`} defaultMessage={label} /></Radio.Button>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Divider />
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="type"
                                label={<FormattedMessage id="bonus.input.type_number" defaultMessage="ประเภทจำนวน" />}
                                hasFeedback>
                                <Radio.Group buttonStyle="solid">
                                    {BonusAmountTypes.map(({ type, label }) => (
                                        <Radio.Button value={type} key={type}><FormattedMessage id={`bonus.radio_option_bonusAmountTypes.${type}`} defaultMessage={label} /></Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="gameType"
                                label={<FormattedMessage id="bonus.input.type_game" defaultMessage="ประเภทเกม" />}
                                hasFeedback>
                                <Radio.Group buttonStyle="solid">
                                    {((game).toUpperCase() === "AMBKING") ? (
                                        BonusGameTypesAMBKing.map(({ game, label }) => (
                                            <Radio.Button value={game} key={game}><FormattedMessage id={`bonus.radio_option_BonusGameTypes.${game}`} defaultMessage={label} /></Radio.Button>
                                        ))
                                    ) : (
                                        BonusGameTypes.map(({ game, label }) => (
                                            <Radio.Button value={game} key={game}><FormattedMessage id={`bonus.radio_option_BonusGameTypes.${game}`} defaultMessage={label} /></Radio.Button>
                                        ))
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="order"
                                label={<FormattedMessage id="bonus.input.order" defaultMessage="ลำดับ" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_order" defaultMessage="กรุณากรอกลำดับ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("bonus.input.order", "ลำดับ")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={<FormattedMessage id="bonus.input.bonus" defaultMessage="โบนัส" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_bunus" defaultMessage="กรุณากรอกโบนัส" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("bonus.input.bonus", "โบนัส")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minBonus"
                                label={<FormattedMessage id="bonus.input.minBonus" defaultMessage="โบนัสขั้นต่ำ" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_minBonus" defaultMessage="กรุณากรอกโบนัสขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("bonus.input.minBonus", "โบนัสขั้นต่ำ")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="maxBonus"
                                label={<FormattedMessage id="bonus.input.maxBonus" defaultMessage="โบนัสสูงสุด" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_maxBonus" defaultMessage="กรุณากรอกโบนัสสูงสุด" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("bonus.input.maxBonus", "โบนัสสูงสุด")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="minimum"
                                label={<FormattedMessage id="bonus.input.minDeposit" defaultMessage="ยอดฝากขั้นต่ำ" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="bonus.input.please_minDeposit" defaultMessage="กรุณากรอกยอดฝากขั้นต่ำ" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("bonus.input.minDeposit", "ยอดฝากขั้นต่ำ")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="turn"
                                label={<FormattedMessage id="turn-times" defaultMessage="เทิร์น (เท่า)" />}
                                rules={[
                                    { required: true, message: <FormattedMessage id="please-enter-turn-times" defaultMessage="กรุณากรอกเทิร์น (เท่า)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={FuncFormattedMessage("turn-times", "เทิร์น (เท่า)")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimitType"
                                label={<FormattedMessage id="bonus.input.maxWithdrawType" defaultMessage="ประเภทถอนสูงสุด" />}
                                rules={[{ required: true, message: <FormattedMessage id="bonus.input.please_maxWithdrawType" defaultMessage="กรุณาเลือกประเภทถอนสูงสุด" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={<FormattedMessage id="bonus.input.select_maxWithdrawType" defaultMessage="เลือกประเภทถอนสูงสุด" />}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onSelect={(value: any) => {
                                        setWithdrawLimitType(value)
                                    }}>
                                    {WithdrawLimitTypes.map(({ value, label }) => (
                                        <Option value={value} key={value} ><FormattedMessage id={`bonus.select_option_WithdrawLimitTypes.${value}`} defaultMessage={label} /></Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="withdrawLimit"
                                label={withdrawLimitType === 'fixed' ?
                                    <FormattedMessage id="bonus.input.maxWithdraw" defaultMessage="จำนวนถอนสูงสุด" /> :
                                    <FormattedMessage id="bonus.input.maxWithdraw_times" defaultMessage="ถอนสูงสุด (เท่า)" />}
                                rules={[
                                    { required: true, message: (withdrawLimitType === 'fixed' ? <FormattedMessage id="bonus.input.please_maxWithdraw" defaultMessage="กรุณากรอกจำนวนถอนสูงสุด" /> : <FormattedMessage id="bonus.input.please_maxWithdraw_times" defaultMessage="กรุณากรอกถอนสูงสุด  (เท่า)" />) },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={withdrawLimitType === 'fixed' ? FuncFormattedMessage("bonus.input.maxWithdraw", "จำนวนถอนสูงสุด") : FuncFormattedMessage("bonus.input.maxWithdraw_times", "ถอนสูงสุด (เท่า)")} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <StepSetting data={data} />
                    <PeriodSetting data={data} />
                    <ConsecutiveDepositBonus data={data} />
                    <NoDepositBetween data={data} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{ marginBottom: '16px' }}><FormattedMessage id="bonus.input.image" defaultMessage="รูปภาพโปรโมชั่น" /></label>
                            <div className="upload-image">
                                <Upload
                                    name="promotion-image"
                                    listType="picture-card"
                                    showUploadList={false}
                                    customRequest={uploadPromotionImage}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                                {imageUrl &&
                                    <Button type="primary" onClick={deleteImage} icon={<DeleteOutlined />} shape="circle" danger></Button>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" /></Button>
                            <Button onClick={close} size="large">
                                <FormattedMessage id="cancel" defaultMessage="ยกเลิก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditBonus

