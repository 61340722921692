import React, { Fragment, useEffect, useState } from 'react'
import TitleBar from '../../../components/Elements/TitleBar'
import { Form, Row, Col, Select, Divider, Space, Button, InputNumber, Input, Checkbox } from 'antd'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PatternNumber from '../../../utils/PatternNumber'
import { WithdrawAccountConfig } from '../../../@types/prefix'
import {
    createBankSelector,
    createSCBAccountSelector,
    createBankKbankSelector
} from '../../../utils/bank'
import { ApplicationState } from '../../../store'
import PrefixService from '../../../services/prefix'
import FormattedMessage from '../../../components/Elements/FormattedMessage'
import { FuncFormattedMessage } from '../../../utils/FuncFormattedMessage'

const AutoWithdraw = () => {

    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [typeAccount, setTypeAccount] = useState<string>("")
    const [optionAccountNumber, setOptionAccountNumber] = useState<any>()
    const [showMaxAmount, setShowMaxAmount] = useState<boolean>(false)

    const { prefix } = useSelector((state: ApplicationState) => state.prefix)
    const { Option } = Select;
    const updateData = async (values: WithdrawAccountConfig) => {
        try {
            const res = await PrefixService.saveWithdrawAccount(values)
            setBtnLoading(false)
            toast.success(res.message)
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: WithdrawAccountConfig) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {
            });
    };



    useEffect(() => {
        if (form) {
            form.resetFields();
            form.setFieldsValue(prefix?.setting?.withdrawAccount)
            setTypeAccount(prefix?.setting?.withdrawAccount?.type)
            setShowMaxAmount(prefix?.setting?.withdrawAccount?.noBonus?.enable)
        }
    }, [form, prefix])// eslint-disable-line

    useEffect(() => {
        const AccountNumber = typeAccount === 'api' ?
            createSCBAccountSelector(Object.values(prefix.banks)) :
            (typeAccount === "easynet" ? createBankSelector(Object.values(prefix.banks)) : createBankKbankSelector(Object.values(prefix.banks)))

        const optAccountNumber = AccountNumber && Object.keys(AccountNumber).map((key: any) => {
            return <Option key={AccountNumber[key].value} value={AccountNumber[key].value}>{AccountNumber[key].label}</Option>
        })

        setOptionAccountNumber(optAccountNumber)
        const prefixAccountNumber = AccountNumber.find((item: any) => item.value === prefix?.setting?.withdrawAccount?.accountNumber) !== undefined && AccountNumber.find((item: any) => item.value === prefix?.setting?.withdrawAccount?.accountNumber)?.value
        form.setFieldsValue({ 'accountNumber': prefixAccountNumber })
    }, [typeAccount, form])// eslint-disable-line

    const text_type = FuncFormattedMessage("autoWithdraw.input.type", "ประเภท")
    const text_select_type = FuncFormattedMessage("autoWithdraw.input.select_type", "เลือกประเภท")
    const text_account = FuncFormattedMessage("autoWithdraw.input.account", "บัญชี")
    const text_select_account = FuncFormattedMessage("autoWithdraw.input.select_account", "เลือกบัญชี")
    const text_maxAmount = FuncFormattedMessage("autoWithdraw.input.maxAmount", "ยอดถอนสูงสุด")
    const text_mobileNo = FuncFormattedMessage("autoWithdraw.input.mobileNo", "เบอร์โทรศัพท์ 3 ตัวสุดท้าย")
    const text_noBonus = FuncFormattedMessage("autoWithdraw.input.noBonus", "ยอดถอนสูงสุด (ไม่รับโบนัส)")
    return (
        <Fragment>
            <TitleBar title={<FormattedMessage id="autoWithdraw.title" defaultMessage="ตั้งค่าถอนเงินอัตโนมัติ" />} />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="autoWithdrawForm"
                    layout="vertical"
                    hideRequiredMark
                    size={'large'}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="type"
                                label={text_type}
                                rules={[{ required: false, message: <FormattedMessage id="autoWithdraw.input.please_type" defaultMessage="กรุณาเลือกประเภท" /> }]}>
                                <Select
                                    placeholder={text_select_type}
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={(e: string) => setTypeAccount(e)}
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option key={'1'} value={'api'}>SCB API</Option>
                                    <Option key={'2'} value={'easynet'}>SCB Easy Net</Option>
                                    <Option key={'3'} value={'kbiz'}>KBANK Kbiz</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="maxAmount"
                                label={text_maxAmount}
                                rules={[
                                    { required: true, message: <FormattedMessage id="autoWithdraw.input.please_maxAmount" defaultMessage="กรุณากรอกยอดถอนสูงสุด" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_maxAmount} inputMode="numeric" controls={false} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="accountNumber"
                                label={text_account}
                                rules={[{ required: true, message: <FormattedMessage id="autoWithdraw.input.please_account" defaultMessage="กรุณาเลือกบัญชี" /> }]}
                                hasFeedback>
                                <Select
                                    placeholder={text_select_account}
                                    dropdownMatchSelectWidth={false}
                                    placement="bottomRight"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {optionAccountNumber}
                                </Select>
                            </Form.Item>
                        </Col>
                        {typeAccount === 'easynet' && (
                            <Col sm={24} md={12}>
                                <Form.Item
                                    name="mobileNo"
                                    label={text_mobileNo}
                                    rules={[
                                        { required: typeAccount === 'easynet' ? true : false, message: <FormattedMessage id="autoWithdraw.input.please_mobileNo" defaultMessage="กรุณากรอกเบอร์โทรศัพท์ 3 ตัวสุดท้าย" /> },
                                        { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                    ]}
                                    hasFeedback>
                                    <Input maxLength={3} showCount placeholder={text_mobileNo} inputMode="numeric" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <div className="checkbox-collapse">
                        <Form.Item
                            name={['noBonus', 'enable']}
                            valuePropName="checked"
                            className="mb-0">
                            <Checkbox onClick={() => setShowMaxAmount(!showMaxAmount)}><FormattedMessage id="autoWithdraw.input.check_noBonus" defaultMessage="ถอนเงินอัตโนมัติเมื่อไม่มีรายการรับโบนัส" /></Checkbox>
                        </Form.Item>
                        {showMaxAmount &&
                            <Form.Item
                                name={['noBonus', 'maxAmount']}
                                label={text_noBonus}
                                className="mt-2 mb-0"
                                rules={[
                                    { required: true, message: <FormattedMessage id="autoWithdraw.input.please_noBonus" defaultMessage="กรุณากรอกยอดถอนสูงสุด (ไม่รับโบนัส)" /> },
                                    { pattern: PatternNumber, message: <FormattedMessage id="please-enter-numbers-only" defaultMessage="กรุณากรอกเป็นตัวเลขเท่านั้น" /> }
                                ]}
                                hasFeedback>
                                <InputNumber placeholder={text_noBonus} inputMode="numeric" controls={false} />
                            </Form.Item>
                        }
                    </div>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large">
                                <i className="fa-solid fa-save me-2"></i> <FormattedMessage id="save" defaultMessage="บันทึก" />
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AutoWithdraw