import React, { FC, useCallback, useState, useEffect } from 'react'
import moment from 'moment'
import TableComponent from '../../Elements/Table'
import StatusBadge from '../../Elements/StatusBadge'
import PrefixService from '../../../services/prefix'
import { useAPIRequest } from '../../../hooks/useAPIRequest'
import NumberWithCommas from '../../../utils/NumberWithCommas'
import { getBankName, getBankIcon } from '../../../utils/bank'
import { Member } from '../../../@types/member'
import FormattedMessage from '../../Elements/FormattedMessage'
// import { EyeOutlined } from '@ant-design/icons';


interface DepositTabProps {
    member: Member
    dateRange: any
}

const DepositTab: FC<DepositTabProps> = ({ member, dateRange }) => {
    const [dataSource, setDataSource] = useState<any>([])
    const { data, loading, update } = useAPIRequest(
        useCallback(
            async () =>
                PrefixService.getMemberTransactionList({
                    id: member.id,
                    dateRange: dateRange,
                    type: 'deposit',
                }),
            [member.id, dateRange]
        )
    )

    const columns = [
        {
            title: <FormattedMessage id="date_time" defaultMessage="วันที่/เวลา" />,
            dataIndex: 'transferTime',
            width: '120px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.transferTime).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                    <div><FormattedMessage id="balance_update" defaultMessage="อัพเดทยอด" /> : {moment(record.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="from" defaultMessage="จาก" />,
            dataIndex: 'transferFrom',
            render: (text: any, record: any) => (
                <div className="row-bank">
                    {getBankIcon(record.transferFrom) && (<img src={getBankIcon(record.transferFrom)} alt={record.transferFrom} />)}
                    <div className="row-bank-info">
                        <div>{getBankName(record.transferFrom)} ({record.transferFrom})</div>
                    </div>
                </div>
            )
        },
        {
            title: <FormattedMessage id="amount" defaultMessage="จำนวนเงิน" />,
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <>
                    <div className="row-amount">
                        <span>฿</span> {NumberWithCommas(record.amount, 2)}
                    </div>
                    {record.remark && <div className="row-remark mt-2">{record.remark}</div>}
                    {record.operator && <div className="bo-badge badge-light mt-2 text-danger"><FormattedMessage id="listed_by" defaultMessage="ทำรายการโดย" /> : {record.operator}</div>}
                </>
            )
        },
        {
            title: <FormattedMessage id="status" defaultMessage="สถานะ" />,
            dataIndex: 'status',
            width: '180px',
            render: (text: any, record: any) => (
                <StatusBadge status={record.status} />
            )
        }
    ]

    useEffect(() => {
        setDataSource(data)
    }, [data, loading, setDataSource])

    useEffect(() => {
        update()
    }, [dateRange])// eslint-disable-line

    return (
        <>
            <h5 className="mt-2 mb-3"><FormattedMessage id="menu-transaction-deposit" defaultMessage="รายการฝาก" /> <small className="sub-title">{`${NumberWithCommas(dataSource ? dataSource.length : 0)}`} <FormattedMessage id="list" defaultMessage="รายการ" /></small></h5>
            <div className="box-white ant-box-table">
                <TableComponent
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={loading}
                    customOffsetHeader={-24}
                />
            </div>
        </>
    )
}
export default DepositTab